import React from "react";
import FindMyTrip from "./components/FindMyTrip.vm";
import TripContainer from "./TripContainer";
import { withAppInsights } from "../../lib/AppInsights";

class MyTrip extends React.PureComponent {
  render() {
    return (
      <TripContainer {...this.props} title="My Trip">
        <FindMyTrip />
      </TripContainer>
    );
  }
}

export default withAppInsights(MyTrip);
