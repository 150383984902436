import React from "react";
import ReactSelect from "react-select";

export default class Select extends React.Component {
  render() {
    const {
      autoComplete,
      className,
      clearable,
      disabled,
      name,
      onChange,
      options,
      placeholder,
      searchable,
      style,
      tabIndex,
      value,
    } = this.props;

    return (
      <div className={className || "custom-select"} style={style}>
        <ReactSelect
          autoComplete={autoComplete}
          classNamePrefix="input-container-select"
          isClearable={!!clearable}
          isDisabled={disabled}
          isSearchable={!!searchable}
          onChange={(selection) =>
            onChange(name, selection ? selection.value : "")
          }
          options={options || []}
          placeholder={placeholder}
          tabIndex={tabIndex}
          value={
            value && options ? options.find((o) => o.value === value) : null
          }
        />
      </div>
    );
  }
}
