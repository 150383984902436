export function ApiCallErrorMessageHandler(err) {
  const statusCode = (err.response && err.response.status) || err.status || -1;

  switch (statusCode) {
    case 401:
    case 403:
      return "You do not have permissions to access this feature. \nPlease contact an Administrator for assistance.";
    case 400:
      if (err.response && err.response.data && err.response.data.messages) {
        const errorMessage = err.response.data.messages.join("\n");
        if (errorMessage) return errorMessage;
      }
      // handle errors from Stripe
      if (err?.message) {
        return err.message;
      }
      return "Sorry, something went wrong.  Please try again.";
    case 404:
      return "Sorry, the page you are looking for cannot be found";
    default: //500 & other
      return "Sorry, an unexpected error has occurred.";
  }
}
