import React from "react";
import Pages from "../../pages";
import Layout from "../../components/Layout.vm";
import { getFullNameDisplay, PageLink } from "../../lib";
import { withAppInsights } from "../../lib/AppInsights";
import moment from "moment";

class Home extends React.PureComponent {
  componentDidMount() {
    this.props.actions.getActivity();
  }

  render() {
    const { lastLogin, user } = this.props;

    return (
      <Layout {...this.props} title="Home">
        <div className="home-page page container">
          <div className="home-page-header flex flex-justify-space">
            <div className="flex flex-align-center">
              <div className="home-page-profile-pic mr-24">
                <img
                  src={
                    (user && user.profileImageURL) ||
                    "/images/profile-placeholder.svg"
                  }
                  alt=""
                />
              </div>
              <div>
                {" "}
                <p className="fw-700 xxl-text mb-8">
                  Welcome
                  {user &&
                    (user.firstName || user.lastName) &&
                    `, ${getFullNameDisplay(user.firstName, user.lastName)}`}
                </p>
                {!!lastLogin && (
                  <p className="accent-text">
                    Your last login was on{" "}
                    {moment(lastLogin).format("MMMM D, YYYY")} at{" "}
                    {moment(lastLogin).format("h:mm a")}
                  </p>
                )}
              </div>
            </div>
            <PageLink
              to={Pages.profile.studentProfile}
              className="btn btn-accent btn-medium ml-16"
              style={{ padding: "0 16px", fontSize: "12px" }}
            >
              View Account
            </PageLink>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withAppInsights(Home);
