import React from "react";
import { getAddressDisplay } from "../../../../lib";
import moment from "moment";

export default class CourseSchedule extends React.PureComponent {
  render() {
    const { courseSchedule } = this.props;

    return (
      <div className="your-israel-form-section mb-32">
        <p className="fw-700 xl-text mb-16 accent-text-dark">Course Schedule</p>
        {courseSchedule && courseSchedule.classSchedules && (
          <div className="course-classes">
            {courseSchedule.classSchedules
              .sort((c1, c2) => c1.sortOrder - c2.sortOrder)
              .map((classSchedule, index) => {
                return (
                  <div
                    className="flex flex-align-center flex-justify-space mb-32 accent-text fw-700 medium-text mobile-block"
                    key={index}
                  >
                    <p
                      className="mobile-mb-16"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Class #{index + 1}
                    </p>
                    <div className="course-classes-schedule-details">
                      <p className="flex flex-align-center mr-16">
                        <i className="material-icons mr-4 large-text accent-text-secondary">
                          date_range
                        </i>
                        {moment(classSchedule.dateTime).format("M/D/YYYY")}{" "}
                        {courseSchedule.timezoneName}
                      </p>
                      <p className="flex flex-align-center mr-16">
                        <i className="material-icons mr-4 large-text accent-text-secondary">
                          access_time
                        </i>
                        {moment(classSchedule.dateTime).format("h:mm a")}
                      </p>
                      <p className="flex flex-align-center">
                        <i className="material-icons mr-4 large-text accent-text-secondary">
                          place
                        </i>
                        {courseSchedule.locationAddress.address1
                          ? `${
                              courseSchedule.locationName
                                ? `${courseSchedule.locationName} - `
                                : ""
                            }${getAddressDisplay(
                              courseSchedule.locationAddress.address1,
                              courseSchedule.locationAddress.address2,
                              courseSchedule.locationAddress.city,
                            )}`
                          : "Chabad house"}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    );
  }
}
