import React from "react";
import Checkbox from "../../../../../components/form/Checkbox";
import ValidatedInput from "../../../../../components/ValidatedInput";
import NumberFormat from "react-number-format";

function DonationField(props) {
  const {
    willPayDonation,
    setWillPayDonation,
    donationAmount,
    onChangeRegistration,
    submitAttempted,
    showCheckbox,
  } = props;

  return (
    <div className="flex flex-align-center mobile-block">
      {showCheckbox && (
        <Checkbox
          className="policy-checkbox mr-16 mt-12"
          name="willPayDonation"
          label="I would like to make a one-time donation"
          labelClassName="mobile-mb-8"
          checked={willPayDonation}
          onChange={(_, checked) => setWillPayDonation(checked)}
        />
      )}
      {!showCheckbox && <div className="mr-16">Donation amount</div>}
      {willPayDonation && (
        <ValidatedInput
          name="paymentInfo.donationAmount"
          className="custom-input dollar-input"
          input={
            <NumberFormat
              allowNegative={false}
              onValueChange={({ floatValue }) => {
                onChangeRegistration("paymentInfo.donationAmount", floatValue);
              }}
              decimalScale={2}
              thousandSeparator={true}
            />
          }
          disabled={!willPayDonation}
          required={willPayDonation}
          showRequired={submitAttempted && willPayDonation && !donationAmount}
          value={donationAmount}
        />
      )}
    </div>
  );
}

export default DonationField;
