import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { TripActions } from "../../../state";
import FindMyTrip from "./FindMyTrip";

export default connect(
  function mapState(state) {
    return {
      ...state.trip,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...TripActions,
        },
        dispatch,
      ),
    };
  },
)(FindMyTrip);
