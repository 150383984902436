import React from "react";
import { getVersionedImgSrc, Navigation, PageLink, PageNavLink } from "../lib";
import Pages from "../pages";

export default class Layout extends React.PureComponent {
  state = {
    accountDropdownOpen: false,
    menuOpen: false,
  };

  closeMobileMenu() {
    this.setState({ menuOpen: false });
  }

  toggleDropdown() {
    this.setState({ accountDropdownOpen: !this.state.accountDropdownOpen });
  }

  toggleMenu() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  render() {
    const {
      pageRoute,
      ui: { isPhone },
      user,
    } = this.props;
    const { firstName, lastName, profileImageURL } = user || {};
    const { accountDropdownOpen, menuOpen } = this.state;

    return (
      <React.Fragment>
        <header className="header">
          <div className="container">
            <PageLink to={"/"} className="mobile-hidden">
              <img
                src={getVersionedImgSrc("coci-logo.png")}
                alt="logo"
                className="mobile-hidden"
              />
            </PageLink>
            <div className="desktop-hidden tablet-hidden flex flex-align-center">
              {this.props.goBack ? (
                <i
                  className="material-icons accent-text-secondary pointer"
                  style={{ position: "absolute" }}
                  onClick={pageRoute.history.goBack}
                >
                  arrow_back
                </i>
              ) : (
                <div
                  className={menuOpen ? "mobile-menu menu-open" : "mobile-menu"}
                >
                  <span
                    className="menu-icon-wrapper"
                    onClick={() => this.toggleMenu()}
                  >
                    <span className="menu-icon" />
                  </span>
                  <div className="mobile-menu-content">
                    <ul className="mobile-nav-links">
                      <li
                        className="mobile-nav-link"
                        onClick={() => this.closeMobileMenu()}
                      >
                        <PageNavLink
                          to={"/"}
                          exact={true}
                          activeClassName="active-mobile-nav-link"
                        >
                          Home
                        </PageNavLink>
                        {!user && (
                          <PageLink
                            query={{ after: encodeURI(Navigation.locationURL) }}
                            style={{ padding: "0 16px" }}
                            to={Pages.account.login}
                          >
                            Login
                          </PageLink>
                        )}
                      </li>
                    </ul>
                    <div className="mobile-nav-footer small-text flex">
                      <div onClick={() => this.closeMobileMenu()}>
                        <PageLink to={""} style={{ whiteSpace: "nowrap" }}>
                          Terms and Conditions
                        </PageLink>
                      </div>
                      <p className="ml-8 mr-8">|</p>
                      <div onClick={() => this.closeMobileMenu()}>
                        <p className="flex flex-align-center">
                          <span className="block" style={{ color: "#fff" }}>
                            <a
                              href="mailto:help@chabadoncampus.org"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span>help@chabadoncampus.org</span>
                            </a>
                          </span>
                          <span
                            className="accent-text ml-8 mr-8"
                            style={{ color: "#fff" }}
                          >
                            |
                          </span>
                          <span className="block" style={{ color: "#fff" }}>
                            <a href="tel:718-510-8181">
                              <span style={{ whiteSpace: "nowrap" }}>
                                718-510-8181 ext 0
                              </span>
                            </a>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <p className="ml-40 fw-700 medium-text">{this.props.title}</p>
            </div>
            <nav className="mobile-hidden">
              <ul className="nav-links">
                {/* <li className="nav-link">
                  <PageNavLink to={'/'} exact={true} activeClassName="active-nav-link">
                    Home
                  </PageNavLink>
                </li> */}
              </ul>
            </nav>
            <div className="header-right">
              {user ? (
                <div className="account-dropdown">
                  <div
                    className="account-dropdown-header"
                    onClick={() => this.toggleDropdown()}
                  >
                    <img
                      src={
                        profileImageURL
                          ? profileImageURL
                          : "/images/profile-placeholder.svg"
                      }
                      alt=""
                      className="user-profile-pic"
                    />
                    <p className="user-name fw-700 mobile-hidden">
                      {firstName === null ? "Hello" : `Hi, ${firstName}`}
                      <i className="material-icons">expand_more</i>
                    </p>
                  </div>
                  <div
                    className={
                      accountDropdownOpen
                        ? "account-dropdown-box dropdown-box open"
                        : "account-dropdown-box dropdown-box"
                    }
                  >
                    <div className="account-dropdown-box-header">
                      <img
                        src={
                          profileImageURL
                            ? profileImageURL
                            : "/images/profile-placeholder.svg"
                        }
                        alt=""
                        className="user-profile-pic"
                      />
                      <div>
                        <p className="user-name fw-700">
                          {firstName} {lastName}
                        </p>
                        {pageRoute.page.type !==
                          Pages.profile.studentProfile.type && (
                          <PageLink
                            className="link-text small-text"
                            to={Pages.profile.studentProfile}
                            state={
                              pageRoute.page.type ===
                                Pages.trips.register.type ||
                              pageRoute.page.type ===
                                Pages.yourIsrael.enroll.type
                                ? {
                                    previousLocationPageType:
                                      pageRoute.page.type,
                                    previousLocationPath:
                                      pageRoute.location.pathname,
                                  }
                                : null
                            }
                          >
                            View Account
                          </PageLink>
                        )}
                      </div>
                    </div>
                    <div className="account-dropdown-box-body">
                      <p>
                        <span className="accent-text-dark block small-text mb-8">
                          <a
                            href="mailto:help@chabadoncampus.org"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link-with-icon flex flex-align-center"
                          >
                            <i
                              className="material-icons mr-8"
                              style={{ fontSize: "15px" }}
                            >
                              email
                            </i>
                            <span>help@chabadoncampus.org</span>
                          </a>
                        </span>
                        <span className="accent-text-dark block mb-16 small-text">
                          <a
                            href="tel:718-510-8181"
                            className="link-with-icon flex flex-align-center"
                          >
                            <i
                              className="material-icons mr-8"
                              style={{ fontSize: "15px" }}
                            >
                              phone
                            </i>
                            <span>718-510-8181 ext 0</span>
                          </a>
                        </span>
                      </p>
                      <p
                        className="fw-500"
                        onClick={this.props.actions.doSignout}
                      >
                        Sign Out
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                !isPhone && (
                  <div className="flex flex-align-center flex-justify-end">
                    <PageLink
                      className="medium-text link-text-secondary fw-500"
                      query={{ after: encodeURI(Navigation.locationURL) }}
                      to={Pages.account.login}
                    >
                      Login
                    </PageLink>
                    <PageLink
                      className="btn btn-medium btn-accent ml-24 mr-24"
                      query={{ after: encodeURI(Navigation.locationURL) }}
                      style={{ padding: "0 16px" }}
                      to={Pages.account.register}
                    >
                      Create Account
                    </PageLink>
                  </div>
                )
              )}
              <p className="bh">ב״ה</p>
            </div>
          </div>
        </header>
        <div
          className={
            !isPhone ? "page-wrapper" : "page-wrapper page-wrapper-mobile"
          }
          mobilemenuopen={menuOpen ? "true" : null}
          accountdropdownopen={accountDropdownOpen ? "true" : null}
        >
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }
}
