import React, { useEffect } from "react";
import { pluralizeText, getFullNameDisplay } from "../../../../../lib";

function Seating(props) {
  const {
    rsvpItem: { attendeesByTable },
    isStandaloneSeatingSelection,
    onChangeRegistration,
    registration: { tableID: selectedTableID },
    validateStepAndAdvance,
    handleValidationAndAdvanceStep,
  } = props;

  useEffect(() => {
    if (validateStepAndAdvance) {
      handleValidationAndAdvanceStep(); // no required fields in this step
    }
  }, [validateStepAndAdvance, handleValidationAndAdvanceStep]);

  const hasAvailableSeating = () =>
    attendeesByTable.some((table) => !table.capacity || table.seatsRemaining); //has tables with unlimited capacity or remaining seats

  return (
    <div className="full-width">
      <div className="card flat-card mb-16 seating-options-card">
        {!isStandaloneSeatingSelection && (
          <React.Fragment>
            <p className="fw-700 xxl-text mb-8">Choose your seat!</p>
            <p className="accent-text-dark mb-24">
              Select which table you would like to sit at
            </p>
          </React.Fragment>
        )}
        <div className="seating-options-grid">
          {attendeesByTable &&
            attendeesByTable.map((table) => {
              const {
                capacity,
                seatsRemaining: initialSeatsRemaining,
                tableName,
                tableID,
                sortOrder,
              } = table;
              const isFullyBooked = capacity && !initialSeatsRemaining;
              const isTableSelected = selectedTableID === tableID;
              const seatsRemaining = isTableSelected
                ? initialSeatsRemaining - 1
                : initialSeatsRemaining;

              return (
                <div
                  className={`card flat-card table-card${
                    isTableSelected ? " selected" : ""
                  }`}
                  key={tableID}
                >
                  <div>
                    <div className="flex flex-justify-space flex-align-center table-card-header mb-16">
                      <p className="fw-700 xl-text">
                        {tableName || `Table ${sortOrder}`}
                      </p>
                      {!!capacity && (
                        <p className="accent-text-secondary">
                          {seatsRemaining}{" "}
                          {pluralizeText("Seat", seatsRemaining)} left
                        </p>
                      )}
                    </div>
                    <div className="mb-16">
                      {table.attendees &&
                        table.attendees.map((attendee) => (
                          <p
                            className="accent-text small-text mt-8"
                            key={attendee.id}
                          >
                            {getFullNameDisplay(
                              attendee.firstName,
                              attendee.lastName,
                            )}
                          </p>
                        ))}
                      {isTableSelected && (
                        <p className="accent-text small-text mt-8">You</p>
                      )}
                    </div>
                  </div>
                  <div>
                    <p
                      className={`${
                        !isTableSelected
                          ? "link-text"
                          : "accent-text-dark link-text-secondary"
                      } uppercase-text pointer ${
                        isFullyBooked ? " disabled" : ""
                      }`}
                      onClick={
                        isFullyBooked
                          ? null
                          : () =>
                              onChangeRegistration(
                                "tableID",
                                isTableSelected ? "" : tableID,
                              )
                      }
                    >
                      {isTableSelected ? "Deselect" : "Choose this table"}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
        {!hasAvailableSeating(attendeesByTable) && (
          <p className="accent-text mt-24">
            *All tables are full but additional seating will be available.
          </p>
        )}
      </div>
    </div>
  );
}

export default Seating;
