import React from "react";
import Loader from "../../../components/Loader";
import RegistrationError from "./registrationStates/RegistrationError";
import RegistrationNotAvailable from "./registrationStates/RegistrationNotAvailable";

import { Navigation } from "../../../lib";
import { TripTypes } from "../TripConsts";

export default class FindMyTrip extends React.PureComponent {
  state = {
    error: null,
    loading: true,
  };

  componentDidMount() {
    const {
      pageRoute: {
        params: { eventType },
      },
    } = this.props;

    this.findMyTrip(eventType);
  }

  findMyTrip = async (eventType = TripTypes.InternationalPegisha) => {
    await this.props.actions.findMyTrip(eventType);

    const {
      findMyTrip: { data: { id: tripId } = {}, error, success },
    } = this.props;

    if (success && tripId) {
      Navigation.redirect(`/trips/${eventType}/${tripId}/register`);
    } else {
      this.setState({ error: error || "Cannot find trip" });
    }
  };

  render() {
    const { error } = this.state;

    return (
      <div className="page trip-page">
        {error ? (
          error.status === 404 ? ( //no current trip for student
            <RegistrationNotAvailable />
          ) : (
            <RegistrationError error={error} />
          )
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}
