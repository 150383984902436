import React from "react";
import { Prompt } from "react-router";

import ConfirmationModal from "../../../components/ConfirmationModal";
import Application from "./registrationSteps/Application";
import Overview from "./registrationSteps/Overview";
import OverviewTravel from "./registrationSteps/OverviewTravel";
import Payment from "./registrationSteps/Payment";
import Terms from "./registrationSteps/Terms";
import Tours from "./registrationSteps/Tours";
import Travel from "./registrationSteps/Travel";
import VisibilitySensor from "react-visibility-sensor";
import { injectStripe } from "react-stripe-elements";

import Pages from "../..";
import _set from "lodash.set";
import _isEqual from "lodash.isequal";
import _cloneDeep from "lodash.clonedeep";
import {
  getInitialRegistration,
  validateRegistration,
} from "./RegistrationFormHelpers";
import { replaceValuesInObject } from "../../../lib";

class RegistrationForm extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      trip: {
        event: {
          isMarketingConsentRequired,
          isProofOfVaccinationRequired,
          isTravelTrip,
        },
        termsAndConditions,
        tours,
      },
    } = this.props;

    const state = {
      registration: getInitialRegistration(props),

      rehydrated: true, // property for form caching/rehydration.  removed caching, t/f defaulting to true.

      showOverlappingTourSchedulesAlert: false,

      submitAttempted: false,

      validationErrors: [],

      visibleStepIndex: 0,
    };

    state.initialState = _cloneDeep(state);

    this.state = state;

    const hasTerms =
      isMarketingConsentRequired ||
      isProofOfVaccinationRequired ||
      termsAndConditions?.length;

    this.registrationSteps = [
      {
        component: isTravelTrip ? OverviewTravel : Overview,
        ref: "overview",
        title: "Overview",
      },
      ...(isTravelTrip
        ? [
            {
              component: Application,
              ref: "application",
              title: "Application",
            },
            {
              component: Travel,
              ref: "travel",
              title: "Travel",
            },
          ]
        : []),
      ...(tours.length
        ? [
            {
              component: Tours,
              ref: "tours",
              title: "Tours",
            },
          ]
        : []),
      ...(hasTerms
        ? [
            {
              component: Terms,
              ref: "terms",
              title: "Terms",
            },
          ]
        : []),
      {
        component: Payment,
        ref: "payment",
        title: "Payment",
      },
    ];

    this.visibleStepsIndexes = [];
  }

  hasUnsavedChanges() {
    const {
      registration,
      initialState: { registration: initialRegistration },
    } = this.state;
    // disregard reward request (which can be auto-applied) from registration changes consideration
    const { rewardRequest: _, ...registrationRest } = registration;
    const { rewardRequest: __, ...initialRegistrationRest } =
      initialRegistration;
    return !_isEqual(registrationRest, initialRegistrationRest);
  }

  componentDidMount() {
    window.onbeforeunload = () => {
      const promptForUnsavedChanges =
        !this.state.navigating && this.hasUnsavedChanges();
      return promptForUnsavedChanges || undefined;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  stepVisibilityChanged(isVisible, stepIndex) {
    if (isVisible) {
      if (!this.visibleStepsIndexes.includes(stepIndex)) {
        this.visibleStepsIndexes.push(stepIndex);
      }
    } else if (this.visibleStepsIndexes.includes(stepIndex)) {
      this.visibleStepsIndexes = this.visibleStepsIndexes.filter(
        (i) => i !== stepIndex,
      );
    }

    let greatestVisibleStepIndex = this.visibleStepsIndexes.length
      ? Math.max(...this.visibleStepsIndexes)
      : 0;
    if (greatestVisibleStepIndex !== this.state.visibleStepIndex) {
      this.setState({ visibleStepIndex: greatestVisibleStepIndex });
    }
  }

  scrollToStep(stepRef, stepIndex) {
    if (this[stepRef]) {
      this[stepRef].scrollIntoView({ block: "start" });
      window.scrollBy(0, -200);

      // 5 ms after 10 ms scrollDelay, setting visibleStepIndex to clicked stepIndex,
      // in case visibleStepIndex was set to a higher stepIndex if higher steps are visible as well
      setTimeout(() => {
        this.setState({ visibleStepIndex: stepIndex });
      }, 15);
    }
  }

  onChange = (name, value) => {
    let state = _cloneDeep(this.state);
    _set(state, name, value);

    return new Promise((resolve, reject) => {
      this.setState(state, () => resolve());
    });
  };

  onChangeRegistration = (name, value, other) => {
    let registration = _cloneDeep(this.state.registration);
    if (name === "registration") {
      registration = value;
    } else {
      _set(registration, name, value);
      if (other) {
        Object.keys(other).forEach((otherKey) =>
          _set(registration, otherKey, other[otherKey]),
        );
      }
    }

    return new Promise((resolve, reject) => {
      this.setState(
        {
          ...this.state,
          registration,
        },
        async () => {
          resolve();
        },
      );
    });
  };

  onChangeRegistrationEvt = (evt) => {
    return this.onChangeRegistration(
      evt.target.name,
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value,
    );
  };

  onChangeVaccinationProof = (action, value, index) => {
    let registration = _cloneDeep(this.state.registration);
    if (action === "add") {
      if (!registration.vaccinationProofsForUpload) {
        registration.vaccinationProofsForUpload = [];
      }
      registration.vaccinationProofsForUpload[index] = value;
    } else {
      registration.vaccinationProofsForUpload.splice(index, 1);
    }
    return this.onChangeRegistration("registration", registration);
  };

  cancelRegistration = () => {
    const { navigate } = this.props;

    navigate(Pages.main.home);
    // localStorage.removeItem(`registration-${studentId}`);
  };

  submitRegistration = async (billingInfo) => {
    const { registration, submitAttempted, validationErrors } = this.state;
    const { doTripRegistration, trip } = this.props;

    if (!submitAttempted) {
      this.setState({ submitAttempted: true });
    }

    if (
      validateRegistration(registration, trip, validationErrors).isIncomplete
    ) {
      return;
    }

    const registrationForSubmission = _cloneDeep(registration);
    replaceValuesInObject(registrationForSubmission, (val) => val === "", null);

    delete registrationForSubmission.didConfirmInfo; // This field is for FE validation only, not accepted by the API

    await doTripRegistration({
      ...registrationForSubmission,
      billing: billingInfo,
    });

    const {
      onCompleteRegistration,
      register: { error, success },
    } = this.props;

    if (success) {
      onCompleteRegistration();
      // localStorage.removeItem(`registration-${studentId}`);
    } else {
      if (
        error?.data?.messages[0] &&
        error.data.messages[0].indexOf("OverlapWithOhelVisit") >= 0
      ) {
        this.setState({
          showOverlappingTourSchedulesAlert: true,
        });
      }
    }

    return this.props.register;
  };

  updateValidationErrors = (name, isValid) => {
    const { validationErrors } = this.state;
    this.setState({
      validationErrors: isValid
        ? validationErrors.filter((err) => err !== name)
        : [...validationErrors, name],
    });
  };

  render() {
    const { isShliachView, navigating, profile, sys, trip, ui } = this.props;

    const {
      registration,
      rehydrated,
      showOverlappingTourSchedulesAlert,
      submitAttempted,
      validationErrors,
      visibleStepIndex,
    } = this.state;

    const registrationValidation = validateRegistration(
      registration,
      trip,
      validationErrors,
    );

    const { isTravelTrip } = trip.event;

    return (
      rehydrated && (
        <>
          <Prompt
            when={!navigating && this.hasUnsavedChanges()}
            message="Are you sure you want to leave this page before submitting your registration?"
          />

          <div className="trip-registration-nav trip-page-stepper">
            <div className="container">
              <div className="stepper-steps">
                {this.registrationSteps.map((step, index) => (
                  <React.Fragment key={index}>
                    <div
                      className={`stepper-step ${
                        visibleStepIndex === index ? "active" : ""
                      }`}
                      onClick={() => this.scrollToStep(step.ref, index)}
                    >
                      <div className="stepper-step-circle" />
                      <p className="stepper-step-title">{step.title}</p>
                    </div>
                    {index !== this.registrationSteps.length - 1 && (
                      <div className="stepper-line" />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>

          <div className="trip-page-form-container container">
            {!isTravelTrip && (
              <div className="trip-form-section">
                <p className="fw-900 xl-text">
                  {trip.programScheduleName
                    ? `Enroll in ${trip.programScheduleName}`
                    : "Enroll"}
                </p>
              </div>
            )}
            <div className={isTravelTrip ? "travel-trip-form" : ""}>
              {this.registrationSteps.map((step, index) => (
                <VisibilitySensor
                  onChange={(isVisible) =>
                    this.stepVisibilityChanged(isVisible, index)
                  }
                  intervalCheck={false}
                  key={index}
                  minTopValue={400}
                  partialVisibility={true}
                  scrollCheck={true}
                  scrollDelay={10}
                >
                  <div
                    ref={(el) => (this[step.ref] = el)}
                    className={
                      index || !isTravelTrip ? "trip-form-section" : ""
                    }
                  >
                    {React.createElement(step.component, {
                      cancelRegistration: this.cancelRegistration,
                      isShliachView: isShliachView,
                      onChange: this.onChangeRegistration,
                      onChangeEvt: this.onChangeRegistrationEvt,
                      onChangeVaccinationProof: this.onChangeVaccinationProof,
                      profile,
                      registration,
                      registrationValidation,
                      setSubmitAttempted: () =>
                        this.setState({ submitAttempted: true }),
                      submitAttempted,
                      submitRegistration: this.submitRegistration,
                      sys,
                      trip,
                      ui,
                      updateValidation: this.updateValidationErrors,
                      validationErrors,
                    })}
                  </div>
                </VisibilitySensor>
              ))}
            </div>
          </div>
          <ConfirmationModal
            cancel={() =>
              this.setState({
                submitAttempted: false,
                showOverlappingTourSchedulesAlert: false,
              })
            }
            cancelText="OK"
            message="The selected tour schedules are overlapping.  Please adjust your tour schedule selection before registering."
            noConfirm={true}
            show={showOverlappingTourSchedulesAlert}
          />
        </>
      )
    );
  }
}

export default injectStripe(RegistrationForm);
