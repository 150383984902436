import RsvpEvents from "./RsvpEvents.vm";
import RsvpEventRegistrationContainer from "./RsvpEventRegistrationContainer.vm";
import RsvpResourceRegistrationContainer from "./RsvpResourceRegistrationContainer.vm";
import RsvpEventRegistrationPaymentContainer from "./RsvpEventRegistrationPaymentContainer.vm";
import RsvpResourceRegistrationPaymentContainer from "./RsvpResourceRegistrationPaymentContainer.vm";

export const RsvpPages = {
  events: {
    anon: true,
    path: "/rsvp/:chabadHouseSlug",
    title: "RSVP Events",
    type: "PAGE_RSVP_EVENTS",
    view: RsvpEvents,
  },
  register: {
    anon: true,
    path: "/rsvp/:chabadHouseSlug/:eventScheduleId",
    title: "Register for an Event",
    type: "PAGE_RSVP_EVENT_REGISTRATION",
    view: RsvpEventRegistrationContainer,
  },
  registerResource: {
    anon: true,
    path: "/rsvp/:chabadHouseSlug/resources/:resourceId",
    title: "Sign up for a Resource",
    type: "PAGE_RSVP_RESOURCE_REGISTRATION",
    view: RsvpResourceRegistrationContainer,
  },
  pay: {
    anon: true,
    path: "/events/registration/pay",
    title: "Complete event registration",
    type: "PAGE_RSVP_EVENT_REGISTRATION_PAYMENT",
    view: RsvpEventRegistrationPaymentContainer,
  },
  payResource: {
    anon: true,
    path: "/resources/registration/pay",
    title: "Complete resource sign-up",
    type: "PAGE_RSVP_RESOURCE_REGISTRATION_PAYMENT",
    view: RsvpResourceRegistrationPaymentContainer,
  },
};
export default RsvpPages;

export const RsvpArea = {
  pages: RsvpPages,
};
