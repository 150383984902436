import React, { useState } from "react";
import ManualPayButton from "../../../../shared/ManualPayButton.jsx";
import { StripeCardElementWithName } from "../../../../../components/StripeCardElement.jsx";
import { injectStripe } from "react-stripe-elements";

import { formatCurrency } from "../../../../../lib";
import {
  paymentTypes,
  submitOneTimeStripePayment,
  constructStripeBillingDetails,
} from "../../../../../lib/payment";

// copied from Trip's Checkout - pull DigitalPay info from there when relevant
// TODO can this code be merged with the RegistrationPayment screen of the online registration wizard?
function StandaloneRegistrationPayment(props) {
  const {
    rsvpName,
    registrationFeeAmount,
    donationAmount,
    errorMessage,
    loading,
    setErrorMessage,
    setLoading,
    onCompletePayment,
    handleError,
    pageRoute: {
      query: { accountId, token, registrationId, threadId },
    },
    doRsvpRegistrationPayment,
    textBlanks: { reservation = "registration", action = "Register" },
    stripe,
    user,
  } = props;

  const [ccInfoComplete, setCcInfoComplete] = useState(false);
  const [cardHolderFullName, setCardHolderFullName] = useState(
    user ? `${user.firstName} ${user.lastName}` : "",
  );
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const isValid = () => ccInfoComplete && !!cardHolderFullName;

  const onSubmitPayment = async (event) => {
    if (event) {
      event.preventDefault();
    }

    setErrorMessage("");
    setSubmitAttempted(true);

    if (!isValid()) {
      return; // error message is taken care of by the button
    }

    setLoading(true);

    const objectForSubmission = {
      registrationId,
      tokenRequest: {
        accountId,
        token,
        threadId,
      },
      donationAmount,
      registrationFeeAmount,
      paymentType: paymentTypes.creditCard,
      sendRegistrantEmail: true,
    };

    const billingDetails = constructStripeBillingDetails(cardHolderFullName);
    await submitOneTimeStripePayment(
      stripe,
      billingDetails,
      objectForSubmission,
      doRsvpRegistrationPayment,
    )
      .then(() => {
        onCompletePayment();
      })
      .catch((error) => handleError(error));

    setLoading(false);
  };

  const formatAmount = (label, value, textSize) => (
    <p className={`${textSize || "medium-text"} mb-8`}>
      {label}: {formatCurrency(value, "$")}
    </p>
  );

  return (
    <>
      <form
        action="/autocomplete"
        autoComplete="on"
        className="checkout-form"
        method="post"
        onSubmit={onSubmitPayment}
      >
        <div className="rsvp-payment-section">
          <div className="mb-32">
            <p className="fw-700 large-text mb-20">
              Complete your {reservation} for {rsvpName}
            </p>
            <div className="flex flex-align-center">
              <div>
                {registrationFeeAmount &&
                  formatAmount("Event Fee", registrationFeeAmount)}
                {donationAmount && formatAmount("Donation", donationAmount)}
                {registrationFeeAmount && donationAmount && (
                  <div className="mt-16">
                    {formatAmount(
                      "Total Amount",
                      registrationFeeAmount + donationAmount,
                      "large-text fw-700",
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <StripeCardElementWithName
            onComplete={setCcInfoComplete}
            cardHolderFullName={cardHolderFullName}
            onChangeName={(_, value) => setCardHolderFullName(value)}
            isNameRequired={true}
            submitAttempted={submitAttempted}
          />

          <div className="flex rsvp-payment-btn relative">
            <ManualPayButton
              clearErrorMessage={() => setErrorMessage("")}
              customValidation={!isValid()}
              customValidationErrorMessage={
                "Please complete all payment fields"
              }
              errorMessage={errorMessage}
              loading={loading}
              submitAttempted={submitAttempted}
              buttonText={`Pay & ${action}`}
            />
          </div>
        </div>
      </form>
    </>
  );
}

export default injectStripe(StandaloneRegistrationPayment);
