import { RsvpActions } from "./actions";
import { AuthActions } from "../auth/actions";

export const RsvpState = {
  name: "rsvp",
  persist: false,
  defaults: {
    rsvpLocation: {
      data: {},
      error: undefined,
      loading: false,
      success: undefined,
    },

    eventSchedules: {
      data: {},
      error: undefined,
      loading: false,
      success: undefined,
    },
    eventSchedule: {
      data: {},
      error: undefined,
      loading: false,
      success: undefined,
    },

    eventRegistration: {
      loading: false,
    },
    eventRegistrationPaymentDetails: {
      loading: false,
    },
    eventRegistrationPayment: {
      loading: false,
    },

    resources: {
      loading: false,
    },
    resource: {
      loading: false,
    },

    resourceRegistration: {
      loading: false,
    },
    resourceRegistrationPaymentDetails: {
      loading: false,
    },
    resourceRegistrationPayment: {
      loading: false,
    },
  },
  handlers: {
    [RsvpActions.SET_GET_RSVP_LOCATION_LOADING]: function (state, action) {
      return {
        ...state,
        rsvpLocation: {
          ...state.rsvpLocation,
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.GET_RSVP_LOCATION_COMPLETE]: function (state, action) {
      return {
        ...state,
        rsvpLocation: {
          ...state.rsvpLocation,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [RsvpActions.GET_RSVP_LOCATION_ERROR]: function (state, action) {
      return {
        ...state,
        rsvpLocation: {
          ...state.rsvpLocation,
          loading: false,
          success: false,
          error: action.payload,
          data: {},
        },
      };
    },

    [RsvpActions.SET_GET_RSVP_EVENT_SCHEDULES_LOADING]: function (
      state,
      action,
    ) {
      return {
        ...state,
        eventSchedules: {
          ...state.eventSchedules,
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.GET_RSVP_EVENT_SCHEDULES_COMPLETE]: function (state, action) {
      return {
        ...state,
        eventSchedules: {
          ...state.eventSchedules,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [RsvpActions.GET_RSVP_EVENT_SCHEDULES_ERROR]: function (state, action) {
      return {
        ...state,
        eventSchedules: {
          ...state.eventSchedules,
          loading: false,
          success: false,
          error: action.payload,
          data: {},
        },
      };
    },
    [RsvpActions.RESET_RSVP_EVENT_SCHEDULES]: function (state, action) {
      return {
        ...state,
        eventSchedules: RsvpState.defaults.eventSchedules,
      };
    },

    [RsvpActions.SET_GET_RSVP_EVENT_SCHEDULE_LOADING]: function (
      state,
      action,
    ) {
      return {
        ...state,
        eventSchedule: {
          ...state.eventSchedule,
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.GET_RSVP_EVENT_SCHEDULE_COMPLETE]: function (state, action) {
      return {
        ...state,
        eventSchedule: {
          ...state.eventSchedule,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [RsvpActions.GET_RSVP_EVENT_SCHEDULE_ERROR]: function (state, action) {
      return {
        ...state,
        eventSchedule: {
          ...state.eventSchedule,
          loading: false,
          success: false,
          error: action.payload,
          data: {},
        },
      };
    },
    [RsvpActions.RESET_RSVP_EVENT_SCHEDULE]: function (state, action) {
      return {
        ...state,
        eventSchedule: RsvpState.defaults.eventSchedule,
        eventRegistration: RsvpState.defaults.eventRegistration,
      };
    },

    [RsvpActions.SET_RSVP_EVENT_REGISTRATION_LOADING]: function (
      state,
      action,
    ) {
      return {
        ...state,
        eventRegistration: {
          ...state.eventRegistration,
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.SET_RSVP_EVENT_REGISTRATION]: function (state, action) {
      return {
        ...state,
        eventRegistration: action.payload,
      };
    },

    [RsvpActions.SET_GET_RSVP_EVENT_REGISTRATION_PAYMENT_DETAILS_LOADING]:
      function (state, action) {
        return {
          ...state,
          eventRegistrationPaymentDetails: {
            ...state.eventRegistrationPaymentDetails,
            loading: !!action.payload,
          },
        };
      },
    [RsvpActions.SET_GET_RSVP_EVENT_REGISTRATION_PAYMENT_DETAILS]: function (
      state,
      action,
    ) {
      return {
        ...state,
        eventRegistrationPaymentDetails: action.payload,
      };
    },

    [RsvpActions.SET_RSVP_EVENT_REGISTRATION_PAYMENT_LOADING]: function (
      state,
      action,
    ) {
      return {
        ...state,
        eventRegistrationPayment: {
          ...state.eventRegistrationPayment,
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.SET_RSVP_EVENT_REGISTRATION_PAYMENT]: function (
      state,
      action,
    ) {
      return {
        ...state,
        eventRegistrationPayment: action.payload,
      };
    },

    [RsvpActions.SET_GET_RSVP_RESOURCES_LOADING]: function (state, action) {
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.SET_RSVP_RESOURCES]: function (state, action) {
      return {
        ...state,
        resources: action.payload,
      };
    },

    [RsvpActions.SET_GET_RSVP_RESOURCE_LOADING]: function (state, action) {
      return {
        ...state,
        resource: {
          ...state.resource,
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.SET_RSVP_RESOURCE]: function (state, action) {
      return {
        ...state,
        resource: action.payload,
      };
    },

    [RsvpActions.SET_RSVP_RESOURCE_REGISTRATION_LOADING]: function (
      state,
      action,
    ) {
      return {
        ...state,
        resourceRegistration: {
          ...state.resourceRegistration,
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.SET_RSVP_RESOURCE_REGISTRATION]: function (state, action) {
      return {
        ...state,
        resourceRegistration: action.payload,
      };
    },
    [RsvpActions.SET_GET_RSVP_RESOURCE_REGISTRATION_PAYMENT_DETAILS_LOADING]:
      function (state, action) {
        return {
          ...state,
          resourceRegistrationPaymentDetails: {
            ...state.resourceRegistrationPaymentDetails,
            loading: !!action.payload,
          },
        };
      },
    [RsvpActions.SET_GET_RSVP_RESOURCE_REGISTRATION_PAYMENT_DETAILS]: function (
      state,
      action,
    ) {
      return {
        ...state,
        resourceRegistrationPaymentDetails: action.payload,
      };
    },

    [RsvpActions.SET_RSVP_RESOURCE_REGISTRATION_PAYMENT_LOADING]: function (
      state,
      action,
    ) {
      return {
        ...state,
        resourceRegistrationPayment: {
          ...state.resourceRegistrationPayment,
          loading: !!action.payload,
        },
      };
    },
    [RsvpActions.SET_RSVP_RESOURCE_REGISTRATION_PAYMENT]: function (
      state,
      action,
    ) {
      return {
        ...state,
        resourceRegistrationPayment: action.payload,
      };
    },

    [AuthActions.DO_SIGNOUT]: function (state, action) {
      return RsvpState.defaults;
    },
  },
};
