import React from "react";

export default () => (
  <div className="trip-page-form-state">
    <img src="/images/registration_not_avail.svg" alt="" height="240" />
    <p className="fw-700 mt-24" style={{ fontSize: "34px" }}>
      RSVP not available
    </p>
    <div className="mt-16 xl-text">
      This location does not have the RSVP System set up for local events.
      Please contact your Chabad for assistance.
    </div>
  </div>
);
