import React from "react";
import DateInput from "../../../../components/form/DateInput";
import PhoneInput from "../../../../components/form/PhoneInput";
import ValidatedInput from "../../../../components/ValidatedInput";
import FileUpload from "../../../../components/FileUpload";
import { validateEmail } from "../../../../lib";

const maxIdExpirationDate = new Date().setFullYear(
  new Date().getFullYear() + 12,
);

function Travel(props) {
  const {
    onChange,
    onChangeEvt,
    profile: { address, mother, father },
    trip: {
      event: { isPassportIdRequired },
    },
    registration: {
      profileDetails: { legalFirstName, legalMiddleName, legalLastName },
      travelApplication: {
        additionalInfoNote,
        emergencyContacts,
        knownTravelerNumber,
        officialIdDocForUpload,
        officialIdExpirationDate,
        officialIdNumber,
      },
    },
    submitAttempted,
    sys: { countries = [] },
    ui: { isPhone },
    updateValidation,
    validationErrors,
  } = props;

  return (
    <>
      <p className="section-title">Travel details</p>

      <div className="subsection">
        <p className="subsection-title">Emergency contacts</p>
        {(mother || father) && (
          <p className="accent-text line-height-large mb-16">
            We found your parents’ contact info in your profile but if you would
            like to list someone else instead just swap out the information
            below.
          </p>
        )}
        {emergencyContacts.map((contact, index) => {
          const contactAccessor = `travelApplication.emergencyContacts[${index}]`;
          return (
            <div className="emergency-contacts" key={index}>
              <p className="fw-700 mb-16">Emergency contact #{index + 1}</p>
              <div>
                <ValidatedInput
                  label="Name"
                  name={`${contactAccessor}.name`}
                  onChange={onChangeEvt}
                  required={true}
                  showRequired={submitAttempted}
                  value={contact.name}
                />
                <ValidatedInput
                  label="Email"
                  name={`${contactAccessor}.email`}
                  onChange={onChangeEvt}
                  required={true}
                  showRequired={submitAttempted}
                  showValidation={true}
                  validate={(email) => {
                    const isValid = validateEmail(email);
                    updateValidation(`${contactAccessor}.email`, isValid);
                    return isValid;
                  }}
                  validationMessage="Invalid Email address"
                  value={contact.email}
                />
                <div
                  className={`input-container ${
                    validationErrors.includes(
                      `${contactAccessor}.phoneNumber`,
                    ) ||
                    (submitAttempted &&
                      (!contact.phoneNumber || !contact.phoneCountryID))
                      ? "error"
                      : ""
                  }`}
                >
                  <label>Phone</label>
                  <PhoneInput
                    countries={countries || []}
                    countryId={contact.phoneCountryID}
                    defaultCountryName={address?.country} //fall back on country associated with student's address
                    error={validationErrors.includes(
                      `${contactAccessor}.phoneNumber`,
                    )}
                    name={`${contactAccessor}.phoneNumber`}
                    onBlur={(name, isValid) => updateValidation(name, isValid)}
                    onChange={(name, val) => onChange(name, val)}
                    onChangeCountryId={(countryId) => {
                      onChange(`${contactAccessor}.phoneCountryID`, countryId);
                    }}
                    value={contact.phoneNumber}
                  />
                </div>
                <ValidatedInput
                  label="Relationship"
                  name={`${contactAccessor}.relationship`}
                  onChange={onChangeEvt}
                  required={true}
                  showRequired={submitAttempted}
                  value={contact.relationship}
                />
              </div>
            </div>
          );
        })}
      </div>

      <div className="subsection">
        <p className="subsection-title flex flex-align-center">
          {isPassportIdRequired ? "Passport" : "Government issued ID"}
          <span className="tooltip-container id-tooltip-container">
            <i className="material-icons large-text ml-8 accent-text-dark">
              info
            </i>
            <span className="tooltip">
              We prioritize the security of your personal information. All
              identification details provided will be stored in a highly secure
              environment, following data protection protocols.
            </span>
          </span>
        </p>
        <div className="mb-24">
          <p className="fw-700 mb-16">Legal name</p>
          <div className="id-info-grid">
            <ValidatedInput
              label="First"
              name="profileDetails.legalFirstName"
              onChange={onChangeEvt}
              required={true}
              showRequired={submitAttempted}
              value={legalFirstName}
            />
            <ValidatedInput
              label="Middle"
              name="profileDetails.legalMiddleName"
              onChange={onChangeEvt}
              required={false}
              value={legalMiddleName}
            />
            <ValidatedInput
              label="Last"
              name="profileDetails.legalLastName"
              onChange={onChangeEvt}
              required={true}
              showRequired={submitAttempted}
              value={legalLastName}
            />
            <ValidatedInput
              label={`${isPassportIdRequired ? "Passport" : "ID"} number`}
              name="travelApplication.officialIdNumber"
              onChange={onChangeEvt}
              required={true}
              showRequired={submitAttempted}
              value={officialIdNumber}
            />
            <ValidatedInput
              input={<DateInput maxDate={maxIdExpirationDate} />}
              label="Expiration date"
              name="travelApplication.officialIdExpirationDate"
              onChange={onChange}
              required={true}
              showRequired={submitAttempted}
              value={officialIdExpirationDate}
            />
            <ValidatedInput
              label={
                <span className="flex flex-align-center">
                  Known traveler number (optional){" "}
                  <span className="tooltip-container ktn-tooltip-container">
                    <i className="material-icons medium-text ml-8 accent-text">
                      info
                    </i>
                    <span className="tooltip">
                      A Known Traveler Number (KTN) is issued to all individuals
                      approved to receive TSA PreCheck® expedited screening.
                    </span>
                  </span>
                </span>
              }
              name="travelApplication.knownTravelerNumber"
              onChange={onChangeEvt}
              required={false}
              value={knownTravelerNumber}
            />
          </div>
        </div>
        <div className="mb-24">
          <p
            className={`trip-form-label${
              submitAttempted && !officialIdDocForUpload ? " error-text" : ""
            }`}
          >
            Please upload a copy of{" "}
            {isPassportIdRequired
              ? "the photo page of your passport"
              : "your ID"}
          </p>
          <FileUpload
            isPhone={isPhone}
            description={`${isPassportIdRequired ? "Passport" : "ID"} Document`}
            documentURL={officialIdDocForUpload?.previewURL}
            submitDocument={(_, val) =>
              onChange("travelApplication.officialIdDocForUpload", val)
            }
          />
        </div>
      </div>

      <div className="subsection">
        <p className="subsection-title">
          Additional information{" "}
          <span className="accent-text fw-500" style={{ fontSize: "14px" }}>
            (Optional)
          </span>
        </p>
        <div>
          <p className="trip-form-label">
            Is there anything else we should know about you or your application?
          </p>
          <textarea
            className="custom-input"
            name="travelApplication.additionalInfoNote"
            onChange={onChangeEvt}
            style={{ height: "48px" }}
            value={additionalInfoNote}
          />
        </div>
      </div>
    </>
  );
}

export default React.memo(Travel);
