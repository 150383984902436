import LogRocket from "logrocket";
import { store } from "../state";

const { REACT_APP_HOST_ENV = "development", REACT_APP_LOGROCKET_APPID = "" } =
  process.env;
const __DEV__ = REACT_APP_HOST_ENV === "development";
const __AUTOMATED__ = navigator.webdriver;

export function logRocketInit() {
  if (!__DEV__ && !__AUTOMATED__) {
    LogRocket.init(REACT_APP_LOGROCKET_APPID, {
      release: `student-${REACT_APP_HOST_ENV}`,
    });
    logRocketIdentifyUser();
  }
}

export function logRocketIdentifyUser() {
  if (!__DEV__ && !__AUTOMATED__) {
    const {
      auth: {
        person: { firstName = "", lastName = "" } = {},
        userEmail = "",
      } = {},
    } = store.getState();

    LogRocket.identify(userEmail, {
      name: `${firstName} ${lastName}`,
      email: userEmail,
      portal: "student_portal",
    });
  }
}
