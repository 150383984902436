import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { YourIsraelActions } from "../../../state";
import Enrollment from "./Enrollment";

export default connect(
  function mapState(state) {
    return {
      ...state.yourIsrael,
      profile: state.profile.get,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...YourIsraelActions,
        },
        dispatch,
      ),
    };
  },
)(Enrollment);
