import React, { useCallback, useEffect } from "react";
import Checkbox from "../../../../../components/form/Checkbox";
import DateInput from "../../../../../components/form/DateInput";
import Toggle from "../../../../../components/form/Toggle";
import ValidatedInput from "../../../../../components/ValidatedInput";

function PolicyAgreementAndQuestions(props) {
  const {
    didAcceptPolicy,
    rsvpItem: { isPolicyRequired, policy },
    onChangeRegistration,
    onToggleAcceptPolicy,
    registration: { notes, registrationQuestionResponses },
    validateStepAndAdvance,
    handleValidationAndAdvanceStep,
    submitAttempted,
  } = props;

  const validateStep = useCallback(() => {
    const errors = [];

    if (isPolicyRequired && !didAcceptPolicy) {
      errors.push("Please accept the Covid-19 Policy");
    }

    if (
      registrationQuestionResponses.some(
        (qr) =>
          qr.isMandatory &&
          (qr.responseType === "YesNoToggle"
            ? qr.value !== false && qr.value !== true
            : !qr.value),
      )
    ) {
      errors.push("Please answer required questions");
    }

    return errors.join("; ");
  }, [isPolicyRequired, didAcceptPolicy, registrationQuestionResponses]);

  useEffect(() => {
    if (validateStepAndAdvance) {
      handleValidationAndAdvanceStep(validateStep());
    }
  }, [validateStepAndAdvance, handleValidationAndAdvanceStep, validateStep]);

  const getRegistrationQuestionResponseInput = (qr) => {
    switch (qr.responseType) {
      case "Date":
        return <DateInput />;
      case "Number":
        return <input type="number" />;
      case "YesNoToggle":
        return (
          <Toggle
            allowNoOption={!qr.isMandatory}
            options={[
              {
                value: true,
                display: "Yes",
              },
              {
                value: false,
                display: "No",
              },
            ]}
          />
        );
      case "Text":
      default:
        return <input />;
    }
  };

  const onChangeRegistrationQuestionResponse = (responseType, changeProps) => {
    let name, value;

    switch (responseType) {
      case "YesNoToggle":
      case "Date":
        name = changeProps[0];
        value = changeProps[1];
        break;
      case "Number":
      case "Text":
      default:
        const evtTarget = changeProps[0].target;
        name = evtTarget.name;
        value = evtTarget.value;
    }

    onChangeRegistration(name, value);
  };

  return (
    <div className="full-width">
      <div className="card flat-card rsvp-terms-card mb-16">
        <div>
          {isPolicyRequired && (
            <>
              <p className="fw-700 xxl-text mb-24 mobile-xl-text mobile-mb-8">
                Terms and Conditions
              </p>
              <p className="fw-700 xl-text mb-16 mobile-reg-text">
                Covid-19 Policy
              </p>
              <p className="accent-text-dark mb-16">
                Read through the Covid-19 Policy and confirm that you agree to
                it.
              </p>
              <div
                style={{ whiteSpace: "pre-wrap" }}
                className="policy-textbox"
              >
                {policy}
              </div>
              <Checkbox
                checked={didAcceptPolicy}
                className="mt-16 policy-checkbox"
                error={submitAttempted && !didAcceptPolicy}
                label="I have read, understand and agree with the Covid-19 Policy"
                name="didAcceptPolicy"
                onChange={() => onToggleAcceptPolicy(!didAcceptPolicy)}
              />{" "}
            </>
          )}
          <p
            className={`fw-700 xl-text ${
              isPolicyRequired ? "mt-32" : ""
            } mb-16 mobile-reg-text`}
          >
            {registrationQuestionResponses.length
              ? "Some Quick Questions"
              : "Quick Question"}
          </p>
          {registrationQuestionResponses.map((qr, qrIndex) => (
            <div className="mb-32" key={qrIndex}>
              <p className="fw-700 xl-text mb-16 mobile-reg-text">
                Question {qrIndex + 1}
              </p>
              <div>
                <p className="accent-text-dark mb-16">
                  {qr.questionText}
                  {qr.isMandatory && (
                    <span className="error-text ml-8">*Required</span>
                  )}
                </p>
                <ValidatedInput
                  className={
                    qr.responseType === "YesNoToggle" ? "toggle-container" : ""
                  }
                  input={getRegistrationQuestionResponseInput(qr)}
                  name={`registrationQuestionResponses[${qrIndex}].value`}
                  onChange={(...changeProps) =>
                    onChangeRegistrationQuestionResponse(
                      qr.responseType,
                      changeProps,
                    )
                  }
                  required={
                    qr.isMandatory &&
                    (qr.responseType !== "YesNoToggle" ||
                      (qr.value !== true && qr.value !== false))
                  }
                  showRequired={submitAttempted}
                  style={
                    qr.responseType === "Date" || qr.responseType === "Number"
                      ? { width: "192px" }
                      : {}
                  }
                  value={qr.value}
                />
              </div>
            </div>
          ))}
        </div>
        {!!registrationQuestionResponses.length && (
          <p className="fw-700 xl-text mb-16 mobile-reg-text">
            Question {registrationQuestionResponses.length + 1}
          </p>
        )}
        <p className="accent-text-dark mb-16">
          Anything you would like us to know about, or any other comments?
        </p>
        <textarea
          className="custom-input full-width"
          name="notes"
          onChange={(e) => onChangeRegistration(e.target.name, e.target.value)}
          style={{ resize: "none" }}
          value={notes}
        />
      </div>
    </div>
  );
}

export default PolicyAgreementAndQuestions;
