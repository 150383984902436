import React from "react";
import { Navigation, PageLink } from "../../../../lib";
import Pages from "../../..";

export default ({
  juEnrollmentRequired,
  trip: {
    campusName,
    event: { isTravelTrip, programScheduleName } = {}, // retrieving programScheduleName from event because there is no enrollment info for registrationNotAvail state
  } = {},
}) => (
  <div className="trip-page-form-state">
    <img src="/images/registration_not_avail.svg" alt="" height="240" />
    <p className="fw-700 mt-24" style={{ fontSize: "34px" }}>
      Registration not available
    </p>
    {juEnrollmentRequired ? (
      <p className="mt-16 large-text">
        This trip is exclusively for JewishU frequent learners. Learn more about
        JewishU{" "}
        <a
          href="https://jewishu.org"
          className="link-text"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </p>
    ) : (
      <>
        <p className="mt-16 large-text">
          {campusName || "Your school"} is not enrolled in{" "}
          {programScheduleName || "any current trip"}.
          {isTravelTrip ? (
            <>
              <br />
              Please contact us at{" "}
              <a
                href="mailto:help@chabadoncampus.org"
                className="link-text"
                target="_blank"
                rel="noopener noreferrer"
              >
                help@chabadoncampus.org
              </a>{" "}
              or{" "}
              <a href="tel:+1-718-510-8181,0" className="link-text">
                718 510 8181 x 0
              </a>
            </>
          ) : (
            " Please contact your local Chabad on Campus"
          )}
          .
        </p>
        <PageLink
          className="btn btn-large btn-accent mt-48"
          style={{ width: "220px" }}
          to={Pages.profile.studentProfile}
          state={{
            previousLocationPageType: Navigation.page.type,
            previousLocationPath: Navigation.location.pathname,
          }}
        >
          View My Profile
        </PageLink>
      </>
    )}
  </div>
);
