import React from "react";
import Checkbox from "../../../../components/form/Checkbox";
import FadeOutMessage from "../../../../components/FadeOutMessage";
import { handleErrorMessage } from "../../../../lib";

export default class ConfirmEnrollment extends React.PureComponent {
  render() {
    const {
      courseName,
      didReviewAndAgree,
      enrollment: { error, loading },
      isShliachView,
      onConfirmEnrollment,
      submitAttempted,
      toggleDidReviewAndAgree,
    } = this.props;

    return (
      <div>
        <Checkbox
          className="accept-course-terms-checkbox medium-text"
          checked={didReviewAndAgree}
          error={submitAttempted && !didReviewAndAgree}
          label={`I agree and have reviewed the details of the ${courseName} course`}
          name="didReviewAndAgree"
          onChange={toggleDidReviewAndAgree}
        />

        <div className="text-right mt-24 relative">
          <button
            className="btn btn-accent btn-large"
            disabled={loading || isShliachView}
            onClick={onConfirmEnrollment}
            style={{ minWidth: 200 }}
          >
            {loading ? "Processing Enrollment..." : "Confirm Enrollment"}
          </button>

          {submitAttempted &&
            !loading &&
            (!didReviewAndAgree ? (
              <span
                className="error-text mt-4 small-text"
                style={{ position: "absolute", right: "0", bottom: -16 }}
              >
                Please accept enrollment agreement
              </span>
            ) : (
              error && (
                <FadeOutMessage
                  className="error-text mt-4 small-text"
                  message={handleErrorMessage(error)}
                  // onTimeout={() => this.setState({ errorMessage: "" })}
                  style={{ position: "absolute", right: "0", bottom: -16 }}
                  // timeout={20000}
                />
              )
            ))}
        </div>
      </div>
    );
  }
}
