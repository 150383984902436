import React from "react";
import moment from "moment";
import { PageURL, Navigation, formatCurrency } from "../../../../lib";
import Pages from "../../../../pages";
import { RsvpRegistrationStatuses } from "../../../../lib/RsvpHelpers";

export default class UpcomingEventCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      eventImageHeight: "auto",
      showMoreEventDescription: false,
      showMoreLessEventDescriptionOption: false,
    };

    this.eventCardRef = React.createRef();
    this.eventCardDescriptionRef = React.createRef();
  }

  componentDidMount() {
    if (this.eventCardRef.current) {
      this.setState({
        eventImageHeight: this.eventCardRef.current.clientWidth / 1.5,
      });
    }
    if (this.eventCardDescriptionRef.current) {
      let scrollHeight = this.eventCardDescriptionRef.current.scrollHeight;
      let height = this.eventCardDescriptionRef.current.clientHeight;
      const clamped = scrollHeight > height;
      if (clamped) {
        this.setState({ showMoreLessEventDescriptionOption: true });
      }
    }
  }

  getLocation() {
    const {
      eventSchedule: { address1, location, locationDisplay, locationName },
    } = this.props;

    switch (location) {
      case "Other":
        return address1 || locationName;
      case "Virtual":
        return location;
      default:
        return locationDisplay;
    }
  }

  getButtonText(userRegistrationStatus, isFullyBooked) {
    if (userRegistrationStatus === RsvpRegistrationStatuses.PendingPayment) {
      return "RSVP";
    }
    if (
      userRegistrationStatus === RsvpRegistrationStatuses.Waitlisted ||
      userRegistrationStatus === RsvpRegistrationStatuses.Registered
    ) {
      return "Edit Registration";
    }
    return isFullyBooked ? "Join the waitlist" : "RSVP";
  }

  getButton = (disableButton) => {
    const {
      eventPageURL,
      eventRegistrationComponent,
      eventSchedule: { isFullyBooked, userRegistrationStatus },
      user,
    } = this.props;
    return (
      /* RegistrationCompleted's 'More upcoming events' cards have eventRegistrationComponent for direct in-list event registration */
      eventRegistrationComponent || (
        <button
          className={`btn btn-accent btn-medium`}
          style={{ minWidth: 80 }}
          disabled={disableButton}
          onClick={() =>
            Navigation.go(
              user
                ? eventPageURL
                : PageURL.to(Pages.account.login, null, {
                    after: encodeURI(eventPageURL),
                  }),
            )
          }
        >
          {this.getButtonText(userRegistrationStatus, isFullyBooked)}
        </button>
      )
    );
  };

  render() {
    const {
      eventSchedule: {
        dayOfMonth,
        description,
        eventDateTime,
        formattedEventScheduleTime,
        imageURL,
        isFullyBooked,
        monthShortName,
        name,
        registrationFeeAmount,
        userRegistrationStatus,
        primarySmsCode,
      },
    } = this.props;

    const {
      eventImageHeight,
      showMoreEventDescription,
      showMoreLessEventDescriptionOption,
    } = this.state;

    const daysArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const disableButton =
      userRegistrationStatus === RsvpRegistrationStatuses.PendingPayment ||
      (isFullyBooked && registrationFeeAmount);

    return (
      <div className="rsvp-event-card card flat-card" ref={this.eventCardRef}>
        <div className="mb-16">
          <img
            alt={name}
            className="rsvp-event-img"
            src={imageURL || "/images/rsvp-event-card-placeholder.png"}
            style={{ height: eventImageHeight }}
          />
          <div className="rsvp-event-date">
            <div className="text-center">
              <p>{daysArray[moment(eventDateTime).day()]}</p>
              <p className="mt-4 mb-4 large-text fw-700">{dayOfMonth}</p>
              <p>{monthShortName}</p>
            </div>
          </div>

          <p className="text-center xl-text fw-700 mb-16">{name}</p>
          <p
            className={`rsvp-event-description mb-8 ${
              !showMoreEventDescription ? "hide-more-description" : ""
            }`}
            ref={this.eventCardDescriptionRef}
            style={{ whiteSpace: "pre-wrap", lineHeight: "21px" }}
          >
            {description}
          </p>
          {showMoreLessEventDescriptionOption && (
            <p
              className="link-text-secondary flex flex-align-center flex-justify-center mb-16"
              onClick={() =>
                this.setState({
                  showMoreEventDescription: !showMoreEventDescription,
                })
              }
            >
              <i className="material-icons">
                {showMoreEventDescription ? "expand_less" : "expand_more"}
              </i>
              show {showMoreEventDescription ? "less" : "more"}
            </p>
          )}
          {!!registrationFeeAmount && (
            <p className="fw-700 mb-16">
              {formatCurrency(registrationFeeAmount, "$")}
            </p>
          )}
        </div>
        <div>
          <div className="flex flex-justify-space fw-700 mb-16">
            <p className="flex medium-text fw-700">
              <i className="material-icons mr-4 large-text accent-text-secondary">
                place
              </i>
              {this.getLocation()}
            </p>
            <p className="flex medium-text fw-700">
              <i className="material-icons mr-4 large-text accent-text-secondary">
                access_time
              </i>
              {formattedEventScheduleTime}
            </p>
          </div>
          <div className="mt-24 flex flex-column flex-align-center">
            {isFullyBooked && !userRegistrationStatus && (
              <p
                className="flex flex-align-center flex-justify-center mb-8"
                style={{ color: "#FF9E57" }}
              >
                <i className="material-icons large-text mr-4">info</i>
                Event fully booked
              </p>
            )}
            {userRegistrationStatus === RsvpRegistrationStatuses.Registered && (
              <p
                className="flex flex-align-center flex-justify-center mb-8"
                style={{ color: "#33B7AE" }}
              >
                <i className="material-icons large-text mr-4">check</i> You are
                registered
              </p>
            )}
            {userRegistrationStatus === RsvpRegistrationStatuses.Waitlisted && (
              <p
                className="flex flex-align-center flex-justify-center error-text mb-8"
                style={{ color: "#FF9E57" }}
              >
                <i className="material-icons large-text mr-4">info</i> You are
                waitlisted
              </p>
            )}
            {userRegistrationStatus ===
              RsvpRegistrationStatuses.PendingPayment && (
              <p className="flex flex-align-center flex-justify-center error-text mb-8">
                <i className="material-icons large-text mr-4">info</i> You are
                pending payment
              </p>
            )}
            {disableButton ? (
              <span className="tooltip-container flex flex-align-center">
                <span
                  className="tooltip text-center"
                  style={{
                    whiteSpace: "unset",
                    width: "275px",
                  }}
                >
                  {userRegistrationStatus ===
                    RsvpRegistrationStatuses.PendingPayment && (
                    <span>
                      You’ve already tried registering for this event using SMS.
                      To complete your registration, text {primarySmsCode}.
                    </span>
                  )}
                  {isFullyBooked && registrationFeeAmount && (
                    <span>There is no waitlist available for this event.</span>
                  )}
                </span>
                {this.getButton(disableButton)}
              </span>
            ) : (
              this.getButton(disableButton)
            )}
          </div>
        </div>
      </div>
    );
  }
}
