import React, { useState, useCallback } from "react";
import ResourceDetailsCard from "./components/eventRegistration/ResourceDetailsCard";
import LogInToRegister from "./components/eventRegistration/registrationStates/LogInToRegister";
import ResourceRegistrationClosed from "./components/eventRegistration/registrationStates/ResourceRegistrationClosed";
import ResourceRegistrationCompleted from "./components/eventRegistration/registrationStates/ResourceRegistrationCompleted";
import {
  ResourceRegistrationForm,
  ResourceRegistrationFormWithStripe,
} from "./components/eventRegistration/registrationForm/RegistrationForm";
import StripeProvider from "../../components/StripeProvider";
import { PageLink } from "../../lib";
import Pages from "..";

function RsvpResourceRegistration(props) {
  const [registrationCompleted, setRegistrationCompleted] = useState(false);

  const onCompleteRegistration = useCallback(() => {
    setRegistrationCompleted(true);
    window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
  }, []);

  const getRegistrationForm = () => {
    const {
      actions: { doRsvpResourceRegistration },
      resource: { data: resource },
      resourceRegistration,
      pageRoute: {
        params: { chabadHouseSlug },
      },
      personId,
      user,
    } = props;

    const { registrationFeeAmount, askForDonation, stripePublicKey } = resource;

    const registrationFormProps = {
      chabadHouseSlug,
      doResourceRegistration: doRsvpResourceRegistration,
      resource,
      resourceRegistration,
      onCompleteRegistration: onCompleteRegistration,
      personId,
      user,
    };

    if (!!registrationFeeAmount || askForDonation) {
      if (!stripePublicKey) {
        return <div>This registration cannot be completed at this time.</div>;
      }

      return (
        <StripeProvider apiKey={stripePublicKey}>
          <ResourceRegistrationFormWithStripe {...registrationFormProps} />
        </StripeProvider>
      );
    }

    return <ResourceRegistrationForm {...registrationFormProps} />;
  };

  const {
    resource: { data: resource },
    pageRoute: {
      params: { chabadHouseSlug },
    },
    user,
  } = props;

  const { chabadHouseName, state } = resource || {};

  return (
    <div className="page rsvp-page">
      <div className="container rsvp-event-registration-container">
        <div className="flex flex-align-center flex-justify-space mobile-flex-align-top mb-32 mobile-mb-8 rsvp-event-registration-header">
          <PageLink
            className="link-text-secondary uppercase-text fw-700 desktop-hidden mr-16"
            params={{ chabadHouseSlug }}
            to={Pages.rsvp.events}
            query={{ rsvpType: "resources" }}
          >
            <i className="material-icons">arrow_back</i>
          </PageLink>
          <p className="xxl-text fw-700 mobile-large-text line-height-double mobile-mb-8">
            {chabadHouseName} RSVP System
          </p>
          <PageLink
            className="link-text uppercase-text fw-700 tablet-hidden mobile-hidden"
            params={{ chabadHouseSlug }}
            to={Pages.rsvp.events}
          >
            View all resources
          </PageLink>
        </div>
        <div className="flex flex-align-top mobile-block">
          <div className="rsvp-sidebar">
            <ResourceDetailsCard resource={resource} />
          </div>
          {state === "Unavailable" ? (
            <ResourceRegistrationClosed chabadHouseSlug={chabadHouseSlug} />
          ) : !user ? (
            <LogInToRegister rsvpType={"resource"} />
          ) : registrationCompleted ? (
            <ResourceRegistrationCompleted
              resourceName={resource.name}
              customMessage={resource.customConfirmationMessage}
            />
          ) : (
            getRegistrationForm()
          )}
        </div>
      </div>
    </div>
  );
}
export default React.memo(RsvpResourceRegistration);
