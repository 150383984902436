import React from "react";
import { formatCurrency } from "../../../../lib";

export default class ResourceDetailsCard extends React.PureComponent {
  state = {
    mobileShowResourceDescription: false,
  };

  render() {
    const {
      resource: { description, imageURL, name, registrationFeeAmount },
    } = this.props;

    const { mobileShowResourceDescription } = this.state;

    return (
      <div className="card rsvp-event-card flat-card mr-24">
        <img
          alt={name}
          className="rsvp-event-img"
          src={imageURL || "/images/rsvp-event-card-placeholder.png"}
        />
        <div className="event-details-header">
          <p className="text-center xl-text fw-700 mt-20 mb-8">{name}</p>
        </div>
        {!!registrationFeeAmount && (
          <p className="desktop-hidden fw-700 mb-8">
            {formatCurrency(registrationFeeAmount, "$")}
          </p>
        )}
        <p
          className={`line-height-double medium-text mobile-hidden tablet-hidden ${
            !!registrationFeeAmount ? "mb-8" : "mb-24"
          }`}
          style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
        >
          {description}
        </p>
        {!!registrationFeeAmount && (
          <p className="fw-700 mb-8 mobile-hidden tablet-hidden">
            {formatCurrency(registrationFeeAmount, "$")}
          </p>
        )}
        <div className="desktop-hidden">
          {mobileShowResourceDescription && (
            <p
              className="line-height-double medium-text mt-16"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {description}
            </p>
          )}
          <p
            className="link-text-secondary flex flex-align-center flex-justify-center mt-8"
            onClick={() =>
              this.setState({
                mobileShowResourceDescription: !mobileShowResourceDescription,
              })
            }
          >
            <i className="material-icons">
              {mobileShowResourceDescription ? "expand_less" : "expand_more"}
            </i>
            show {mobileShowResourceDescription ? "less" : "more"}
          </p>
        </div>
      </div>
    );
  }
}
