import React from "react";
import FadeOutMessage from "../../../../../components/FadeOutMessage";
import { debounce } from "throttle-debounce";

export default class DigitalPayButton extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      canMakePayment: false,
      isApplePay: false,
      paymentRequest: this.getPaymentRequest(),
    };
  }

  _isMounted = false;
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.amountCents !== this.props.amountCents) {
      this.setPaymentRequestDebounced();
    }
  }

  setPaymentRequestDebounced = debounce(500, () => {
    if (this._isMounted) {
      this.setState({
        canMakePayment: false,
        paymentRequest: this.getPaymentRequest(),
      });
    }
  });

  getPaymentRequest = () => {
    const { amountCents, createPaymentRequest } = this.props;

    let paymentRequest = null;

    if (amountCents) {
      const paymentTotalInCents = parseFloat(amountCents);

      paymentRequest = createPaymentRequest({
        country: "US",
        currency: "usd",
        total: {
          amount: paymentTotalInCents,
          label: "Total",
        },
      });

      paymentRequest.on("token", async ({ complete, token }) => {
        const status = await this.props.submitPayment(token);
        complete(status || "fail");
      });

      paymentRequest.canMakePayment().then((result) => {
        const canMakePayment = !!result;
        this.setState({
          canMakePayment,
          isApplePay: result ? result.applePay : false,
        });
      });
    }

    return paymentRequest;
  };

  onClick = async (event) => {
    event.preventDefault();

    const canPayDigital = await this.props.onClickDigitalPay();
    if (canPayDigital) {
      this.state.paymentRequest.show();
    }
  };

  render() {
    const {
      clearErrorMessage,
      errorMessage,
      incompleteRegistration,
      isPhone,
      isStudentIneligibleForAllTracks,
      loading,
      submitAttempted,
    } = this.props;
    const { canMakePayment, isApplePay } = this.state;

    return canMakePayment ? (
      <>
        <div className={!isPhone ? "digital-pay-container" : ""}>
          {!isPhone && <p className="medium-text mobile-mb-16">Or</p>}
          {!isApplePay ? (
            <button
              className="btn digital-pay-btn btn-large"
              disabled={loading}
              onClick={this.onClick}
            >
              <p className="uppercase-text">
                {loading ? "Processing..." : "Digital Wallet"}
              </p>
              {!loading && (
                <p className="small-text">
                  Use your browser's saved payment method
                </p>
              )}
            </button>
          ) : (
            <div
              class="apple-pay-button apple-pay-button-black"
              disabled={loading}
              onClick={this.onClick}
            />
          )}

          {submitAttempted &&
            (isStudentIneligibleForAllTracks || incompleteRegistration ? (
              <p className="error-text mt-4 small-text">
                Please{" "}
                {isStudentIneligibleForAllTracks
                  ? "review registration form for errors"
                  : "complete required or incomplete fields"}
              </p>
            ) : (
              errorMessage && (
                <FadeOutMessage
                  message={errorMessage}
                  onTimeout={clearErrorMessage}
                  timeout={20000}
                />
              )
            ))}
        </div>
        {isPhone && (
          <p className="mt-16 mb-24">Or pay with credit card below</p>
        )}
      </>
    ) : null;
  }
}
