import React, { useCallback } from "react";
import Radio from "../../../../components/form/Radio";
import ValidatedInput from "../../../../components/ValidatedInput";
import { formatCurrency, joinCommaDelimitedList } from "../../../../lib";
import moment from "moment";

function Tours(props) {
  const {
    onChange,
    registration,
    registrationValidation: { overlappingTourSchedules },
    trip: { programScheduleName, tours },
  } = props;
  const { tourScheduleIDs } = registration;

  const onToggleTourSchedules = useCallback(
    (id, tour) => {
      const scheduleId = parseInt(id, 10);

      let tourScheduleIDs = [...registration.tourScheduleIDs];

      const selectedTourSchedule = tour.schedules.find(
        (sched) => tourScheduleIDs.indexOf(sched.id) >= 0,
      );
      if (selectedTourSchedule) {
        tourScheduleIDs = tourScheduleIDs.filter(
          (id) => id !== selectedTourSchedule.id,
        );
      }

      const deselectTourSchedule =
        !tour.autoEnrollStudents &&
        selectedTourSchedule &&
        selectedTourSchedule.id === scheduleId;
      if (!deselectTourSchedule) {
        tourScheduleIDs.push(scheduleId);
      }

      onChange("tourScheduleIDs", tourScheduleIDs);
    },
    [onChange, registration.tourScheduleIDs],
  );

  return (
    <>
      <div className="trip-form-section-inner">
        <p className="section-title">Tours</p>
        <p className="accent-text-dark line-height-double">
          Select which group tours you would like to participate in during{" "}
          {programScheduleName || "the trip"}. Please note, based on your local
          Chabad enrollment settings, your tour options may differ and may be
          pre-selected.
        </p>
      </div>

      <div>
        {tours &&
          tours.map((tour) => {
            const {
              autoEnrollStudents,
              description,
              name,
              id,
              price,
              schedules,
            } = tour;
            const selectedTourSchedule = schedules.find(
              (sched) => tourScheduleIDs.indexOf(sched.id) >= 0,
            );

            return (
              <div key={id} className="tour">
                <div className="flex flex-align-center mb-8">
                  <p className="fw-700 medium-text">{name}</p>
                  {autoEnrollStudents && (
                    <span className="tooltip-container">
                      <i className="material-icons large-text ml-8 accent-text-dark">
                        info
                      </i>
                      <span className="tooltip">
                        This tour is mandatory for your group. For help, please
                        contact your local Chabad on Campus.
                      </span>
                    </span>
                  )}
                </div>
                <p
                  className="accent-text-dark mb-16"
                  style={{ lineHeight: "normal", whiteSpace: "pre-wrap" }}
                >
                  {description}
                </p>
                <p className="mb-24">
                  {price ? (
                    <>
                      <span className="fw-700 mr-8">Tour Fee:</span>
                      <span>{formatCurrency(price, "$")}</span>
                    </>
                  ) : (
                    "Free Tour"
                  )}
                </p>

                <div className="tour-schedule flex">
                  <ValidatedInput
                    input={
                      <Radio
                        options={schedules.map((schedule) => ({
                          disabled:
                            !autoEnrollStudents && schedule.isFullToCapacity,
                          display: (
                            <div
                              className={`flex ${
                                overlappingTourSchedules[schedule.id]
                                  ? "error-text"
                                  : ""
                              }`}
                            >
                              <p className="fw-700">{schedule.name}</p>
                              <p className="ml-8 mr-8">|</p>
                              <p>{`${moment(schedule.time).format(
                                "ddd MMM D h:mma",
                              )} - ${moment(schedule.calculatedEndTime).format(
                                "h:mma",
                              )}`}</p>
                            </div>
                          ),
                          tag: overlappingTourSchedules[schedule.id] ? (
                            <p className="scheduleOverlapError">
                              Conflicting tour schedules
                              <span className="tooltip-container">
                                <i className="material-icons medium-text ml-8">
                                  info
                                </i>
                                <span
                                  className="tooltip"
                                  style={{
                                    whiteSpace: "unset",
                                    width: "350px",
                                  }}
                                >
                                  Tour schedule conflicts with the{" "}
                                  {joinCommaDelimitedList(
                                    overlappingTourSchedules[schedule.id].map(
                                      (s) => s.name,
                                    ),
                                  )}{" "}
                                  times. Please adjust your tour selection.
                                </span>
                              </span>
                            </p>
                          ) : schedule.isFullToCapacity ? (
                            <p className="isFullError">Tour is full</p>
                          ) : null,
                          value: schedule.id,
                        }))}
                      />
                    }
                    name="tourSchedule"
                    onChange={(_, value) => onToggleTourSchedules(value, tour)}
                    value={
                      selectedTourSchedule ? selectedTourSchedule.id : null
                    }
                  />
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}

export default React.memo(Tours);
