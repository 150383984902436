import React from "react";
import RsvpResourceRegistration from "./RsvpResourceRegistration";
import { RsvpEventResourceRegistrationContainer } from "./RsvpEventRegistrationContainer";
import { withAppInsights } from "../../lib/AppInsights";

// TODO needs to be in its own file because non-default export withAppInsights isn't working
// possibly related to this issue which needs v2.5.5 to resolve?
// https://github.com/microsoft/ApplicationInsights-JS/issues/1274
function RsvpResourceRegistrationContainer(props) {
  const {
    actions,
    resource: { data: resource, error, loading },
    pageRoute,
    user,
  } = props;

  const getRsvpResource = async (pageRoute) => {
    await actions.getRsvpResource(pageRoute.params.resourceId, !user);
  };

  return (
    <RsvpEventResourceRegistrationContainer
      rsvpType="resource"
      getRsvpItem={getRsvpResource}
      rsvpItem={resource}
      hasRsvpItem={!!resource?.resourceID}
      error={error}
      loading={loading}
      pageRoute={pageRoute}
      user={user}
      registrationComponent={<RsvpResourceRegistration {...props} />}
      textBlanks={{
        reservation: "signup",
      }}
    />
  );
}

export default withAppInsights(RsvpResourceRegistrationContainer);
