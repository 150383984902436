import React from "react";
import Layout from "../../components/Layout.vm";
import ProfileForm from "./components/ProfileForm.vm";
import { Navigation } from "../../lib";
import { withAppInsights } from "../../lib/AppInsights";
import Pages from "../../pages";

class Profile extends React.PureComponent {
  getBackToPreviousTitle = () => {
    const { previousLocationPageType, previousLocationTitle } =
      Navigation.location.state || {};

    if (!previousLocationPageType && !previousLocationTitle) {
      return "";
    }

    if (previousLocationTitle) {
      return previousLocationTitle;
    }

    switch (previousLocationPageType) {
      case Pages.trips.register.type:
        return "Trip Registration";
      case Pages.yourIsrael.enroll.type:
        return "Your Israel Enrollment";
      default:
        return "";
    }
  };

  render() {
    const { accountConfirmed } = Navigation.location.state || {};
    const showAccountConfirmed = !!accountConfirmed;

    const backToPreviousTitle = this.getBackToPreviousTitle();

    return (
      <Layout {...this.props} title="My Profile">
        <div className="page container my-profile-page">
          <div className="card profile-card">
            <div className="flex flex-align-center">
              {backToPreviousTitle ? (
                <span
                  className="link-text flex flex-align-center mb-16"
                  style={{ width: "fit-content" }}
                  onClick={() => Navigation.goBack()}
                >
                  <i className="material-icons xl-text mr-8">arrow_back</i>
                  Back To {backToPreviousTitle}
                </span>
              ) : showAccountConfirmed ? (
                <span
                  className="text-right full-width fw-700 mb-16"
                  style={{
                    color: "#2d7c3b",
                    fontSize: "14px",
                  }}
                >
                  Your account has been successfully confirmed
                </span>
              ) : null}
            </div>
            <ProfileForm />
          </div>
        </div>
      </Layout>
    );
  }
}

export default withAppInsights(Profile);
