import React from "react";
import { getVersionedImgSrc, Navigation, PageLink } from "../../../../../lib";
import Pages from "../../../../../pages";

export default ({ rsvpType }) => (
  <div className="card trip-page-form-state">
    <img src={getVersionedImgSrc("coci-logo.svg")} alt="" height="240" />
    <p className="fw-700 mt-24" style={{ fontSize: "34px" }}>
      Please log in to RSVP for this {rsvpType}
    </p>
    <PageLink
      className="btn btn-accent btn-large mt-32"
      query={{ after: encodeURI(Navigation.locationURL) }}
      style={{ padding: "0 16px" }}
      to={Pages.account.login}
    >
      Log in
    </PageLink>
  </div>
);
