import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { Navigation } from "../lib";

const { REACT_APP_INSIGHTS_KEY } = process.env;

var reactPlugin = new ReactPlugin();

export function appInsightsInit() {
  if (REACT_APP_INSIGHTS_KEY && Navigation && Navigation.history) {
    var appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: REACT_APP_INSIGHTS_KEY,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: Navigation.history },
        },
      },
    });
    appInsights.loadAppInsights();
  }
}

var portalName = "Student";
export var withAppInsights = (Component) =>
  withAITracking(reactPlugin, Component, portalName + Component.name);
