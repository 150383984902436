import React from "react";
import { PageLink, pluralizeText } from "../../../../../lib";
import Pages from "../../../../../pages";

// TODO merge screens with similar functionality
function RegistrationNotAvailable({ rsvpType, chabadHouseSlug }) {
  return (
    <div className="card flat-card full-width flex flex-align-center flex-justify-center text-center rsvp-state-card">
      <div className="text-center">
        <img src="/images/registration_not_avail.svg" alt="" height="200" />
        <p className="fw-700 mt-24 rsvp-state-card-title">
          This {rsvpType} is fully booked
        </p>
        <p className="mt-16 large-text">
          Make sure to check out one of our many other {pluralizeText(rsvpType)}
        </p>
        <PageLink
          className="btn btn-accent btn-medium mt-32 inline-flex"
          params={{ chabadHouseSlug }}
          style={{ padding: "0 16px" }}
          to={Pages.rsvp.events}
        >
          View all {pluralizeText(rsvpType)}
        </PageLink>
      </div>
    </div>
  );
}

export default RegistrationNotAvailable;
