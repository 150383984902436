import React from "react";
import { getVersionedImgSrc, PageLink } from "../../../lib";
import Pages from "../../../pages";

export default ({ children: view }) => (
  <div className="login-page account-page">
    <PageLink to={Pages.account.login.path}>
      <img
        src={getVersionedImgSrc("coci-logo.svg")}
        alt="logo"
        className="logo mb-24"
        height="56"
      />
    </PageLink>
    <div className="account-card card">{view}</div>
  </div>
);
