import React from "react";
import ValidatedInput from "../../../components/ValidatedInput";
import Select from "../../../components/form/Select";

export default class FamilyBackgroundInfo extends React.PureComponent {
  render() {
    const {
      getProfileFieldLabel,
      isProfileFieldRequired,
      jewishBackgroundTypes,
      onChangeProfile,
      profile: { fatherBackground, motherBackground } = {},
      showFormValidation,
    } = this.props;

    return (
      <div className="profile-form-section">
        <p className="section-title">Family background</p>
        <div className="profile-form-grid">
          <div className="relative">
            <ValidatedInput
              input={
                <Select
                  clearable={true}
                  options={jewishBackgroundTypes.map((bg) => ({
                    label: bg.displayValue,
                    value: bg.enumValue,
                  }))}
                />
              }
              label={getProfileFieldLabel(
                "Father's background",
                "fatherBackground",
              )}
              name="fatherBackground"
              onChange={onChangeProfile}
              required={isProfileFieldRequired("fatherBackground")}
              showRequired={showFormValidation}
              value={fatherBackground}
            />
          </div>
          <div className="relative">
            <ValidatedInput
              input={
                <Select
                  clearable={true}
                  options={jewishBackgroundTypes.map((bg) => ({
                    label: bg.displayValue,
                    value: bg.enumValue,
                  }))}
                />
              }
              label={getProfileFieldLabel(
                "Mother's background",
                "motherBackground",
              )}
              name="motherBackground"
              onChange={onChangeProfile}
              required={isProfileFieldRequired("motherBackground")}
              showRequired={showFormValidation}
              value={motherBackground}
            />
          </div>
        </div>
      </div>
    );
  }
}
