import React from "react";

export default class JoinWaitlist extends React.PureComponent {
  render() {
    const { errorMessage, loading, submitRegistration } = this.props;

    return (
      <div className="card flat-card full-width flex flex-align-center flex-justify-center text-center rsvp-state-card">
        <div className="text-center">
          <img src="/images/registration_not_avail.svg" alt="" height="200" />
          <p className="fw-700 mt-24 rsvp-state-card-title">
            This event is fully booked
          </p>
          <p className="mt-16 large-text">
            Join the waitlist and we'll be in touch if space becomes available
          </p>
          <button
            className="btn btn-accent btn-medium mt-32 inline-flex"
            disabled={loading}
            onClick={submitRegistration}
            style={{ padding: "0 16px" }}
          >
            {loading ? "Joining the Waitlist..." : "Join the Waitlist"}
          </button>
          {errorMessage && <p className="error-text mt-8">{errorMessage}</p>}
        </div>
      </div>
    );
  }
}
