import { removeEmptyFromObj } from "./utils";

export function constructStripeBillingDetails(
  cardHolderName,
  address,
  countries,
) {
  const billingDetails = {};

  if (cardHolderName) {
    billingDetails.name = cardHolderName;
  }

  if (address) {
    billingDetails.address = {
      line1: address.address1,
      line2: address.address2,
      city: address.city,
      state: address.state,
      postal_code: address.zip,
      country: (
        (countries || []).find((country) => country.name === address.country) ||
        {}
      ).code,
    };
  }
  return billingDetails;
}

export const paymentTypes = {
  creditCard: "creditCard",
  manual: "manual",
  digital: "digital",
  payPal: "payPal",
};

const defaultErrorMessage =
  "Sorry, we are unable to process your credit card payment.  Please try again.";

export async function submitOneTimeStripePayment(
  stripe,
  billingDetails,
  objectForSubmission,
  doSubmitPayment,
  paymentType = paymentTypes.manual,
  digitalPaymentData = {},
) {
  const { completeDigitalPayment = null, digitalPayPaymentMethod = null } =
    digitalPaymentData;

  let paymentMethodId;

  if (paymentType === paymentTypes.payPal) {
    throw new Error("PayPal payment cannot be processed here");
  } else {
    if (paymentType === paymentTypes.digital) {
      paymentMethodId = digitalPayPaymentMethod.id;
    } else {
      let { error: paymentMethodError, paymentMethod } =
        await stripe.createPaymentMethod("card", {
          billing_details: billingDetails,
        });

      if (paymentMethodError) {
        throw new Error(paymentMethodError.message || defaultErrorMessage);
      }
      paymentMethodId = paymentMethod.id;
    }

    // Additional check in case paymentMethodId was not created and no error was thrown
    if (paymentMethodId) {
      if (objectForSubmission.paymentInfo) {
        // TODO to handle RsvpRegistrationAPI
        objectForSubmission.paymentInfo.paymentMethodId = paymentMethodId;
      } else {
        objectForSubmission.paymentMethodId = paymentMethodId;
      }
    } else {
      if (paymentType === paymentTypes.digital) {
        completeDigitalPayment("fail");
      }
      throw new Error(defaultErrorMessage);
    }
  }

  removeEmptyFromObj(objectForSubmission);

  let orderResponse = await doSubmitPayment(
    // this.apiSignal.token,
    objectForSubmission,
  );

  if (paymentType === paymentTypes.digital) {
    // hide digital pay window to allow SCA Action window interaction
    completeDigitalPayment("success");
  }

  if (orderResponse.error) {
    throw orderResponse.error;
  }

  if (orderResponse.data.requiresAction) {
    // SCA Action Required
    const { error: paymentIntentError, paymentIntent } =
      await stripe.handleCardAction(
        orderResponse.data.paymentIntentClientSecret,
      );

    if (paymentIntentError) {
      throw new Error(paymentIntentError.message || defaultErrorMessage);
    } else {
      // The card action has been handled. The PaymentIntent can be confirmed again on the server
      if (objectForSubmission.paymentInfo) {
        // TODO to handle RsvpRegistrationAPI
        delete objectForSubmission.paymentInfo.paymentMethodId;
        objectForSubmission.paymentInfo.paymentIntentId = paymentIntent.id;
      } else {
        delete objectForSubmission.paymentMethodId;
        objectForSubmission.paymentIntentId = paymentIntent.id;
      }

      orderResponse = await doSubmitPayment(
        // this.apiSignal.token,
        objectForSubmission,
      );

      if (orderResponse.error) {
        throw orderResponse.error;
      }

      // Checking again if still requiresAction for some reason and if so returning error message
      if (orderResponse.data.requiresAction) {
        throw new Error(defaultErrorMessage);
      }
    }
  }
}
