import React, { useCallback, useEffect } from "react";
import { StripeCardElementWithName } from "../../../../../components/StripeCardElement";
import { formatCurrency } from "../../../../../lib";
import DonationField from "./DonationField";

function RegistrationPayment(props) {
  const {
    rsvpItem: { registrationFeeAmount, askForDonation },
    registration: {
      paymentInfo: { donationAmount },
    },
    onChangeRegistration,
    willPayDonation,
    setWillPayDonation,
    ccInfoComplete,
    setCcInfoComplete,
    cardHolderFullName,
    setCardHolderFullName,
    validateStepAndAdvance,
    handleValidationAndAdvanceStep,
    submitAttempted,
  } = props;

  const validateStep = useCallback(() => {
    const errors = [];

    if (willPayDonation && (!donationAmount || donationAmount <= 0)) {
      errors.push("Please enter donation amount greater than 0");
    }

    if (
      (!!registrationFeeAmount || willPayDonation) &&
      (!ccInfoComplete || !cardHolderFullName)
    ) {
      errors.push("Please complete all payment fields");
    }

    return errors.join("; ");
  }, [
    ccInfoComplete,
    cardHolderFullName,
    registrationFeeAmount,
    willPayDonation,
    donationAmount,
  ]);

  useEffect(() => {
    if (validateStepAndAdvance) {
      handleValidationAndAdvanceStep(validateStep());
    }
  }, [validateStepAndAdvance, handleValidationAndAdvanceStep, validateStep]);

  const formatAmount = (label, value, textSize) => (
    <p className={`${textSize || "medium-text"} mb-8`}>
      {label}: {formatCurrency(value, "$")}
    </p>
  );

  const creditCardInfo = (
    <>
      <div className="fw-700 xl-text mb-16 mobile-reg-text">
        Credit card info
      </div>
      <StripeCardElementWithName
        onComplete={setCcInfoComplete}
        cardHolderFullName={cardHolderFullName}
        onChangeName={(_, name) => setCardHolderFullName(name)}
        isNameRequired={registrationFeeAmount || willPayDonation}
        submitAttempted={submitAttempted}
      />
    </>
  );

  return (
    <div className="card flat-card rsvp-terms-card mb-16">
      <div>
        <div className="fw-700 xxl-text mb-24 mobile-xl-text mobile-mb-16">
          {!!registrationFeeAmount ? "Payment" : "Add a donation"}
        </div>

        {!registrationFeeAmount ? (
          // Donation-only
          <>
            <DonationField
              willPayDonation={willPayDonation}
              setWillPayDonation={setWillPayDonation}
              donationAmount={donationAmount}
              onChangeRegistration={onChangeRegistration}
              submitAttempted={submitAttempted}
              showCheckbox
            />
            {willPayDonation && (
              <div className="mt-32 mb-16">{creditCardInfo}</div>
            )}
          </>
        ) : (
          // Registration fee
          <>
            <div className="mb-32">{creditCardInfo}</div>
            {askForDonation && (
              <div className="mb-32">
                {!!registrationFeeAmount && (
                  <div className="fw-700 xl-text mb-16 mobile-reg-text">
                    Add a donation
                  </div>
                )}
                <DonationField
                  willPayDonation={willPayDonation}
                  setWillPayDonation={setWillPayDonation}
                  donationAmount={donationAmount}
                  onChangeRegistration={onChangeRegistration}
                  submitAttempted={submitAttempted}
                  showCheckbox
                />
              </div>
            )}
          </>
        )}

        <div className="flex flex-align-center">
          <div>
            {/* Only show breakdown if there are two line items */}
            {!!registrationFeeAmount && askForDonation && (
              <>
                {formatAmount("Event Fee", registrationFeeAmount)}
                {formatAmount("Donation", willPayDonation ? donationAmount : 0)}
              </>
            )}
            {/* Only show total amount if there will be a payment */}
            {(!!registrationFeeAmount || willPayDonation) && (
              <div className="mt-16">
                {formatAmount(
                  "Total Amount",
                  registrationFeeAmount + (donationAmount || 0),
                  "large-text fw-700",
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegistrationPayment;
