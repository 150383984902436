import React from "react";
import Loader from "./Loader";
import Axios from "axios";
import Modal from "./Modal";

class FilePreviewModal extends React.PureComponent {
  state = {
    error: false,
    fileType: null,
    loading: false,
    secureFileURL: null,
  };

  componentDidMount() {
    if (this.props.show) {
      this.getFileInfo();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.getFileInfo();
    }
  }

  getFileInfo = () => {
    const { isSecure, type, url } = this.props;
    if (isSecure) {
      this.getSecureFileURL();
    } else {
      this.setState({
        fileType: this.getFileType(type, url),
      });
    }
  };

  getSecureFileURL = async () => {
    this.setState({ error: false, loading: true });

    try {
      const { type, url } = this.props;

      const secureFileURL = await this.props.actions.getSecureBlobURL(url);
      //secure-file-read test:
      await Axios.get(secureFileURL);

      this.setState({
        fileType: this.getFileType(type, secureFileURL),
        loading: false,
        secureFileURL,
      });
    } catch (err) {
      this.setState({
        error: true,
        loading: false,
      });
    }
  };

  getFileType(type, url) {
    if (type && this.supportedFileTypes.indexOf(type) >= 0) {
      return type;
    }

    if (url) {
      if (url.indexOf(".pdf") > 0) {
        return "application/pdf";
      } else if (url.indexOf(".html") > 0) {
        return "text/html";
      } else if (url.indexOf(".png") > 0) {
        return "image/png";
      } else if (url.indexOf(".jpeg") > 0 || url.indexOf(".jpg") > 0) {
        return "image/jpeg";
      } else if (url.indexOf(".tif") > 0 || url.indexOf(".tiff") > 0) {
        return "image/tif";
      } else if (url.indexOf(".gif") > 0) {
        return "image/gif";
      } else if (url.indexOf(".bmp") > 0) {
        return "image/bmp";
      }
    }

    return "";
  }

  supportedFileTypes = [
    "application/pdf",
    "text/html",
    "image/png",
    "image/jpeg",
    "image/tif",
    "image/gif",
    "image/bmp",
  ];

  onClose = () => {
    this.setState({
      error: false,
      fileType: null,
      loading: false,
      secureFileURL: null,
    });

    this.props.close();
  };

  render() {
    const { isPhone, isSecure, show, title, type, url } = this.props;
    const { error, fileType, loading, secureFileURL } = this.state;

    const downloadFile = isPhone || !fileType;

    return (
      <Modal show={show} className="large-modal file-preview-modal">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="flex flex-justify-space flex-align-center mb-24">
              <p className="xl-text fw-500">
                {error ? "Something went wrong" : title}
              </p>
              <i
                className="material-icons pointer link-text-secondary"
                onClick={this.onClose}
              >
                close
              </i>
            </div>
            {error ? (
              <div className="error-text preview-message">
                Sorry, something went wrong and your preview could not be
                loaded. Please try again.
              </div>
            ) : downloadFile ? (
              <div className="preview-message">
                <p>
                  File preview unavailable{" "}
                  {isPhone ? "on mobile devices" : "for this file"}. Click{" "}
                  <a
                    className="link-text"
                    download
                    href={isSecure ? secureFileURL : url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>{" "}
                  to download.
                </p>
              </div>
            ) : (
              <object data={isSecure ? secureFileURL : url} type={type}>
                <embed src={isSecure ? secureFileURL : url} type={type} />
              </object>
            )}
          </>
        )}
      </Modal>
    );
  }
}
export default FilePreviewModal;
