import React from "react";
import { Navigation, PageLink } from "../../../../lib";
import Pages from "../../../../pages";

export default ({
  campus,
  chabadHouseNotEnrolled,
  courseScheduleNotFound,
  isShliachView,
}) => (
  <div className="your-israel-page-form-state">
    <img
      src="/images/registration_not_avail.svg"
      alt=""
      height="240"
      style={{ maxWidth: "100%" }}
    />
    <p className="fw-700 mt-24" style={{ fontSize: "34px" }}>
      {chabadHouseNotEnrolled
        ? "Enrollment not available"
        : courseScheduleNotFound
        ? "We can't find the course you're looking for"
        : "Hmmm, No upcoming courses scheduled"}
    </p>
    <div className="mt-16 large-text line-height-double mobile-text-center">
      {chabadHouseNotEnrolled ? (
        `${
          campus ? campus.name : "Your campus"
        } is not enrolled in Your Israel. Please contact your local Chabad on Campus.`
      ) : courseScheduleNotFound ? (
        <p>
          The course you are requesting does not exist. Please contact your
          Rabbi or{" "}
          <PageLink
            className="link-text"
            to={Pages.yourIsrael.enroll}
            params={{ scheduleId: undefined }}
          >
            click here
          </PageLink>{" "}
          for the latest course offered by your Chabad on Campus.
        </p>
      ) : (
        `${
          campus ? campus.name : "Your campus"
        } does not have any upcoming courses scheduled. Please contact your local Chabad on Campus.`
      )}
    </div>
    {!isShliachView && (
      <PageLink
        className="btn btn-large btn-accent mt-48"
        style={{ width: "220px" }}
        pathname={Pages.profile.studentProfile.path}
        state={{
          previousLocationPageType: Navigation.page.type,
          previousLocationPath: Navigation.location.pathname,
        }}
      >
        View My Profile
      </PageLink>
    )}
  </div>
);
