import React from "react";
import Layout from "../../components/Layout.vm";
import Loader from "../../components/Loader";
import ProfileForm from "../profile/components/ProfileForm.vm";
import RsvpError from "./components/RsvpError";
import RsvpEventRegistration from "./RsvpEventRegistration";
import { withAppInsights } from "../../lib/AppInsights";

function RsvpEventRegistrationContainer(props) {
  const {
    actions,
    eventSchedule: { data: eventSchedule, error, loading },
    pageRoute,
    user,
  } = props;

  const getRsvpEventSchedule = async (pageRoute) => {
    await actions.getRsvpEventSchedule(pageRoute.params.eventScheduleId, !user);
  };

  return (
    <RsvpEventResourceRegistrationContainer
      rsvpType="event"
      getRsvpItem={getRsvpEventSchedule}
      rsvpItem={eventSchedule}
      hasRsvpItem={!!eventSchedule?.eventScheduleID}
      error={error}
      loading={loading}
      pageRoute={pageRoute}
      user={user}
      registrationComponent={<RsvpEventRegistration {...props} />}
      textBlanks={{
        reservation: "registration",
      }}
    />
  );
}
export default withAppInsights(RsvpEventRegistrationContainer);

export class RsvpEventResourceRegistrationContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      checkProfileStatus: !!props.user, // for logged in users: do not show enrollment form until profile status has been checked
      initialized: false,
      profileStatusCheckError: false,
      showCompleteProfileForm: false,
    };
  }

  componentDidMount() {
    this.props.getRsvpItem(this.props.pageRoute);
    this.setState({ initialized: true });
  }

  onProfileIsComplete = (success = true) => {
    if (this.state.showCompleteProfileForm) {
      this.setState({
        showCompleteProfileForm: false,
      });
    }

    if (!success) {
      this.setState({
        profileStatusCheckError: true,
      });
    }
  };

  render() {
    const {
      rsvpType,
      rsvpItem,
      hasRsvpItem,
      error,
      loading,
      registrationComponent,
      textBlanks: { reservation = "registration" },
    } = this.props;

    const { name, chabadHouseID, programID, isAllergiesRequired } =
      rsvpItem || {};

    const {
      checkProfileStatus,
      initialized,
      profileStatusCheckError,
      showCompleteProfileForm,
    } = this.state;

    return (
      <Layout
        {...this.props}
        title={`RSVP${rsvpItem && name ? ` for ${name}` : ""}`}
      >
        <div className="page your-israel-page">
          {!initialized || loading ? (
            <div className="full-page-loader">
              <Loader />
            </div>
          ) : error ? (
            <RsvpError error={error} errorType={rsvpType} />
          ) : (
            hasRsvpItem &&
            (profileStatusCheckError ? (
              <div className="text-center">
                <img src="/images/error.svg" alt="error-bot" height="240" />
                <p className="medium-text mt-24 mb-16">
                  Sorry, something went wrong and we could not retrieve your
                  profile information for {rsvpType} {reservation}.
                </p>
                <p className="medium-text accent-text fw-500">
                  Please reload the page to try again.
                </p>
              </div>
            ) : checkProfileStatus || showCompleteProfileForm ? (
              <ProfileForm
                chabadHouseId={chabadHouseID}
                completeProfileReason={`${rsvpType} ${reservation}`}
                isCompleteProfilePrompt={true}
                programId={programID}
                onProfileFetched={(success, isProfileComplete) => {
                  this.setState({
                    checkProfileStatus: false,
                    showCompleteProfileForm: !isProfileComplete,
                  });
                  if (!success) {
                    //need to show error msg if profile retrieval failed
                    this.onProfileIsComplete(false);
                  }
                }}
                onProfileIsComplete={() => this.onProfileIsComplete()}
                requireAllergies={isAllergiesRequired}
              />
            ) : (
              registrationComponent
            ))
          )}
        </div>
      </Layout>
    );
  }
}
