import React from "react";
import CourseLocation from "./enrollment/CourseLocation";
import CourseSchedule from "./enrollment/CourseSchedule";
import CourseSurveyLink from "./enrollment/CourseSurveyLink";

export default function EnrollmentDetails(props) {
  const {
    enrollmentStatus,
    enrollmentUpdate: { loading: enrollmentUpdateLoading },
    refreshCourseDetails,
    refreshingCourseDetails,
    yourIsraelCourse,
    yourIsraelCourse: { courseScheduleEnrollment },
    yourIsraelCourseSchedule,
  } = props;

  const enrollment = //ensure that the enrollment matches the course schedule we're displaying
    courseScheduleEnrollment &&
    courseScheduleEnrollment.eduCourseScheduleID === yourIsraelCourseSchedule.id
      ? courseScheduleEnrollment
      : {};

  const isRejected = enrollmentStatus === "Rejected";

  return (
    <div className="your-israel-page-form-container container">
      <div className="card">
        <div className="your-israel-form-section">
          <p className="fw-900 xl-text mb-16 mobile-mt-32">
            {yourIsraelCourseSchedule.name} {!isRejected && "Enrollment"}
          </p>
          {!refreshingCourseDetails && (
            <div className="accent-text line-height-double">
              {enrollment.didCompleteCourse ? (
                `You've completed the ${yourIsraelCourseSchedule.name} course.`
              ) : !yourIsraelCourseSchedule.didClassesEnd ? (
                `You are ${
                  enrollmentStatus === "Pending"
                    ? "pending enrollment in"
                    : isRejected
                    ? "ineligible for"
                    : "enrolled in"
                } ${yourIsraelCourseSchedule.name}. ${
                  !isRejected ? "See course details below." : ""
                }`
              ) : (
                <React.Fragment>
                  {`Course completion is pending ${
                    !enrollment.didCompleteAttendance
                      ? "complete course attendance"
                      : !enrollment.didCompleteSurvey
                      ? "Course Review and Evaluation"
                      : ""
                  }.`}
                  {enrollment.didCompleteAttendance &&
                    !enrollment.didCompleteSurvey && (
                      <CourseSurveyLink
                        className="mt-8"
                        courseScheduleEnrollment={enrollment}
                        loading={enrollmentUpdateLoading}
                        refreshCourseDetails={refreshCourseDetails}
                      />
                    )}
                </React.Fragment>
              )}
            </div>
          )}
        </div>
        <CourseLocation
          location={yourIsraelCourse.courseLocation}
          notes={yourIsraelCourseSchedule.notes}
        />

        <CourseSchedule courseSchedule={yourIsraelCourseSchedule} />
      </div>
    </div>
  );
}
