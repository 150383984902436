import React from "react";
import { handleErrorMessage } from "../../../lib";

export default class RsvpError extends React.PureComponent {
  render() {
    const { error, errorType } = this.props;
    const isNotFoundError =
      error && error.response && error.response.status === 404;

    return (
      <div className="trip-page-form-state">
        <img
          alt=""
          height="240"
          src={
            isNotFoundError
              ? "/images/registration_not_avail.svg"
              : "/images/error.svg"
          }
        />
        {isNotFoundError ? (
          <p className="xxl-text mt-24 mb-16">
            Sorry, the {errorType} that you are trying to access is not
            available
          </p>
        ) : (
          <React.Fragment>
            <p className="xxl-text mt-24 mb-16">Something went wrong</p>
            <p className="medium-text accent-text fw-500">
              {handleErrorMessage(
                error,
                "Sorry for the inconvenience. Our server is being stubborn, please try again.",
              )}
            </p>
          </React.Fragment>
        )}
      </div>
    );
  }
}
