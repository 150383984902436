import SurveyCompleted from "./SurveyCompleted.vm";
import YourIsraelEnrollment from "./YourIsraelEnrollment";

export const YourIsraelPages = {
  surveyCompleted: {
    anon: false,
    path: "/yourisrael/review-completed",
    title: "Your Israel Review Completed",
    type: "PAGE_YOUR_ISRAEL_SURVEY_COMPLETED",
    view: SurveyCompleted,
  },
  enroll: {
    anon: false,
    path: "/yourisrael/:scheduleId?",
    title: "Enroll in Your Israel",
    type: "PAGE_YOUR_ISRAEL_ENROLLMENT",
    view: YourIsraelEnrollment,
  },
};
export default YourIsraelPages;

export const YourIsraelArea = {
  pages: YourIsraelPages,
};
