import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { CampusesActions, ProfileActions } from "../../../state";
import ProfileForm from "./ProfileForm";

export default connect(
  function mapState(state) {
    return {
      campuses: state.campuses,
      profile: state.profile.get,
      profileSettings: state.profile.settings,
      studentId: state.auth.studentID,
      submitProfile: state.profile.submit,
      sys: state.sys,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...CampusesActions,
          ...ProfileActions,
        },
        dispatch,
      ),
    };
  },
)(ProfileForm);
