import React from "react";
import moment from "moment";
import { formatCurrency } from "../../../../lib";

export default class EventDetailsCard extends React.PureComponent {
  state = {
    mobileShowEventDescription: false,
  };

  render() {
    const {
      eventSchedule: {
        address1,
        address2,
        dayOfMonth,
        description,
        eventDateTime,
        formattedEventScheduleTime,
        imageURL,
        location,
        locationDisplay,
        locationName,
        monthShortName,
        name,
        registrationFeeAmount,
      },
    } = this.props;

    const { mobileShowEventDescription } = this.state;

    const daysArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    return (
      <div className="card rsvp-event-card flat-card mr-24">
        <img
          alt={name}
          className="rsvp-event-img"
          src={imageURL || "/images/rsvp-event-card-placeholder.png"}
        />
        <div className="event-details-header">
          <div className="rsvp-event-date">
            <div className="text-center">
              <p>{daysArray[moment(eventDateTime).day()]}</p>
              <p className="mt-4 mb-4 large-text fw-700">{dayOfMonth}</p>
              <p>{monthShortName}</p>
            </div>
          </div>
          <p className="text-center xl-text fw-700 mb-8">{name}</p>
        </div>
        {!!registrationFeeAmount && (
          <p className="desktop-hidden fw-700 mb-8">
            {formatCurrency(registrationFeeAmount, "$")}
          </p>
        )}
        <p
          className={`line-height-double medium-text mobile-hidden tablet-hidden ${
            !!registrationFeeAmount ? "mb-8" : "mb-24"
          }`}
          style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
        >
          {description}
        </p>
        {!!registrationFeeAmount && (
          <p className="fw-700 mb-8 mobile-hidden tablet-hidden">
            {formatCurrency(registrationFeeAmount, "$")}
          </p>
        )}
        <div className="mobile-flex flex-justify-space">
          <p className="flex mb-16 mobile-mb-0 fw-700 medium-text">
            <i className="material-icons mr-4 large-text accent-text-secondary">
              place
            </i>
            {location === "Other"
              ? `${locationName ? locationName : ""}
              ${address1 ? (locationName ? " - " + address1 : address1) : ""}
              ${address2 ? " " + address2 : ""}`
              : location === "Virtual"
              ? location
              : locationDisplay}
          </p>
          <p className="flex medium-text fw-700">
            <i className="material-icons mr-4 large-text accent-text-secondary">
              access_time
            </i>
            {formattedEventScheduleTime}
          </p>
        </div>
        <div className="desktop-hidden">
          {mobileShowEventDescription && (
            <p
              className="line-height-double medium-text mt-16"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {description}
            </p>
          )}
          <p
            className="link-text-secondary flex flex-align-center flex-justify-center mt-8"
            onClick={() =>
              this.setState({
                mobileShowEventDescription: !mobileShowEventDescription,
              })
            }
          >
            <i className="material-icons">
              {mobileShowEventDescription ? "expand_less" : "expand_more"}
            </i>
            show {mobileShowEventDescription ? "less" : "more"}
          </p>
        </div>
      </div>
    );
  }
}
