import React from "react";
// import AddToGoogleCalendar from "../registrationForm/AddToGoogleCalendar";
import MoreUpcomingEventsList from "./registrationCompleted/MoreUpcomingEventsList";

export default class EventRegistrationCompleted extends React.PureComponent {
  render() {
    const {
      eventSchedule,
      completedEventRegistration,
      showUpcomingEvents = true,
      customMessage,
    } = this.props;
    return (
      <div className="full-width">
        <div className="card flat-card flex flex-align-center flex-justify-center rsvp-state-card">
          <div className="text-center">
            <img src="/images/registration_complete.svg" alt="" height="240" />
            {completedEventRegistration &&
            completedEventRegistration.status === "Waitlisted" ? (
              <React.Fragment>
                <p className="fw-700 mt-24 rsvp-state-card-title">
                  You've been waitlisted for{" "}
                  {eventSchedule.name || "this event"}
                </p>
                <p className="mt-16 large-text line-height-double text-center">
                  We'll be in touch if space becomes available
                </p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <p className="fw-700 mt-24 rsvp-state-card-title">Thank You!</p>
                <p className="mt-16 large-text line-height-double text-center">
                  {customMessage ||
                    `You’ve successfully registered for ${eventSchedule.name}. We can't wait to see you!`}
                </p>
                {/* TODO: temporarily removing google calendar integration, pending oauth scope access */}
                {/* <AddToGoogleCalendar
                  className="mt-24"
                  eventSchedule={eventSchedule}
                /> */}
              </React.Fragment>
            )}
          </div>
        </div>
        {showUpcomingEvents && <MoreUpcomingEventsList {...this.props} />}
      </div>
    );
  }
}
