import React from "react";
// import AddToGoogleCalendar from "../../registrationForm/AddToGoogleCalendar";
import FadeOutMessage from "../../../../../../components/FadeOutMessage";
import {
  handleErrorMessage,
  AuthRequest,
  Navigation,
} from "../../../../../../lib";
import { RsvpRegistrationStatuses } from "../../../../../../lib/RsvpHelpers";

export default class UpcomingEventRegistration extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      eventSchedule: {},
      loading: false,
      registration: {},
      successMessage: "",
    };
  }

  onRegister = async (waitlistRegistration = false) => {
    const { eventScheduleID, eventPageUrl } = this.props;

    const registrationState = {
      errorMessage: "",
      loading: true,
      successMessage: "",
    };
    this.setState(registrationState);

    try {
      //get event schedule details (for seating options)
      const eventScheduleResponse = await AuthRequest.get(
        `RsvpRegistrations/byScheduleId?scheduleId=${eventScheduleID}`,
      );

      const eventSchedule = eventScheduleResponse.data.payload;
      if (
        eventSchedule.registrationStatus === RsvpRegistrationStatuses.Registered
      ) {
        registrationState.errorMessage =
          "You have already registered for this event";
      } else if (
        eventSchedule.registrationStatus ===
        RsvpRegistrationStatuses.PendingPayment
      ) {
        registrationState.errorMessage =
          "It looks like you've already tried registering for this event using SMS. Your registration is pending for payment. To complete your registration, text MYEVENTS";
      } else if (
        eventSchedule.registrationStatus === RsvpRegistrationStatuses.Waitlisted
      ) {
        registrationState.errorMessage =
          "You are currently waitlisted for this event";
      } else {
        registrationState.eventSchedule = eventSchedule;
      }
    } catch (err) {
      registrationState.errorMessage = handleErrorMessage(err);
    }

    if (registrationState.errorMessage) {
      registrationState.loading = false;
      this.setState(registrationState);
      return;
    }

    const { completedEventRegistration } = this.props;
    registrationState.registration = {
      eventScheduleID,
      notes: waitlistRegistration ? null : completedEventRegistration.notes, // no registration notes or questionResponses when joining the waitlist
      registrationQuestionResponses: waitlistRegistration
        ? []
        : completedEventRegistration.registrationQuestionResponses, // copy registrationQuestionResponses from previous registration to ensure that mandatory responses are provided
      source: "Portal",
      status: waitlistRegistration
        ? RsvpRegistrationStatuses.Waitlisted
        : RsvpRegistrationStatuses.Registered,
      personId: completedEventRegistration.personID,
    };

    this.setState(registrationState, () => {
      const {
        attendeesByTable,
        registrationQuestions,
        registrationFeeAmount,
        askForDonation,
      } = registrationState.eventSchedule;

      const canAutoRegister =
        (waitlistRegistration && !registrationFeeAmount) ||
        (!(
          attendeesByTable &&
          attendeesByTable.some((t) => !t.capacity || t.seatsRemaining)
        ) &&
          (!registrationQuestions || registrationQuestions.length === 0) &&
          !registrationFeeAmount &&
          !askForDonation);

      if (canAutoRegister) {
        this.submitRegistration();
      } else {
        Navigation.go(eventPageUrl);
      }
    });
  };

  submitRegistration = async () => {
    const registrationState = { ...this.state };

    try {
      const { registration } = registrationState;
      await AuthRequest.post("RsvpRegistrations", registration);

      registrationState.successMessage =
        registration.status === RsvpRegistrationStatuses.Waitlisted
          ? "Event waitlist has been joined"
          : "Successfully Registered!";
    } catch (err) {
      registrationState.errorMessage = handleErrorMessage(err);
      registrationState.registration = {};
    }

    registrationState.loading = false;
    this.setState(registrationState);
  };

  render() {
    const { errorMessage, eventSchedule, loading, successMessage } = this.state;

    // pulling isfullyBooked initially from props (list eventSchedule info), but from eventSchedule details once retrieved (upon registration submission) in order to ensure that it's as current as possible in case space becomes avail and waitlist is no longer open
    const isFullyBooked = eventSchedule.hasOwnProperty("isFullyBooked")
      ? eventSchedule.isFullyBooked
      : this.props.isFullyBooked;

    return !!eventSchedule.registrationFeeAmount ? null : (
      <>
        <div className="relative mb-4">
          <button
            className="btn btn-accent btn-medium"
            disabled={loading || successMessage}
            onClick={() => this.onRegister(isFullyBooked)}
            style={isFullyBooked ? { width: 160, fontSize: 12 } : { width: 80 }}
          >
            {isFullyBooked
              ? loading
                ? "Joining the Waitlist..."
                : "Join the Waitlist"
              : `RSVP${loading ? "ing..." : ""}`}
          </button>

          {(errorMessage || successMessage) && (
            <FadeOutMessage
              className={`mt-4 block center ${
                successMessage ? "success-message" : "error-message"
              }`}
              message={errorMessage || successMessage}
              onTimeout={
                errorMessage ? () => this.setState({ errorMessage: "" }) : null
              }
            />
          )}

          {/* TODO: temporarily removing google calendar integration, pending oauth scope access */}
          {/* {successMessage && !isFullyBooked && (
                <AddToGoogleCalendar
                  className="mt-32"
                  eventSchedule={eventSchedule}
                />
              )} */}
        </div>
      </>
    );
  }
}
