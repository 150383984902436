import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Layout from "./Layout";
import { AuthActions } from "../state";

export default connect(
  function mapState(state) {
    return {
      ui: state.ui,
      user: state.auth.person,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          doSignout: AuthActions.doSignout,
        },
        dispatch,
      ),
    };
  },
)(Layout);
