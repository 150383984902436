import React from "react";

export default class InfiniteScrollContainer extends React.Component {
  state = {
    showScrollToTopButton: false,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.onWindowScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onWindowScroll);
  }

  onWindowScroll = () => {
    const { onScrollEnd, scrollEndOffset, showScrollToTop } = this.props;

    const scrollPosition =
      document.documentElement.scrollHeight -
      document.documentElement.scrollTop -
      document.documentElement.clientHeight;
    const hasReachedScrollEnd = scrollPosition <= (scrollEndOffset || 0);

    if (hasReachedScrollEnd && onScrollEnd) {
      onScrollEnd();
    }

    if (showScrollToTop) {
      const { showScrollToTopButton } = this.state;
      const hasScroll = scrollPosition < document.documentElement.clientHeight;
      if (hasScroll !== showScrollToTopButton) {
        this.setState({ showScrollToTopButton: hasScroll });
      }
    }
  };

  scrollToTop = () => window.scrollTo({ behavior: "smooth", left: 0, top: 0 });

  render() {
    const { children, className } = this.props;
    const { showScrollToTopButton } = this.state;

    return (
      <div className={className || ""}>
        {children}
        {showScrollToTopButton && (
          <button className="btn btn-accent btn-top" onClick={this.scrollToTop}>
            <i className="material-icons">arrow_upward</i>
          </button>
        )}
      </div>
    );
  }
}
