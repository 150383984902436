import { AuthRequest } from "../../lib";
import { SysActions } from "../states";

export const TripActions = {
  FIND_TRIP_COMPLETE: "FIND_TRIP_COMPLETE",
  FIND_TRIP_ERROR: "FIND_TRIP_ERROR",
  SET_FIND_TRIP_LOADING: "SET_FIND_TRIP_LOADING",

  GET_TRIP_COMPLETE: "GET_TRIP_COMPLETE",
  GET_TRIP_ERROR: "GET_TRIP_ERROR",
  SET_GET_TRIP_LOADING: "SET_GET_TRIP_LOADING",

  // GET_TRIP_REGISTRATION_COMPLETE: 'GET_TRIP_REGISTRATION_COMPLETE',
  // GET_TRIP_REGISTRATION_ERROR: 'GET_TRIP_REGISTRATION_ERROR',
  // SET_GET_TRIP_REGISTRATION_LOADING: 'SET_GET_TRIP_REGISTRATION_LOADING',

  TRIP_REGISTRATION_COMPLETE: "TRIP_REGISTRATION_COMPLETE",
  TRIP_REGISTRATION_ERROR: "TRIP_REGISTRATION_ERROR",
  SET_TRIP_REGISTRATION_LOADING: "SET_TRIP_REGISTRATION_LOADING",

  findMyTrip(tripEventType) {
    return async (dispatch) => {
      dispatch(TripActions.setFindTripLoading(true));

      let error, success, response;
      try {
        response = await AuthRequest.get(
          "TripEnrollments/Student/CurrentSchedule",
          { params: { tripEventType } }
        );
        success = true;
      } catch (err) {
        error = err;
      }

      if (!success || !response) {
        dispatch(TripActions.findTripError(error));
        return undefined;
      }

      dispatch(TripActions.findTripComplete(response.data.payload));

      return response.data;
    };
  },
  setFindTripLoading(payload) {
    return {
      type: TripActions.SET_FIND_TRIP_LOADING,
      payload,
    };
  },
  findTripComplete(payload) {
    return {
      type: TripActions.FIND_TRIP_COMPLETE,
      payload,
    };
  },
  findTripError(payload) {
    return {
      type: TripActions.FIND_TRIP_ERROR,
      payload,
    };
  },

  getTripByStudent(scheduleId, eventType, shliachView) {
    return async (dispatch) => {
      dispatch(TripActions.setGetTripLoading(true));

      let error, success, tripEventResponse, tripEnrollmentResponse, tripEventType;
      try {
        tripEventResponse = await AuthRequest.get(`TripEvents/${scheduleId}`);
        const tripEventId = tripEventResponse.data.payload.id;
        tripEventType = tripEventResponse.data.payload.type;

        let tripEnrollmentReqUrl = `TripEnrollments/Student?tripEventId=${tripEventId}`;
        if (shliachView) {
          tripEnrollmentReqUrl += `&shliachView=true`;
        }
        tripEnrollmentResponse = await AuthRequest.get(tripEnrollmentReqUrl);
        success = true;
      } catch (err) {
        error = err;
      }

      if (success && tripEventType && tripEventType.toLowerCase() !== eventType.toLowerCase()) {
        success = false;
        error = { response: { data: { messages: [ "Please confirm that you've entered a valid link address." ] } } };
      }

      if (!success || !tripEventResponse || !tripEnrollmentResponse) {
        dispatch(TripActions.getTripError(error));
        return undefined;
      }

      dispatch(
        TripActions.getTripComplete({
          ...tripEnrollmentResponse.data.payload,
          event: tripEventResponse.data.payload,
        }),
      );

      return tripEnrollmentResponse.data;
    };
  },
  setGetTripLoading(payload) {
    return {
      type: TripActions.SET_GET_TRIP_LOADING,
      payload,
    };
  },
  getTripComplete(payload) {
    return {
      type: TripActions.GET_TRIP_COMPLETE,
      payload,
    };
  },
  getTripError(payload) {
    return {
      type: TripActions.GET_TRIP_ERROR,
      payload,
    };
  },

  // getTripRegistration() {
  //   return async dispatch => {
  //     dispatch(TripActions.setGetTripRegistrationLoading(true));

  //     let error, response, success;
  //     try {
  //       response = await AuthRequest.get("TripRegistrations");
  //       success = true;
  //     } catch (err) {
  //       error = err;
  //     }
  //
  //     if (!response || !success) {
  //       dispatch(TripActions.getTripRegistrationError(error));
  //       return undefined;
  //     }

  //     dispatch(TripActions.getTripRegistrationComplete(response.data.payload));

  //     return response.data;
  //   };
  // },
  // setGetTripRegistrationLoading(payload) {
  //   return {
  //     type: TripActions.SET_GET_TRIP_REGISTRATION_LOADING,
  //     payload
  //   };
  // },
  // getTripRegistrationComplete(payload) {
  //   return {
  //     type: TripActions.GET_TRIP_REGISTRATION_COMPLETE,
  //     payload
  //   };
  // },
  // getTripRegistrationError(payload) {
  //   return {
  //     type: TripActions.GET_TRIP_REGISTRATION_ERROR,
  //     payload
  //   };
  // },

  doTripRegistration(registration) {
    return async (dispatch) => {
      dispatch(TripActions.setTripRegistrationLoading(true));

      // upload proof of vacc docs
      if (registration.vaccinationProofsForUpload?.length) {
        registration.vaccinationProofUrls = await Promise.all(
          registration.vaccinationProofsForUpload
            .map((proof) => dispatch(SysActions.uploadFile(proof, "vaccination", true))));
        delete registration.vaccinationProofsForUpload;
      }

      // upload identification doc
      if (registration.travelApplication?.officialIdDocForUpload) { 
        registration.travelApplication.officialIdDocumentUrl =
          await dispatch(SysActions.uploadFile(registration.travelApplication.officialIdDocForUpload, "identification", true));
        delete registration.travelApplication.officialIdDocForUpload;
      }

      let error, response, success;
      try {
        response = await AuthRequest.post("TripRegistrations", registration);
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(TripActions.tripRegistrationError(error));
        return undefined;
      }

      dispatch(
        TripActions.tripRegistrationComplete(response.data.payload),
      );

      return response.data;
    };
  },
  setTripRegistrationLoading(payload) {
    return {
      type: TripActions.SET_TRIP_REGISTRATION_LOADING,
      payload,
    };
  },
  tripRegistrationComplete(payload) {
    return {
      type: TripActions.TRIP_REGISTRATION_COMPLETE,
      payload,
    };
  },
  tripRegistrationError(payload) {
    return {
      type: TripActions.TRIP_REGISTRATION_ERROR,
      payload,
    };
  },
};