import React, { useCallback, useMemo, useState } from "react";
import Checkbox from "../../../../components/form/Checkbox";
import {
  formatCurrency,
  Navigation,
  PageLink,
  pluralizeText,
} from "../../../../lib";
import Pages from "../../..";
import moment from "moment";
import {
  getPromoDiscountInCredits,
  isTripRedeemedViaRewardCredits,
} from "../RegistrationFormHelpers";
import { TripTypes } from "../../TripConsts";

function OverviewTravel(props) {
  const {
    onChange,
    profile: {
      campus: { name: campusName } = {},
      cell,
      email,
      father,
      mother,
      person: { dob } = {},
    },
    registration: { trackID },
    submitAttempted,
    trip,
    ui: { isPhone },
  } = props;

  const {
    availableRewardInfo,
    event: { programURL, tripEndDate, tripStartDate },
    programScheduleName,
    tracks,
    validPromoCodes,
  } = trip;

  const [didConfirmProfileInfo, setDidConfirmProfileInfo] = useState(false);
  const [didConfirmTripInfo, setDidConfirmTripInfo] = useState(false);
  const [profileInfoCollapsed, setProfileInfoCollapsed] = useState(false);
  const [tripInfoCollapsed, setTripInfoCollapsed] = useState(false);
  const [showInstructionsNote, setShowInstructionsNote] = useState(true);

  const showConfirmTripInfoError = submitAttempted && !didConfirmTripInfo;
  const showConfirmProfileInfoError = submitAttempted && !didConfirmProfileInfo;

  const allowToggleTripInfoCollapse = isPhone && didConfirmTripInfo;
  const allowToggleProfileInfoCollapse = isPhone && didConfirmProfileInfo;

  const tripTrack = tracks.find((t) => t.id === trackID);

  const isRedeemedViaCredits = isTripRedeemedViaRewardCredits(trip);

  const tripPriceInCredits = useMemo(() => {
    if (!isRedeemedViaCredits || !tripTrack) return null;

    const trackPrice =
      (tripTrack.isBeforeEarlyBirdDeadline && tripTrack.earlyBirdPrice) ||
      tripTrack.regularPrice;

    return trackPrice / availableRewardInfo.creditValue;
  }, [availableRewardInfo, isRedeemedViaCredits, tripTrack]);

  const promotionalTripPriceInCredits = useMemo(() => {
    if (!isRedeemedViaCredits || !tripPriceInCredits) return null;

    const promoCreditsDiscount = getPromoDiscountInCredits(
      validPromoCodes,
      availableRewardInfo.creditValue,
    );

    return promoCreditsDiscount
      ? tripPriceInCredits - promoCreditsDiscount
      : null;
  }, [
    availableRewardInfo,
    isRedeemedViaCredits,
    tripPriceInCredits,
    validPromoCodes,
  ]);

  const dateLimitRules = availableRewardInfo?.creditsRestrictedFromDate
    ? ` This trip only accepts credits earned since ${moment(
        availableRewardInfo.creditsRestrictedFromDate,
      ).format("M/D/YYYY")}.`
    : "";

  const toggleConfirmInfo = useCallback(
    (name, value) => {
      let allConfirmed = false;

      const setCollapsed = isPhone && value;

      if (name === "didConfirmProfileInfo") {
        setDidConfirmProfileInfo(value);
        if (setCollapsed) setProfileInfoCollapsed(true);
        allConfirmed = value && didConfirmTripInfo;
      } else if (name === "didConfirmTripInfo") {
        setDidConfirmTripInfo(value);
        if (setCollapsed) setTripInfoCollapsed(true);
        allConfirmed = value && didConfirmProfileInfo;
      }

      onChange("didConfirmInfo", allConfirmed);
    },
    [didConfirmProfileInfo, didConfirmTripInfo, isPhone, onChange],
  );

  const ProfileLink = (
    <PageLink
      className="profile-link"
      to={Pages.profile.studentProfile}
      state={{
        previousLocationPageType: Navigation.page.type,
        previousLocationPath: Navigation.location.pathname,
      }}
    >
      <i className="material-icons xl-text mr-8">edit</i>Update in profile
    </PageLink>
  );

  return (
    <>
      {showInstructionsNote && (
        <div className="travel-note">
          <div className="line-height-double">
            <i className="material-icons mr-8">info_outline</i>
            Before you begin this application, please have your required travel
            ID and payment information{" "}
            {trip.event.type === TripTypes.JewishU
              ? "(for refundable deposit)"
              : ""}{" "}
            handy.
          </div>
          <i
            className="material-icons link-text-secondary ml-16"
            onClick={() => setShowInstructionsNote(false)}
          >
            close
          </i>
        </div>
      )}
      <div
        className={`trip-overview-card${
          didConfirmTripInfo
            ? " confirmed"
            : showConfirmTripInfoError
            ? " error"
            : ""
        }`}
      >
        <div
          className={`confirmation-title${
            tripInfoCollapsed ? " collapsed" : ""
          }`}
          onClick={
            allowToggleTripInfoCollapse
              ? () => setTripInfoCollapsed(!tripInfoCollapsed)
              : undefined
          }
        >
          <p className="flex flex-align-center">
            <i className="material-icons mr-8">check_circle_outline</i> Confirm
            trip
          </p>
          {allowToggleTripInfoCollapse && (
            <i className="material-icons xl-text ml-16">
              {tripInfoCollapsed ? "keyboard_arrow_down" : "keyboard_arrow_up"}
            </i>
          )}
        </div>
        {!tripInfoCollapsed && (
          <>
            <p className="apply-title">
              Apply for {programScheduleName || "trip"}
            </p>
            <p className="trip-dates">
              <i className="material-icons">date_range</i>
              {moment(tripStartDate).format("MMMM D, YYYY")} -{" "}
              {moment(tripEndDate).format("MMMM D, YYYY")}
            </p>
            {tripTrack ? (
              <>
                {tripTrack.trackDescription && (
                  <p className="description mt-8">
                    {tripTrack.trackDescription}
                  </p>
                )}
                <div className="trip-fees">
                  <div>
                    <p className="fw-700 ">Trip fee</p>
                    <div className="flex accent-text-dark mobile-block">
                      {tripTrack.earlyBirdPrice &&
                      tripTrack.isBeforeEarlyBirdDeadline ? (
                        <div className="flex">
                          <p className="strike-through">
                            {formatCurrency(tripTrack.regularPrice, "$")}
                          </p>
                          <p
                            className={`error-text ml-8${
                              isRedeemedViaCredits ? " strike-through" : ""
                            }`}
                          >
                            {formatCurrency(tripTrack.earlyBirdPrice, "$")}{" "}
                            Early bird rate
                          </p>
                        </div>
                      ) : (
                        <p
                          className={
                            isRedeemedViaCredits ? "strike-through" : ""
                          }
                        >
                          {formatCurrency(tripTrack.regularPrice, "$")}
                        </p>
                      )}
                      {isRedeemedViaCredits && (
                        <div className="error-text line-height-large ml-8 mobile-ml-0 mobile-mt-8">
                          <div className="fw-700">
                            {tripPriceInCredits}{" "}
                            {pluralizeText(
                              "JewishU Credit",
                              tripPriceInCredits,
                            )}
                            {!!promotionalTripPriceInCredits && (
                              <>
                                <br />
                                <div className="mt-4">
                                  or {promotionalTripPriceInCredits}{" "}
                                  {pluralizeText(
                                    "Credit",
                                    promotionalTripPriceInCredits,
                                  )}{" "}
                                  for first-time JewishU trip goers with code{" "}
                                  {validPromoCodes[0].code}
                                </div>
                              </>
                            )}
                          </div>
                          <div className="mt-8">
                            You have {availableRewardInfo.numOfCreditsAvailable}{" "}
                            {pluralizeText(
                              "credit",
                              availableRewardInfo.numOfCreditsAvailable,
                            )}{" "}
                            available
                            {availableRewardInfo.numOfCreditsAvailable <
                            (promotionalTripPriceInCredits ||
                              tripPriceInCredits) ? (
                              <>
                                {" "}
                                -{" "}
                                <span className="fw-700">
                                  insufficient credits
                                </span>{" "}
                                for trip registration
                              </>
                            ) : (
                              ""
                            )}
                            .{dateLimitRules}
                          </div>
                        </div>
                      )}
                    </div>
                    {tripTrack.depositAmount > 0 && (
                      <>
                        <p className="fw-700">Deposit*</p>
                        <p className="accent-text-dark">
                          {formatCurrency(tripTrack.depositAmount, "$")}
                        </p>
                      </>
                    )}
                  </div>
                  {tripTrack.depositAmount > 0 && (
                    <p className="deposit-note">
                      *The deposit will be reimbursed following the completion
                      of the trip, provided adherence to the trip guidelines and
                      the submission of the post-trip survey.
                    </p>
                  )}
                </div>
                <div className="confirmation-container">
                  <Checkbox
                    checked={didConfirmTripInfo}
                    className="line-height-large mb-8"
                    error={showConfirmTripInfoError}
                    label="I have reviewed the trip dates and details and confirm that I want to apply for this trip."
                    name="didConfirmTripInfo"
                    onChange={toggleConfirmInfo}
                  />
                </div>
              </>
            ) : (
              <>
                <p className="error-text mt-24 mb-24 line-height-double">
                  You are not eligible for {programScheduleName || "this trip"},
                  likely due to age restrictions. Please review your profile
                  info below to confirm that you have the correct date of birth
                  listed.
                </p>
              </>
            )}
            {programURL && (
              <p
                className={`accent-text-dark line-height-large ${
                  tripTrack ? " ml-24" : ""
                }`}
              >
                Looking for another trip or different dates? Browse trips{" "}
                <a href={programURL} className="link-text">
                  here
                </a>
                .
              </p>
            )}
          </>
        )}
      </div>
      <div
        className={`trip-overview-card${
          didConfirmProfileInfo
            ? " confirmed"
            : showConfirmProfileInfoError
            ? " error"
            : ""
        }`}
      >
        <div
          className={`confirmation-title${
            profileInfoCollapsed ? " collapsed" : ""
          }`}
          onClick={
            allowToggleProfileInfoCollapse
              ? () => setProfileInfoCollapsed(!profileInfoCollapsed)
              : undefined
          }
        >
          <p className="flex flex-align-center">
            <i className="material-icons mr-8">check_circle_outline</i> Confirm
            your information
          </p>
          {!isPhone
            ? ProfileLink
            : allowToggleProfileInfoCollapse && (
                <i className="material-icons xl-text ml-16">
                  {profileInfoCollapsed
                    ? "keyboard_arrow_down"
                    : "keyboard_arrow_up"}
                </i>
              )}
        </div>
        {!profileInfoCollapsed && (
          <>
            <p className="description mb-12">
              Let’s make sure we got your information right. Here are some
              pertinent details for the trip that we pulled from your profile.
              If any of the information below is incorrect please update it in
              your profile.
            </p>
            {isPhone && ProfileLink}
            <div className="profile-info-grid">
              <div className="two-column-grid">
                <p className="fw-700">Campus</p>
                <p className="accent-text-dark">{campusName}</p>{" "}
                <p className="fw-700">Date of birth</p>
                <p className="accent-text-dark">{dob}</p>{" "}
                <p className="fw-700">Cell phone number</p>
                <p className="accent-text-dark">{cell}</p>{" "}
                <p className="fw-700">Email address</p>
                <p className="accent-text-dark">{email}</p>
              </div>
              <div className="contact-info-grid">
                {father && (
                  <>
                    <p className="fw-700 mb-16">Father's contact information</p>
                    <p className="accent-text-dark mb-16">
                      <span className="mr-16">{father.name}</span>{" "}
                      <span className="mr-16">{father.phoneNumber}</span>{" "}
                      <span>{father.email}</span>
                    </p>
                  </>
                )}
                {mother && (
                  <>
                    <p className="fw-700 mb-16">Mother's contact information</p>
                    <p className="accent-text-dark">
                      <span className="mr-16">{mother.name}</span>{" "}
                      <span className="mr-16">{mother.phoneNumber}</span>{" "}
                      <span>{mother.email}</span>
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className="confirmation-container">
              <Checkbox
                checked={didConfirmProfileInfo}
                className="line-height-large"
                error={showConfirmProfileInfoError}
                label={`I confirm that the above information is correct for my ${
                  programScheduleName || "trip"
                } application.`}
                name="didConfirmProfileInfo"
                onChange={toggleConfirmInfo}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default React.memo(OverviewTravel);
