import React, { useCallback, useState } from "react";
import Checkbox from "../../../../components/form/Checkbox";
import FileUpload from "../../../../components/FileUpload";
import Toggle from "../../../../components/form/Toggle";
import { hasTrueFalseValue } from "../../../../lib";
import { TripTermTypes } from "../../TripConsts";

function Terms(props) {
  const {
    profile: {
      didConsentToMosaicMarketingContact:
        initialDidConsentToMosaicMarketingContact,
    },
    onChange,
    onChangeVaccinationProof,
    registration: {
      didAcceptTermsAndConditions,
      profileDetails: { didConsentToMosaicMarketingContact },
      vaccinationProofsForUpload,
    },
    submitAttempted,
    trip: {
      termsAndConditions,
      event: {
        isMarketingConsentRequired,
        isProofOfVaccinationRequired,
        isTravelTrip,
      },
    },
    ui: { isPhone },
  } = props;

  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState(
    [],
  );

  const showTermsRequiredError =
    submitAttempted && !didAcceptTermsAndConditions;
  const showProofOfVaccRequiredError =
    submitAttempted &&
    isProofOfVaccinationRequired &&
    !vaccinationProofsForUpload?.length;

  const groupShortTextTerms =
    isTravelTrip &&
    termsAndConditions.some((t) => t.type === TripTermTypes.ShortText);

  const showLegalMarketingConsent =
    isMarketingConsentRequired &&
    !hasTrueFalseValue(initialDidConsentToMosaicMarketingContact);

  const termClassName = isTravelTrip ? "travel-term" : "term";

  const onToggleTerm = useCallback(
    (termId, accepted) => {
      const updatedAcceptedTermsAndConditions = accepted
        ? [...acceptedTermsAndConditions, termId]
        : acceptedTermsAndConditions.filter((id) => id !== termId);

      setAcceptedTermsAndConditions(updatedAcceptedTermsAndConditions);

      onChange(
        "didAcceptTermsAndConditions",
        updatedAcceptedTermsAndConditions.length === termsAndConditions.length,
      );
    },
    [acceptedTermsAndConditions, onChange, termsAndConditions.length],
  );

  return (
    <>
      <div className="flex flex-align-center">
        <p className="section-title">Terms</p>
        {showTermsRequiredError && (
          <p className="error-text mb-16 ml-8">
            * Please accept all terms & conditions
          </p>
        )}
      </div>
      <p className="accent-text-dark line-height-double mb-16">
        {isTravelTrip
          ? "Chabad on Campus International reserves the right to determine final trip eligibility."
          : "Please read through the terms & conditions and confirm that you agree."}
      </p>

      {groupShortTextTerms && (
        <p className="accent-text-dark mb-20">I agree to the following</p>
      )}
      {termsAndConditions &&
        (groupShortTextTerms
          ? [...termsAndConditions].sort((term) =>
              term.type === TripTermTypes.ShortText ? -1 : 1,
            )
          : termsAndConditions
        ).map((term) => {
          const { id, text, title, type } = term;
          const accepted = acceptedTermsAndConditions.includes(id);
          return (
            <div key={id} className={termClassName}>
              {type === TripTermTypes.LongText && (
                <div>
                  <p className="fw-700 mb-16">{title}</p>
                  {text && <p className="long-term-text mb-16">{text}</p>}
                </div>
              )}

              <Checkbox
                checked={accepted}
                className="line-height-large"
                error={submitAttempted && !accepted}
                label={
                  type === TripTermTypes.LongText
                    ? "I have read, understand and agree with the terms and conditions above."
                    : text
                }
                labelStyle={{ whiteSpace: "pre-wrap" }}
                name={`term-${id}`}
                onChange={(_, value) => onToggleTerm(id, value)}
              />
            </div>
          );
        })}

      {showLegalMarketingConsent && (
        <div className={termClassName}>
          <p className="accent-text-dark line-height-large mb-8">
            I would like to receive information about Jewish post-graduate
            opportunities offered by Chabad on Campus funding partners.
          </p>
          <Toggle
            error={
              submitAttempted &&
              !hasTrueFalseValue(didConsentToMosaicMarketingContact)
            }
            name="profileDetails.didConsentToMosaicMarketingContact"
            onChange={onChange}
            options={[
              {
                value: true,
                display: "Yes",
              },
              {
                value: false,
                display: "No",
              },
            ]}
            value={didConsentToMosaicMarketingContact}
          />
        </div>
      )}

      {isProofOfVaccinationRequired && (
        <div className={termClassName}>
          <div>
            <p
              className={`fw-700 mb-8 ${
                showProofOfVaccRequiredError ? "error-text" : ""
              }`}
            >
              Proof of vaccination
            </p>
            {showProofOfVaccRequiredError ? (
              <p className="error-text line-height-double">
                * Please upload at least one proof of vaccination.
              </p>
            ) : (
              <p className="accent-text-dark line-height-double">
                Please upload proof of vaccination
              </p>
            )}
          </div>
          {vaccinationProofsForUpload?.map((proof, vaxIndex) => (
            <FileUpload
              key={vaxIndex}
              index={vaxIndex}
              isPhone={isPhone}
              description="Proof of Vaccination"
              documentURL={proof.previewURL || ""}
              submitDocument={onChangeVaccinationProof}
            />
          ))}
          <FileUpload
            index={vaccinationProofsForUpload?.length || 0}
            isPhone={isPhone}
            description="Proof of Vaccination"
            documentURL=""
            submitDocument={onChangeVaccinationProof}
          />
        </div>
      )}
    </>
  );
}

export default React.memo(Terms);
