import { AuthRequest } from "../../lib";
import querySerializer from "query-string";

export const ProfileActions = {
  SET_GET_PROFILE_LOADING: "SET_GET_PROFILE_LOADING",
  GET_PROFILE_COMPLETE: "GET_PROFILE_COMPLETE",
  GET_PROFILE_ERROR: "GET_PROFILE_ERROR",

  SET_GET_PROFILE_SETTINGS_LOADING: "SET_GET_PROFILE_SETTINGS_LOADING",
  GET_PROFILE_SETTINGS_COMPLETE: "GET_PROFILE_SETTINGS_COMPLETE",
  GET_PROFILE_SETTINGS_ERROR: "GET_PROFILE_SETTINGS_ERROR",

  SET_SUBMIT_PROFILE_LOADING: "SET_SUBMIT_PROFILE_LOADING",
  SUBMIT_PROFILE_ERROR: "SUBMIT_PROFILE_ERROR",
  SUBMIT_PROFILE_COMPLETE: "SUBMIT_PROFILE_COMPLETE",

  UPDATE_PROFILE: "UPDATE_PROFILE",

  getProfile(studentId) {
    return async (dispatch) => {
      dispatch(ProfileActions.setGetProfileLoading(true));

      let error, response, success;
      try {
        response = await AuthRequest.get(`Students/${studentId}`);
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(ProfileActions.getProfileError(error));
        return undefined;
      }

      dispatch(ProfileActions.getProfileComplete(response.data));

      return response.data;
    };
  },
  setGetProfileLoading(payload) {
    return {
      type: ProfileActions.SET_GET_PROFILE_LOADING,
      payload,
    };
  },
  getProfileComplete(payload) {
    return {
      type: ProfileActions.GET_PROFILE_COMPLETE,
      payload,
    };
  },
  getProfileError(payload) {
    return {
      type: ProfileActions.GET_PROFILE_ERROR,
      payload,
    };
  },
  getProfileSettings(campusId, chabadHouseId, programId) {
    return async (dispatch) => {
      dispatch(ProfileActions.setGetProfileSettingsLoading(true));

      let error, response, success;
      try {
        const params = {
          campusId,
          chabadHouseId,
          programId,
        };

        response = await AuthRequest.get(
          `System/StudentProfileSettings?${querySerializer.stringify(params)}`,
        );
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(ProfileActions.getProfileSettingsError(error));
        return undefined;
      }

      dispatch(
        ProfileActions.getProfileSettingsComplete(response.data.payload),
      );

      return response.data.payload;
    };
  },
  setGetProfileSettingsLoading(payload) {
    return {
      type: ProfileActions.SET_GET_PROFILE_SETTINGS_LOADING,
      payload,
    };
  },
  getProfileSettingsComplete(payload) {
    return {
      type: ProfileActions.GET_PROFILE_SETTINGS_COMPLETE,
      payload,
    };
  },
  getProfileSettingsError(payload) {
    return {
      type: ProfileActions.GET_PROFILE_SETTINGS_ERROR,
      payload,
    };
  },
  submitProfile(profile) {
    return async (dispatch) => {
      dispatch(ProfileActions.setSubmitProfileLoading(true));

      let error, response, success;
      try {
        response = await AuthRequest.post("Students", {
          ...profile,
        });
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(ProfileActions.submitProfileError(error));
        return undefined;
      }

      dispatch(ProfileActions.submitProfileComplete());
      dispatch(ProfileActions.updateProfile(response.data));

      return response.data;
    };
  },
  setSubmitProfileLoading(payload) {
    return {
      type: ProfileActions.SET_SUBMIT_PROFILE_LOADING,
      payload,
    };
  },
  submitProfileComplete(payload) {
    return {
      type: ProfileActions.SUBMIT_PROFILE_COMPLETE,
      payload,
    };
  },
  submitProfileError(payload) {
    return {
      type: ProfileActions.SUBMIT_PROFILE_ERROR,
      payload,
    };
  },
  updateProfile(payload) {
    return {
      type: ProfileActions.UPDATE_PROFILE,
      payload,
    };
  },
};
