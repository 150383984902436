import React from "react";

export default () => (
  <div className="your-israel-page-form-state">
    <img src="/images/registration_complete.svg" alt="" height="240" />
    <p className="fw-700 mt-24" style={{ fontSize: "34px" }}>
      Thank you!
    </p>
    <p
      className="mt-16 large-text line-height-double text-center"
      style={{ maxWidth: "600px" }}
    >
      Your application is being reviewed. You will receive an email with further
      details.
    </p>
  </div>
);
