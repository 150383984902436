import React from "react";
import { handleErrorMessage } from "../../../../lib";

export default ({ error }) => (
  <div className="trip-page-form-state">
    <img src="/images/error.svg" alt="error-bot" height="240" />
    <p className="xxl-text mt-24 mb-16">Something went wrong</p>
    <p className="medium-text accent-text fw-500">
      {error && error.status === 404
        ? "Sorry, the event that you are attempting to register for cannot be found."
        : handleErrorMessage(
            error,
            "Sorry for the inconvenience. Our server is being stubborn, please try again.",
          )}
    </p>
  </div>
);
