import React, { useState, useEffect, useCallback } from "react";
import { Navigation, PageURL, getVersionedImgSrc } from "../../lib";
import StripeProvider from "../../components/StripeProvider";
import { ApiCallErrorMessageHandler } from "../../ApiClientErrorHandler";
import StandaloneRegistrationPayment from "./components/eventRegistration/registrationStates/StandaloneRegistrationPayment.jsx";
import ResourceRegistrationCompleted from "./components/eventRegistration/registrationStates/ResourceRegistrationCompleted";

const onCompletePayment = (pathname) => {
  Navigation.redirect(PageURL.to(pathname), { paymentCompleted: true });
  window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
};

export default function RsvpResourceRegistrationPaymentContainer(props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    pageRoute: {
      query: { registrationId, accountId, token },
      location: { pathname, state: pageLocationState },
    },
    actions: { getRsvpResourceRegistrationPaymentDetails },
    resourceRegistrationPaymentDetails: {
      data: registrationPaymentDetails,
      error: registrationPaymentDetailsError,
    },
    ui: { isPhone },
  } = props;

  const {
    name,
    registrationFeeAmount,
    donationAmount,
    stripePublicKey,
    customConfirmationMessage,
  } = registrationPaymentDetails || {};

  useEffect(() => {
    const getPaymentDetails = async () => {
      if (!registrationPaymentDetails) {
        await getRsvpResourceRegistrationPaymentDetails({
          registrationId,
          accountId,
          token,
        });
      }
    };

    getPaymentDetails().catch((err) => {
      setErrorMessage(err);
    });

    setLoading(false);
  }, [
    registrationPaymentDetails,
    registrationId,
    accountId,
    token,
    getRsvpResourceRegistrationPaymentDetails,
  ]);

  const handleError = useCallback(
    (error) => {
      const errorMessage = ApiCallErrorMessageHandler(error);
      if (errorMessage.includes("confirmed")) {
        onCompletePayment(pathname);
      } else {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          const unauthauthorizedErrorMsg =
            "You may not have permission to access this feature, or you are using an expired link. " +
            "If you are trying to make a payment to finalize your resource sign up, text the resource code and follow the instructions to receive a new payment link.";
          setErrorMessage(unauthauthorizedErrorMsg);
        } else {
          setErrorMessage(errorMessage);
        }
      }
    },
    [pathname]
  );

  useEffect(() => {
    if (registrationPaymentDetailsError) {
      handleError(registrationPaymentDetailsError);
    }
  }, [registrationPaymentDetailsError, handleError]);

  return (
    <div>
      <header className="header rsvp-payment-header">
        <div className="rsvp-payment-section mt-8">
          <img src={getVersionedImgSrc("coci-logo.png")} alt="logo" />
        </div>
      </header>
      <div className={!isPhone ? "page-wrapper" : "page-wrapper page-wrapper-mobile"}>
        <div className="page rsvp-payment-page">
          {pageLocationState && pageLocationState.paymentCompleted ? (
            <ResourceRegistrationCompleted
              resourceName={name}
              customMessage={customConfirmationMessage}
            />
          ) : !!registrationPaymentDetailsError ? (
              <div className="text-center rsvp-payment-error">
                <img src="/images/error.svg" alt="error-bot" height="240" />
                <p className="xxl-text mt-24 mb-16">Something went wrong</p>
                <p className="medium-text accent-text fw-500" >{errorMessage}</p>
              </div>
          ) : stripePublicKey ? (
            <StripeProvider apiKey={stripePublicKey}>
              <StandaloneRegistrationPayment
                rsvpName={name}
                registrationFeeAmount={registrationFeeAmount}
                donationAmount={donationAmount}
                errorMessage={errorMessage}
                loading={loading}
                setErrorMessage={setErrorMessage}
                setLoading={setLoading}
                onCompletePayment={() => onCompletePayment(pathname)}
                handleError={handleError}
                pageRoute={props.pageRoute}
                sripe={props.stripe}
                doRsvpRegistrationPayment={
                  props.actions.doRsvpResourceRegistrationPayment
                }
                textBlanks={{
                  reservation: "reservation",
                  action: "Sign up",
                }}
                user={props.user}
              />
            </StripeProvider>
          ) : null}
        </div>
      </div>
    </div>
  );
}
