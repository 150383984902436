import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RsvpActions } from "../../state";
import RsvpResourceRegistrationPaymentContainer from "./RsvpResourceRegistrationPaymentContainer";

export default connect(
  function mapState(state) {
    return {
      resourceRegistrationPaymentDetails:
        state.rsvp.resourceRegistrationPaymentDetails,
      resourceRegistrationPayment: state.rsvp.resourceRegistrationPayment,
      ui: state.ui,
      user: state.auth.person,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...RsvpActions,
        },
        dispatch,
      ),
    };
  },
)(RsvpResourceRegistrationPaymentContainer);
