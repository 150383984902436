import React, { useCallback } from "react";
import ApplyPromoCode from "./ApplyPromoCode";
import ApplyReward from "./ApplyReward";
import { formatCurrency, pluralizeText } from "../../../../../lib/utils";

function BillingSummary(props) {
  const {
    applyPromoCodes,
    applyReward,
    autoApplyReward,
    availableRewardInfo,
    billingPromoCodes,
    billingSummary,
    billingTotal,
    discountableTotal,
    rewardRequest,
    rewardError,
    setRewardError,
    unappliedPromoCreditsDiscount,
    validPromoCodes,
  } = props;

  const handleRemovePromoCode = useCallback(
    (promoCode) => {
      applyPromoCodes(billingPromoCodes.filter((code) => code !== promoCode));
    },
    [applyPromoCodes, billingPromoCodes],
  );

  return (
    <div className="trip-form-section">
      <p className="subsection-title">Billing Summary</p>

      <ApplyPromoCode
        applyPromoCodes={applyPromoCodes}
        billingPromoCodes={billingPromoCodes}
        billingTotal={billingTotal}
        discountableTotal={discountableTotal}
        validPromoCodes={validPromoCodes}
      />

      <div className="checkout-summary">
        {billingSummary &&
          billingSummary.map((item, index) => (
            <div key={index}>
              <div className="flex flex-justify-space flex-align-center mb-8">
                <p className="fw-700">{item.title}</p>
                <div className="flex flex-align-center">
                  <p className={item.isDiscount ? "error-text" : ""}>
                    <span className={item.creditValue ? "strike-through" : ""}>
                      {item.isDiscount ? "-" : ""}
                      {item.price || item.price === 0
                        ? formatCurrency(item.price, "$")
                        : item.priceAlt || ""}
                    </span>
                    {item.creditValue && (
                      <span className="ml-8">
                        {item.isDiscount ? "-" : ""}
                        {item.creditValue}{" "}
                        {pluralizeText("Credit", item.creditValue)}
                      </span>
                    )}
                  </p>
                  {item.isReward && !autoApplyReward && (
                    <i
                      className="material-icons link-text-secondary xl-text ml-8"
                      onClick={() => applyReward(null)}
                    >
                      close
                    </i>
                  )}
                </div>
              </div>
              {item.subItems?.map((subItem, subIndex) => (
                <div
                  key={subIndex}
                  className="flex flex-justify-space flex-align-center ml-16 mb-8"
                >
                  <p>{subItem.title}</p>
                  <div className="flex flex-align-center">
                    <p className={subItem.isDiscount ? "error-text" : ""}>
                      <span
                        className={subItem.creditValue ? "strike-through" : ""}
                      >
                        {subItem.isDiscount ? "-" : ""}
                        {formatCurrency(subItem.price, "$")}
                      </span>
                      {subItem.creditValue && (
                        <span className="ml-8">
                          {subItem.isDiscount ? "-" : ""}
                          {subItem.creditValue}{" "}
                          {pluralizeText("Credit", subItem.creditValue)}
                        </span>
                      )}
                    </p>
                    {subItem.isPromo && (
                      <i
                        className="material-icons link-text-secondary xl-text ml-8"
                        onClick={() => handleRemovePromoCode(subItem.title)}
                      >
                        close
                      </i>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))}
        {availableRewardInfo && (
          <ApplyReward
            applyReward={applyReward}
            autoApply={autoApplyReward}
            availableRewardInfo={availableRewardInfo}
            discountableTotal={discountableTotal}
            rewardRequest={rewardRequest}
            setRewardError={setRewardError}
            unappliedPromoCreditsDiscount={unappliedPromoCreditsDiscount}
          />
        )}
        {!rewardError && (
          <div className="flex flex-justify-space flex-align-center medium-text mt-16">
            <p className="fw-700">Total</p>
            <p className="fw-700">{formatCurrency(billingTotal, "$")}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default React.memo(BillingSummary);
