import { YourIsraelActions } from "./actions";
import { AuthActions } from "../auth/actions";

export const YourIsraelState = {
  name: "yourIsrael",
  persist: false,
  defaults: {
    yourIsrael: {
      data: {},
      error: undefined,
      loading: false,
      success: undefined,
    },
    course: {
      data: {},
      error: undefined,
      loading: false,
      success: undefined,
    },
    enrollment: {
      data: {},
      error: undefined,
      loading: false,
      success: undefined,
    },
    enrollmentUpdate: {
      data: {},
      error: undefined,
      loading: false,
      success: undefined,
    },
    enrollmentCancellation: {
      error: undefined,
      loading: false,
      success: undefined,
    },
  },
  handlers: {
    [YourIsraelActions.SET_GET_YOUR_ISRAEL_LOADING]: function (state, action) {
      return {
        ...state,
        yourIsrael: {
          ...state.yourIsrael,
          loading: !!action.payload,
        },
      };
    },
    [YourIsraelActions.GET_YOUR_ISRAEL_COMPLETE]: function (state, action) {
      return {
        ...state,
        yourIsrael: {
          ...state.yourIsrael,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [YourIsraelActions.GET_YOUR_ISRAEL_ERROR]: function (state, action) {
      return {
        ...state,
        yourIsrael: {
          ...state.yourIsrael,
          loading: false,
          success: false,
          error: action.payload,
          data: {},
        },
      };
    },
    [YourIsraelActions.UPDATE_YOUR_ISRAEL]: function (state, action) {
      return {
        ...state,
        yourIsrael: {
          ...state.yourIsrael,
          data: action.payload,
        },
      };
    },

    [YourIsraelActions.SET_GET_COURSE_LOADING]: function (state, action) {
      return {
        ...state,
        course: {
          ...state.course,
          loading: !!action.payload,
        },
      };
    },
    [YourIsraelActions.GET_COURSE_COMPLETE]: function (state, action) {
      return {
        ...state,
        course: {
          ...state.course,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [YourIsraelActions.GET_COURSE_ERROR]: function (state, action) {
      return {
        ...state,
        course: {
          ...state.course,
          loading: false,
          success: false,
          error: action.payload,
          data: {},
        },
      };
    },
    [YourIsraelActions.RESET_COURSE]: function (state, action) {
      return {
        ...state,
        course: YourIsraelState.defaults.course,
        enrollment: YourIsraelState.defaults.enrollment,
        enrollmentCancellation: YourIsraelState.defaults.enrollmentCancellation,
      };
    },

    [YourIsraelActions.SET_COURSE_ENROLLMENT_LOADING]: function (
      state,
      action,
    ) {
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          loading: !!action.payload,
        },
      };
    },
    [YourIsraelActions.COURSE_ENROLLMENT_COMPLETE]: function (state, action) {
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [YourIsraelActions.COURSE_ENROLLMENT_ERROR]: function (state, action) {
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          loading: false,
          success: false,
          error: action.payload,
          data: {},
        },
      };
    },
    [YourIsraelActions.RESET_COURSE_ENROLLMENT]: function (state, action) {
      return {
        ...state,
        enrollment: YourIsraelState.defaults.enrollment,
      };
    },

    [YourIsraelActions.SET_COURSE_ENROLLMENT_UPDATE_LOADING]: function (
      state,
      action,
    ) {
      return {
        ...state,
        enrollmentUpdate: {
          ...state.enrollmentUpdate,
          loading: !!action.payload,
        },
      };
    },
    [YourIsraelActions.COURSE_ENROLLMENT_UPDATE_COMPLETE]: function (
      state,
      action,
    ) {
      return {
        ...state,
        enrollmentUpdate: {
          ...state.enrollmentUpdate,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [YourIsraelActions.COURSE_ENROLLMENT_UPDATE_ERROR]: function (
      state,
      action,
    ) {
      return {
        ...state,
        enrollmentUpdate: {
          ...state.enrollmentUpdate,
          loading: false,
          success: false,
          error: action.payload,
          data: {},
        },
      };
    },
    [YourIsraelActions.RESET_COURSE_ENROLLMENT_UPDATE]: function (
      state,
      action,
    ) {
      return {
        ...state,
        enrollmentUpdate: YourIsraelState.defaults.enrollment,
      };
    },

    [YourIsraelActions.SET_COURSE_ENROLLMENT_CANCELLATION_LOADING]: function (
      state,
      action,
    ) {
      return {
        ...state,
        enrollmentCancellation: {
          ...state.enrollmentCancellation,
          loading: !!action.payload,
        },
      };
    },
    [YourIsraelActions.COURSE_ENROLLMENT_CANCELLATION_COMPLETE]: function (
      state,
      action,
    ) {
      return {
        ...state,
        enrollmentCancellation: {
          ...state.enrollmentCancellation,
          loading: false,
          success: true,
          error: undefined,
        },
      };
    },
    [YourIsraelActions.COURSE_ENROLLMENT_CANCELLATION_ERROR]: function (
      state,
      action,
    ) {
      return {
        ...state,
        enrollmentCancellation: {
          ...state.enrollmentCancellation,
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    },
    [YourIsraelActions.RESET_COURSE_ENROLLMENT_CANCELLATION]: function (
      state,
      action,
    ) {
      return {
        ...state,
        enrollmentCancellation: YourIsraelState.defaults.enrollmentCancellation,
      };
    },
    [AuthActions.DO_SIGNOUT]: function (state, action) {
      return YourIsraelState.defaults;
    },
  },
};
