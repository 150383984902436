import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import querySerializer from "query-string";
import { CommonPageTypes } from "./constants";
import { Navigation } from "./Navigation";
import { PageLoader } from "./PageLoader";
import { PageURL } from "./PageURL";
import Pages from "../../pages";
import { gaTrackPageView } from "../GoogleAnalytics";

const history = Navigation.init({
  listen: (location) => gaTrackPageView(location),
}).history;
/** Page definitions by PAGE_TYPE string. Populated by `configurePageArea`
 * where each page in each area of the site are processed.
 *
 * **The global PageURL.types is altered when adding a page to this
 * collection.** This allows `PageURL` to create a URL for a page given
 * just that pages PAGE_TYPE string.
 *
 * @type {{[PAGE_TYPE:string]: PageDefinition}}
 */
const pagesByType = PageURL.types || {};
/** @type {{[urlpath_pattern:string]: PageDefinition}} */
const pagesByPath = PageURL.paths || {};
const routePages = [];

let NotFoundPageView;
let isAuthenticated = () => false;
let loginRedirectURL = `${Pages.account.login.path}?after=`;
/** @type {AppRouterPageOptions} */
const pageOptions = {
  anon: false,
  pathExact: true,
};

export class AppRouter extends React.PureComponent {
  /** Configures the `AppRouter`
   * @param {AppRouterConfig} config
   */
  static configure(config) {
    const {
      loginCheck = isAuthenticated,
      loginPath = Pages.account.login.path,
      loginRedirectParam = "after",
      pageOptions: { anon = true, pathExact = true },
      rootArea,
      configurePage,
    } = config;
    isAuthenticated = loginCheck;
    loginRedirectURL = `${loginPath}?${loginRedirectParam}=`;
    pageOptions.anon = anon;
    pageOptions.pathExact = pathExact;
    configurePageArea(rootArea, { configurePage });
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          {routePages.map(renderRouteForPage)}
          <PrivateRoute path="*" component={NotFoundPageView} />
        </Switch>
      </Router>
    );
  }
}
export default AppRouter;

function configurePageArea(area, options = {}) {
  const { areas: subAreas, pages } = area;
  if (subAreas) {
    subAreas.forEach((subArea) => {
      configurePageArea(subArea, options);
    });
  }
  if (!pages) {
    return;
  }
  const { configurePage = () => undefined } = options;
  Object.keys(pages).forEach(function mapPage(keyForPage) {
    const page = pages[keyForPage];
    let { path, type } = page;
    if (!type || pagesByType[type]) {
      throw new Error(`Missing or duplicate page type found: ${type}`);
    }
    pagesByPath[path] = page;
    pagesByType[type] = page;
    if (type === CommonPageTypes.NOT_FOUND) {
      NotFoundPageView = page.view;
    }
    if (path) {
      if (!page.getRouteKey) {
        /** MODIFYING: page is modified to ensure required props.
         * Instead of copying, we apply updates to the original page.
         * This was done so that code outside of this module which already has a
         * reference to a given page can access these modifications.
         */
        page.getRouteKey = getRouteKeyWithPage(page);
      }
      routePages.push(page);
    }
    configurePage(page);
  });
}

function renderRouteForPage(page) {
  const {
    anon = pageOptions.anon,
    path,
    pathExact: exact = pageOptions.pathExact,
  } = page;
  const RouteComponent = anon ? Route : PrivateRoute;
  const pageProps = {
    exact,
    path,
  };
  function renderPageLoader(props) {
    return <PageLoader page={page} {...props} />;
  }
  return <RouteComponent key={path} {...pageProps} render={renderPageLoader} />;
}

export class PrivateRoute extends React.PureComponent {
  render() {
    const props = this.props;
    return isAuthenticated() ? (
      <Route {...props} />
    ) : (
      <Redirect
        to={
          loginRedirectURL +
          encodeURIComponent(props.location.pathname + props.location.search)
        }
      />
    );
  }
}

// #region Route Keys - Uniquely identify pages based on parts of the URL

/** Returns a function that, when called, returns the route key of the page. */
function getRouteKeyWithPage(page) {
  const { key: keySpec } = page;
  function getRouteKeyForPage(params) {
    // NOTE: We only want the path, not a query string, so only pass params.
    return PageURL.to(page, { params });
  }
  if (keySpec) {
    return getRouteKeyWithSpec(page, keySpec, getRouteKeyForPage);
  }
  return getRouteKeyForPage;
}
/** Returns a function that, when called, returns the route key of the page. */
function getRouteKeyWithSpec(page, keySpec, defaultImpl) {
  // TODO: Use keySpec.params to create pathnames like '/prop/value/...'
  // so that we can share view state among different urls (think wizards).
  // This way the uniquness of the key can be independant of the pathname.
  const { query: querySpec } = keySpec;
  if (!querySpec || !Array.isArray(querySpec)) {
    return defaultImpl;
  }
  const len = querySpec.length;
  return function getRouteKeyIncludingQuery(params, query) {
    // NOTE: We only want the path, not a query string, so only pass params.
    let pathname = PageURL.to(page, { params });
    if (!query) {
      return pathname;
    }
    const keyValues = {};
    let hasKeyValues = false;
    for (var i = 0; i < len; i++) {
      let prop = querySpec[i];
      if (prop in query) {
        keyValues[prop] = query[prop];
        hasKeyValues = true;
      }
    }
    if (!hasKeyValues) {
      return pathname;
    }
    return pathname + "?" + querySerializer.stringify(keyValues);
  };
}
// #endregion

// #region Typedefs

/** @typedef {object} AppRouterConfig
 * @property {()=> boolean} [loginCheck] Function that returns true if the user
 * is logged in.
 * @property {string} [loginPath] Path to the login page. Default: `'/login'`.
 * @property {string} [loginRedirectParam] Query param name used by the login
 * page to redirect the user after login. Defaults to `'after'`. The router
 * will pass a relative URL via this parameter.
 * @property {AppRouterPageOptions} [pageOptions] Page configuration options.
 * @property {{areas: object[], pages: object[]}} rootArea The root area of the
 * app.
 */

/** @typedef {object} AppRouterPageOptions
 * @property {boolean} [anon] Default value for page `anon` field.
 * @property {boolean} [pathExact] Default value for page 'pathExact' field.
 */

// #endregion
