import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RsvpActions } from "../../state";
import RsvpEventRegistrationPaymentContainer from "./RsvpEventRegistrationPaymentContainer";

export default connect(
  function mapState(state) {
    return {
      eventRegistrationPaymentDetails:
        state.rsvp.eventRegistrationPaymentDetails,
      eventRegistrationPayment: state.rsvp.eventRegistrationPayment,
      ui: state.ui,
      user: state.auth.person,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...RsvpActions,
        },
        dispatch,
      ),
    };
  },
)(RsvpEventRegistrationPaymentContainer);
