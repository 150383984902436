import React from "react";
import moment from "moment";

export default ({
  yourIsraelCourseSchedule: {
    didRegistrationClose,
    name,
    registrationStartDate,
    timezoneName,
  },
}) => (
  <div className="your-israel-page-form-state">
    <img src="/images/registration_closed.svg" alt="" height="200" />
    <p className="fw-700 mt-24" style={{ fontSize: "34px" }}>
      {name || "Course"} enrollment is{" "}
      {didRegistrationClose ? "closed" : "not yet open"}
    </p>
    {!didRegistrationClose && registrationStartDate && (
      <p className="large-text fw-700 mt-16">
        Enrollment opens on{" "}
        {moment(registrationStartDate).format("MMMM D @ h:mm a")} {timezoneName}
      </p>
    )}
    <p className="mt-16 large-text">
      Questions? Please contact us at{" "}
      <a
        href="mailto:help@chabadoncampus.org"
        className="link-text"
        target="_blank"
        rel="noopener noreferrer"
      >
        help@chabadoncampus.org
      </a>{" "}
      or{" "}
      <a href="tel:+1-718-510-8181,0" className="link-text">
        718 510 8181 x 0
      </a>
    </p>
  </div>
);
