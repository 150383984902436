import React from "react";
import Registration from "./components/Registration.vm";
import TripContainer from "./TripContainer";
import { withAppInsights } from "../../lib/AppInsights";

class TripRegistration extends React.PureComponent {
  render() {
    return (
      <TripContainer {...this.props} title="Trip Registration">
        <Registration />
      </TripContainer>
    );
  }
}

export default withAppInsights(TripRegistration);
