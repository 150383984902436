import React from "react";
import Radio from "../../components/form/Radio";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from "react-stripe-elements";

export default class CardPaymentInfo extends React.PureComponent {
  state = {
    incompleteCardFields: {
      cardNumber: true,
      cardExpiry: true,
      cardCvc: true,
    },
  };

  onChangeCC = ({ elementType, complete }) => {
    const incompleteCardFields = {
      ...this.state.incompleteCardFields,
      [elementType]: !complete,
    };

    this.setState(
      {
        incompleteCardFields,
      },
      () => {
        this.props.onChange(
          "ccInfoComplete",
          !Object.keys(incompleteCardFields).filter(
            (cardField) => incompleteCardFields[cardField],
          ).length,
        );
      },
    );
  };

  toggleUseCConFile = async (name, useCard) => {
    const { onChange } = this.props;

    onChange(name, useCard);

    if (useCard) {
      this.setState(
        {
          incompleteCardFields: {
            cardNumber: true,
            cardExpiry: true,
            cardCvc: true,
          },
        },
        () => {
          onChange("ccInfoComplete", false, {
            address: {
              address1: "",
              address2: "",
              country: "",
              city: "",
              state: "",
              zip: "",
            },
            cardHolderFullName: "",
          });
        },
      );
    }
  };

  render() {
    const {
      billingInfo,
      cardOnFile,
      cardHolderFullName,
      digitalPayButton,
      isDigitalPay,
      isPhone,
      onChangeEvt,
      submitAttempted,
      useCardOnFile,
      saveCardInfo = true,
    } = this.props;

    const { incompleteCardFields } = this.state;
    const showIncompleteCardFieldsError =
      submitAttempted && !useCardOnFile && !isDigitalPay;

    return (
      <div>
        <div className="flex flex-align-center mobile-block mb-16">
          {isPhone && digitalPayButton}
          <div className="flex flex-align-center">
            <p className="fw-700 medium-text">Credit card</p>
            {saveCardInfo && (
              <span className="tooltip-container">
                <i className="material-icons large-text ml-8 accent-text-dark">
                  info
                </i>
                <span className="tooltip">
                  Your credit card information will be stored securely meeting
                  all PCI compliance standards.
                </span>
              </span>
            )}
          </div>
          {!isPhone && digitalPayButton}
        </div>
        {saveCardInfo && (
          <Radio
            className="mb-16"
            name="useCardOnFile"
            options={[
              {
                content: cardOnFile && (
                  <div className="flex flex-align-center ml-32 mt-8 mb-16">
                    <img
                      alt=""
                      src={`/images/cc-${cardOnFile.cardBrand
                        .split(" ")
                        .join("-")
                        .toLowerCase()}.png`}
                      height={20}
                      className="mr-8"
                    />
                    {cardOnFile.cardBrand} ending in {cardOnFile.lastFour}
                  </div>
                ),
                disabled: !cardOnFile,
                display: "Use card on file",
                value: true,
              },
              {
                display: "Enter new card",
                value: false,
              },
            ]}
            onChange={this.toggleUseCConFile}
            value={useCardOnFile}
          />
        )}
        {!useCardOnFile && (
          <>
            <div className="cc-info-form trip-form-section-inner mt-16">
              <div
                className={`input-container mb-16 ${
                  showIncompleteCardFieldsError &&
                  incompleteCardFields.cardNumber
                    ? "error"
                    : ""
                }`}
              >
                <label>Card Number</label>
                <CardNumberElement
                  disabled={useCardOnFile}
                  onChange={this.onChangeCC}
                  placeholder="Card Number"
                />
              </div>
              <div className="input-container mb-16">
                <label>Cardholder Full Name</label>
                <input
                  autoComplete="cc-name"
                  disabled={useCardOnFile}
                  onChange={onChangeEvt}
                  name="cardHolderFullName"
                  placeholder="Full Name"
                  type="text"
                  value={cardHolderFullName}
                />
              </div>
              <div
                className={`input-container mb-16 ${
                  showIncompleteCardFieldsError &&
                  incompleteCardFields.cardExpiry
                    ? "error"
                    : ""
                }`}
              >
                <label>Exp Date</label>
                <CardExpiryElement
                  disabled={useCardOnFile}
                  onChange={this.onChangeCC}
                />
              </div>

              <div
                className={`input-container ${
                  showIncompleteCardFieldsError && incompleteCardFields.cardCvc
                    ? "error"
                    : ""
                }`}
              >
                <label>CVC Code</label>
                <CardCVCElement
                  disabled={useCardOnFile}
                  onChange={this.onChangeCC}
                  placeholder="CVC"
                />
              </div>
            </div>

            <div>{billingInfo}</div>
          </>
        )}
      </div>
    );
  }
}
