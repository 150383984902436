import React from "react";
import Loader from "../../../components/Loader";
import EnrollmentClosed from "./enrollmentStates/EnrollmentClosed";
import EnrollmentCompleted from "./enrollmentStates/EnrollmentCompleted";
import EnrollmentDetails from "./EnrollmentDetails";
import EnrollmentError from "./enrollmentStates/EnrollmentError";
import EnrollmentForm from "./EnrollmentForm";
import EnrollmentNotAvailable from "./enrollmentStates/EnrollmentNotAvailable";

import { Navigation, PageURL } from "../../../lib";

export default class Enrollment extends React.PureComponent {
  state = {
    initialized: false,
    navigating: false,
    showEnrollmentCompleted: false,
  };

  componentDidMount() {
    this.getYourIsrael();
  }

  navigate = (page, state) => {
    this.setState({ navigating: true }, () => {
      window.scrollTo({ behavior: "instant", left: 0, top: 0 }); // reset scroll before navigating

      const url = PageURL.to(page);
      state ? Navigation.redirect(url, state) : Navigation.go(url);

      if (page === Navigation.page) {
        this.setState({ navigating: false });
      }
    });
  };

  getYourIsrael = async () => {
    const {
      actions: { getYourIsrael, getYourIsraelCourse },
      isShliachView,
    } = this.props;

    //get your israel program / student enrollment info
    await getYourIsrael(isShliachView);

    //if student is associated with an enrolled chabad house, get course info (schedules, enrollments, etc) and chabad house info
    const { data: { eduChabadHouse } = {} } = this.props.yourIsrael;
    if (eduChabadHouse) {
      await getYourIsraelCourse();
    }

    this.setState({ initialized: true });
  };

  getCourseSchedule = (yourIsraelCourse, scheduleId) => {
    if (!yourIsraelCourse || !yourIsraelCourse.courseSchedulesOffered) {
      return null;
    }

    const { courseSchedulesOffered } = yourIsraelCourse;
    //if scheduleId is specified, return that schedule
    if (scheduleId) {
      return courseSchedulesOffered.find(
        (cs) => cs.id.toString() === scheduleId,
      );
    }

    //if no schedule id is specified:
    //if student is enrolled, return the schedule that student is enrolled in (courseScheduleEnrollment will be the student's most recent course sched enrollment)
    const { courseScheduleEnrollment } = yourIsraelCourse;
    if (
      courseScheduleEnrollment &&
      courseScheduleEnrollment.id &&
      courseSchedulesOffered.length
    ) {
      const enrolledCourseSchedule = courseSchedulesOffered.find(
        (cs) => cs.id === courseScheduleEnrollment.eduCourseScheduleID,
      );
      if (enrolledCourseSchedule) return enrolledCourseSchedule;
    }

    //return first current schedule
    return courseSchedulesOffered.filter((cs) => !cs.didClassesEnd)[0];
  };

  render() {
    const {
      actions,
      isShliachView,
      pageRoute: {
        params: { scheduleId },
      },
      profile: { data: { campus } = {} },

      course: {
        data: yourIsraelCourse,
        error: courseError,
        loading: courseLoading,
      },
      yourIsrael: {
        data: yourIsrael,
        error: yourIsraelError,
        loading: yourIsraelLoading,
      },
      enrollment,
      enrollmentUpdate,
    } = this.props;

    const { initialized, navigating, showEnrollmentCompleted } = this.state;

    const yourIsraelCourseSchedule = this.getCourseSchedule(
      yourIsraelCourse,
      scheduleId,
    );

    const isEnrolledInCourseSchedule =
      yourIsraelCourseSchedule &&
      yourIsrael &&
      yourIsrael.eduCourseSchedules &&
      !!yourIsrael.eduCourseSchedules.find(
        (cs) => cs.id === yourIsraelCourseSchedule.id,
      ); //student's eduCourseSchedules (course enrollments) include the current course schedule

    return !initialized ? ( //we set loading UI on initialized so we don't show loading again when reload YI/course info after enrollment
      <div>
        <Loader />
      </div>
    ) : yourIsraelError || courseError || !yourIsrael ? (
      <EnrollmentError error={yourIsraelError || courseError} />
    ) : yourIsrael ? (
      <React.Fragment>
        {showEnrollmentCompleted ? (
          <EnrollmentCompleted />
        ) : isEnrolledInCourseSchedule ||
          yourIsrael.eduStudentEnrollmentStatus === "Rejected" ? (
          <EnrollmentDetails
            enrollmentStatus={yourIsrael.eduStudentEnrollmentStatus}
            enrollmentUpdate={enrollmentUpdate}
            refreshCourseDetails={this.getYourIsrael}
            refreshingCourseDetails={courseLoading || yourIsraelLoading}
            yourIsraelCourse={yourIsraelCourse}
            yourIsraelCourseSchedule={yourIsraelCourseSchedule}
          />
        ) : !yourIsrael.eduChabadHouse || !yourIsraelCourseSchedule ? (
          <EnrollmentNotAvailable
            campus={campus}
            chabadHouseNotEnrolled={!yourIsrael.eduChabadHouse}
            courseScheduleNotFound={scheduleId && !yourIsraelCourseSchedule}
            isShliachView={isShliachView}
          />
        ) : !yourIsraelCourseSchedule.isRegistrationOpen ? (
          <EnrollmentClosed
            yourIsraelCourseSchedule={yourIsraelCourseSchedule}
          />
        ) : (
          <EnrollmentForm
            doCourseEnrollment={actions.doCourseEnrollment}
            enrollment={enrollment}
            isShliachView={isShliachView}
            navigate={this.navigate}
            navigating={navigating}
            onCompleteEnrollment={() =>
              this.setState({ showEnrollmentCompleted: true })
            }
            yourIsraelCourse={yourIsraelCourse}
            yourIsraelCourseSchedule={yourIsraelCourseSchedule}
          />
        )}
      </React.Fragment>
    ) : null;
  }
}
