import React from "react";
import AccountPageWrapper from "./components/AccountPageWrapper";
import Loader from "../../components/Loader";
import ValidatedInput from "../../components/ValidatedInput";

import {
  handleErrorMessage,
  handleInputState,
  handlePreventDefault,
  Navigation,
  PageURL,
  validateEmail,
} from "../../lib";
import Pages from "../../pages";

export default class ResendConfirmation extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      confirmEmail: "",
      email: "",
      password: "",
      showFormValidation: false,
      submitFormAttempted: false,
    };
  }

  isIncompleteResendConfirmation = () => {
    const { confirmEmail, email, password } = this.state;

    return (
      !password || !email || !validateEmail(email) || email !== confirmEmail
    );
  };

  onChangeInput = handleInputState(this);

  onSubmit = handlePreventDefault(() => {
    if (!this.props.pageRoute.query.id) {
      return;
    }

    this.setState(
      {
        showFormValidation: true,
        submitFormAttempted: true,
      },
      async () => {
        if (this.isIncompleteResendConfirmation()) {
          return;
        }

        const {
          pageRoute: {
            query: { after, id },
          },
        } = this.props;
        const { email, password } = this.state;

        await this.props.actions.doResendRegistrationConfirmation(
          id,
          email,
          password,
        );

        if (!this.props.error) {
          const confirmRegistrationURL = PageURL.to(
            Pages.account.confirm.path,
            null,
            { after, id },
          );
          Navigation.go(confirmRegistrationURL, {
            confirmRegistrationEmail: email,
          });
        } else {
          this.setState({
            password: "",
            showFormValidation: false,
          });
        }
      },
    );
  });

  render() {
    const {
      error,
      loading,
      pageRoute: {
        query: { id },
      },
    } = this.props;

    const {
      confirmEmail,
      email,
      password,
      showFormValidation,
      submitFormAttempted,
    } = this.state;

    return (
      <AccountPageWrapper>
        <div>
          <h1 className="uppercase-text fw-700 large-text text-center mb-40">
            Resend Activation Email
          </h1>
          <p
            className="medium-text line-height-double mb-56"
            style={{ letterSpacing: ".5px" }}
          >
            We sent you a message with a link to activate your account. If you
            didn't receive it, you can re-enter your email address or try
            another address, and we'll send you a new activation link.
          </p>
        </div>

        <form className="account-form">
          <ValidatedInput
            input={<input type="password" />}
            label="Password"
            name="password"
            onChange={this.onChangeInput}
            required={true}
            showRequired={showFormValidation}
            value={password}
          />
          <ValidatedInput
            label="Email"
            name="email"
            onChange={this.onChangeInput}
            required={true}
            showRequired={showFormValidation}
            showValidation={showFormValidation}
            validate={(email) => validateEmail(email)}
            validationMessage="Invalid email address"
            value={email}
          />
          <ValidatedInput
            label="Confirm Email"
            name="confirmEmail"
            onChange={this.onChangeInput}
            required={true}
            showRequired={showFormValidation}
            showValidation={showFormValidation}
            validate={(emailConfirmation, origEmail) =>
              emailConfirmation === origEmail
            }
            validationMessage="Email Addresses do not match"
            validationValue={email}
            value={confirmEmail}
          />

          {!loading ? (
            <React.Fragment>
              <button
                className="btn btn-large btn-fullWidth btn-accent mt-16"
                disabled={!id}
                onClick={this.onSubmit}
                type="submit"
              >
                Resend Email
              </button>

              <div className="error-message-container mt-4">
                <span className="error-message center">
                  {!id
                    ? "Sorry, looks like the link to this page is corrupted.  Please try again."
                    : showFormValidation &&
                      this.isIncompleteResendConfirmation()
                    ? "Please complete required/incomplete fields"
                    : !!error && submitFormAttempted
                    ? error.status === 401
                      ? "Password is incorrect"
                      : handleErrorMessage(error)
                    : ""}
                </span>
              </div>
            </React.Fragment>
          ) : (
            <div className="btn-large-loader">
              <Loader />
            </div>
          )}
        </form>
      </AccountPageWrapper>
    );
  }
}
