import { TripTypes } from "../TripConsts";
import { getWordCount, hasTrueFalseValue } from "../../../lib";

export function getInitialRegistration(props) {
  const {
    profile: {
      allergies,
      didConsentToMosaicMarketingContact,
      dietaryNotes,
      father,
      hasAllergies,
      legalFirstName,
      legalMiddleName,
      legalLastName,
      mother,
      person: { profileImageURL } = {},
      tShirtSize,
    },
    trip: {
      credCardInfo,
      enrollmentID,
      event: { isTravelTrip },
      termsAndConditions,
      tours,
      tracks,
    },
  } = props;

  const availableTracks = tracks.filter((t) => !t.isFullToCapacity);

  // default track for non-travel trips if there is one avail track, for travel trips there should always only be max one available track (we're therefore defaulting if there are any tracks)
  const setDefaultTrack = isTravelTrip ? availableTracks.length : availableTracks.length === 1;
  const defaultTrack = setDefaultTrack ? availableTracks[0] : null;

  return {
    billing: {
      address: {
        address1: "",
        address2: "",
        country: "",
        city: "",
        state: "",
        zip: "",
      },
      amountCents: 0,
      currencyCode: "USD",
      cardHolderFullName: "",
      stripeToken: "",
      useCardOnFile: !!credCardInfo,
    },
    billingPromoCodes: [],
    enrollmentID,
    didAcceptTermsAndConditions: !termsAndConditions.length,
    didConfirmInfo: false,
    didUseEarlyBird: defaultTrack
      ? defaultTrack.isBeforeEarlyBirdDeadline
      : false,
    profileDetails: {
      allergies: allergies || [],
      didConsentToMosaicMarketingContact,
      dietaryNotes: dietaryNotes || "",
      hasAllergies,
      legalFirstName: legalFirstName || "",
      legalMiddleName: legalMiddleName || "",
      legalLastName: legalLastName || "",
      profileImageURL: profileImageURL || "",
      tShirtSize: tShirtSize || "",
    },
    rewardRequest: null,
    tourScheduleIDs: [
      ...tours
        .filter((tour) => tour.autoEnrollStudents)
        .map(({ schedules }) => {
          const openTourSched = schedules.find(
            (sched) => !sched.isFullToCapacity,
          );
          return openTourSched ? openTourSched.id : schedules[0].id;
        }),
    ],
    trackID: defaultTrack?.id || 0,
    travelApplication: isTravelTrip
      ? {
          additionalInfoNote: "",
          emergencyContacts: [
            {
              name: mother?.name || "",
              email: mother?.email || "",
              phoneNumber: mother?.phoneNumber || "",
              phoneCountryID: mother?.phoneCountryID || "",
              relationship: mother ? "Mother" : "",
            },
            {
              name: father?.name || "",
              email: father?.email || "",
              phoneNumber: father?.phoneNumber || "",
              phoneCountryID: father?.phoneCountryID || "",
              relationship: father ? "Father" : "",
            },
          ],
          hasMedicalCondition: "",
          hasPriorTripExperience: "",
          isTakingMedication: "",
          isUnderPsychologicalCare: "",
          jewishCampusInvolvementDescription: "",
          knownTravelerNumber: "",
          medicalConditionDescription: "",
          medicationDescription: "",
          officialIdDocumentUrl: "",
          officialIdExpirationDate: "",
          officialIdNumber: "",
          otherReferralDescription: "",
          priorTripExperienceDescription: "",
          psychologicalCareDescription: "",
          referralSources: [],
          tripReason: "",
        }
      : null,
  };
}

export function validateRegistration(registration, trip, validationErrors) {
  const {
    didAcceptTermsAndConditions,
    didConfirmInfo,
    profileDetails,
    tourScheduleIDs,
    trackID,
    travelApplication,
    vaccinationProofsForUpload,
  } = registration;

  const {
    tours,
    event: {
      isMarketingConsentRequired,
      isProofOfVaccinationRequired, 
      isTravelTrip, 
      isTShirtSizeRequired, 
      type 
    },
  } = trip;

  // check overlapping Tour Schedules
  const overlappingTourSchedules = {};
  if (tourScheduleIDs.length > 1) {
    const doSchedulesOverlap = (schedA, schedB) => {
      return (
        schedA.time <= schedB.calculatedEndTime &&
        schedB.time <= schedA.calculatedEndTime
      );
    };

    const enrolledTourSchedules = [];
    tours.forEach((tour) => {
      tour.schedules
        .filter((tourSched) => tourScheduleIDs.indexOf(tourSched.id) >= 0)
        .forEach((tourSched) => {
          enrolledTourSchedules.push({
            ...tourSched,
            name: `${tour.name} - ${tourSched.name}`,
          });
        });
    });

    enrolledTourSchedules.forEach((tourSchedule) => {
      const overlappingSchedules = enrolledTourSchedules.filter(
        (otherTourSchedule) =>
          otherTourSchedule.id !== tourSchedule.id &&
          doSchedulesOverlap(tourSchedule, otherTourSchedule),
      );
      if (overlappingSchedules.length) {
        overlappingTourSchedules[tourSchedule.id] = overlappingSchedules;
      }
    });
  }

  // if there are validation errors, don't bother validating anyting else, return (with overlapping tours info) immediately
  if (validationErrors?.length) {
    return {
      isIncomplete: true,
      overlappingTourSchedules
    };
  }

  const incompleteProofOfVax = isProofOfVaccinationRequired && !vaccinationProofsForUpload?.length;
  const incompleteMarketingConsent = isMarketingConsentRequired &&
  !hasTrueFalseValue(profileDetails.didConsentToMosaicMarketingContact);

  let isIncomplete =
    !didAcceptTermsAndConditions ||
    !didConfirmInfo ||
    !trackID ||
    incompleteProofOfVax ||
    incompleteMarketingConsent ||
    Object.keys(overlappingTourSchedules).length > 0;

  // If not already found incomplete, check the following for Travel Trips:
  if (!isIncomplete && isTravelTrip) {

    // check for incomplete Profile details
    const {
      allergies,
      dietaryNotes,
      hasAllergies,
      legalFirstName,
      legalLastName,
      profileImageURL,
      tShirtSize,
    } = profileDetails;

    isIncomplete = !legalFirstName 
      || !legalLastName 
      || !profileImageURL
      || (isTShirtSizeRequired && !tShirtSize)
      || !hasTrueFalseValue(hasAllergies)
      || (hasAllergies && !allergies.length)
      || (allergies.includes("Other") && !dietaryNotes);

    // If not already found incomplete, check for incomplete Application fields
    if (!isIncomplete) {
      const {
        emergencyContacts,
        hasMedicalCondition,
        hasPriorTripExperience,
        isTakingMedication,
        isUnderPsychologicalCare,
        jewishCampusInvolvementDescription,
        medicalConditionDescription,
        medicationDescription,
        officialIdDocForUpload,
        officialIdExpirationDate,
        officialIdNumber,
        otherReferralDescription,
        priorTripExperienceDescription,
        psychologicalCareDescription,
        referralSources,
        tripReason,
      } = travelApplication;

      const incompleteEmergencyContacts = emergencyContacts.some(c => !c.name || !c.email || !c.phoneNumber || !c.phoneCountryID || !c.relationship);

      const incompleteLivingLinksTripFields = type === TripTypes.LivingLinks && (
        !tripReason 
        || getWordCount(tripReason) < 200
        || !jewishCampusInvolvementDescription
        || !referralSources.length
        || (referralSources.includes("Other") && !otherReferralDescription)
        || !hasTrueFalseValue(hasPriorTripExperience)
        || (hasPriorTripExperience && !priorTripExperienceDescription)
      );

      isIncomplete = incompleteEmergencyContacts
        || incompleteLivingLinksTripFields
        || !hasTrueFalseValue(hasMedicalCondition)
        || (hasMedicalCondition && !medicalConditionDescription)
        || !hasTrueFalseValue(isTakingMedication)
        || (isTakingMedication && !medicationDescription)
        || !hasTrueFalseValue(isUnderPsychologicalCare)
        || (isUnderPsychologicalCare && !psychologicalCareDescription)
        || !officialIdNumber
        || !officialIdExpirationDate
        || !officialIdDocForUpload;
    }
  }

  return {
    isIncomplete,
    overlappingTourSchedules
  };
};

export function isTripRedeemedViaRewardCredits(trip) {
  const { availableRewardInfo, event: { type } } = trip;
  return type === TripTypes.JewishU && !!availableRewardInfo;
}

export function getPromoDiscountInCredits(validPromoCodes, creditValue) { 
  if (!validPromoCodes.length || !creditValue) return null;

  return validPromoCodes[0].discountAmount / creditValue;
};

// Form caching code removed from RegistrationForm.jsx:

// NOTE - removed form caching for now being that there are so few cache-able form options
// and therefore not worth complexity involved in caching the form and handling profile updates and related trip setting updates.
// componentWillUnmount() {
//   if (!this.props.navigating) {
//     this.cacheRegistrationState();
//   }
// }

// componentDidMount() {
//   window.onbeforeunload = () => {
//     this.cacheRegistrationState();
//     return null;
//   };

//   this.rehydrateRegistrationState();
// }

// cacheRegistrationState = () => {
//   if(!this.props.studentId) {
//     return;
//   }

//   const {
//     initialState: {
//       registration: initialRegistration,
//       visibleStepIndex: initialVisibleStepIndex
//     },
//     registration,
//     visibleStepIndex
//   } = this.state;

//   if (
//     visibleStepIndex !== initialVisibleStepIndex ||
//     !_isEqual(registration, initialRegistration)
//   ) {
//     const registrationState = JSON.stringify({
//       ...this.state,
//       registration: {
//         ...registration,
//         didAcceptTermsAndConditions: false, //not caching terms and conditions - must re-accept
//         billing: initialRegistration.billing //not caching billing info for security purposes
//       }
//     });
//     localStorage.setItem(
//       `registration-${this.props.studentId}`,
//       registrationState
//     );
//   }
// };

// rehydrateRegistrationState = async () => {
//   if(!this.props.studentId) {
//     return;
//   }

//   const registrationState = JSON.parse(
//     localStorage.getItem(`registration-${this.props.studentId}`)
//   );

//   if (registrationState) {
//     this.setState(registrationState, () => {
//       if (registrationState.visibleStepIndex > 0) {
//         this.scrollToStep(registrationState.visibleStepIndex);
//       }
//     });
//   }

//   this.setState({ rehydrated: true });
// };