import React from "react";
import YourIsraelContainer from "./YourIsraelContainer";
import Enrollment from "./components/Enrollment.vm";
import { withAppInsights } from "../../lib/AppInsights";

class YourIsraelEnrollment extends React.PureComponent {
  render() {
    return (
      <YourIsraelContainer {...this.props} title="Your Israel Enrollment">
        <Enrollment />
      </YourIsraelContainer>
    );
  }
}

export default withAppInsights(YourIsraelEnrollment);
