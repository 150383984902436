import {
  AuthRequest,
  FileUploader,
  createUniqueNameForFile,
  trimTrailingSlash,
} from "../../lib";

/** @enum {string} */
const FileType = {
  profile: { path: "profile" },
  vaccination: { path: "vaccination" },
  identification: { path: "identification" },
};

export const SysActions = {
  GET_SYSTEM_DATA: "GET_SYSTEM_DATA",
  UPLOAD_IMAGE: "UPLOAD_IMAGE",

  getSystemData() {
    return async function gettingSystemData(dispatch, getState) {
      const response = await AuthRequest.get(
        `System?portalRedirectsDomain=${window.location.hostname}`,
      );
      dispatch({
        type: SysActions.GET_SYSTEM_DATA,
        payload: response.data.payload,
      });
    };
  },
  /** @param {File} file
   * @param {FileType} filetype
   */
  uploadFile(file, fileType, isSecure) {
    fileType = ("" + fileType).trim();
    const fileTypeInfo = FileType[fileType];
    if (!fileTypeInfo) {
      throw new Error(`FileType not found: ${fileTypeInfo}`);
    }
    return async function uploadingFile(/* dispatch, getState */) {
      let {
        data: {
          payload: {
            blobContainerURI,
            // container,
            sas: sasQueryString,
          },
        },
      } = await AuthRequest.get("/Storage/sas", { params: { isSecure } });
      blobContainerURI = trimTrailingSlash(blobContainerURI);
      const fileName = createUniqueNameForFile(file);
      const fileURL = `${blobContainerURI}/${fileTypeInfo.path}/${fileName}`;
      const uploadURL = `${fileURL}${sasQueryString}`;
      const uploader = new FileUploader(file, uploadURL);
      await uploader.start();
      return fileURL;
    };
  },
};
