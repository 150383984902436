import React from "react";
import InfiniteScrollContainer from "../../../../components/InfiniteScrollContainer";
import Loader from "../../../../components/Loader";
import UpcomingEventCard from "./UpcomingEventCard";
import { PageURL } from "../../../../lib";
import Pages from "../../../../pages";

export default class UpcomingEventsList extends React.PureComponent {
  state = {
    page: 1,
    resultsView: 12,
  };

  componentDidMount() {
    this.loadEventSchedules();
  }

  componentWillUnmount() {
    this.props.resetEventSchedules();
  }

  loadEventSchedules = async (loadMore) => {
    const {
      pageRoute: {
        params: { chabadHouseSlug },
      },
    } = this.props;
    let { page, resultsView } = this.state;

    if (loadMore) {
      page = page + 1;
      this.setState({ page });
    }

    await this.props.getEventSchedules(chabadHouseSlug, page, resultsView);
  };

  render() {
    const {
      eventSchedules: {
        data: {
          results: eventSchedules,
          numberOfRows: totalEventSchedules,
        } = {},
        error,
        loading,
      },
      rsvpLocation,
      pageRoute: {
        params: { chabadHouseSlug },
      },
      user,
    } = this.props;

    const { page } = this.state;

    const hasMore =
      eventSchedules &&
      totalEventSchedules > 0 &&
      eventSchedules.length > 0 &&
      totalEventSchedules > eventSchedules.length;

    return (
      <div className="rsvp-container">
        <p className="xxxl-text mobile-xl-text line-height-double fw-700 mb-8 mobile-mb-8">
          Upcoming Events
        </p>
        <p
          className="medium-text line-height-double mb-32"
          style={{ whiteSpace: "pre-wrap" }}
        >
          {rsvpLocation.message}
        </p>
        <InfiniteScrollContainer
          onScrollEnd={
            loading || !hasMore ? null : () => this.loadEventSchedules(true)
          }
          scrollEndOffset={100}
          showScrollToTop={!error && page > 1}
        >
          {error ? (
            <div>
              <img src="/images/error.svg" alt="error-bot" height="240" />
              <p className="xxl-text mt-24 mb-16">An error has occured</p>
              <p className="medium-text accent-text fw-500">
                Our server is being stubborn, please try again
              </p>
            </div>
          ) : !totalEventSchedules && !loading ? (
            <div className="text-center">
              <img src="/images/no_results.svg" alt="no results" height="200" />
              <p className="large-text">
                There are no upcoming events at this location.
              </p>
              <p className="large-text mt-8">Please try back later.</p>
            </div>
          ) : (
            <div className="rsvp-events-grid">
              {eventSchedules &&
                eventSchedules.map((eventSchedule) => (
                  <UpcomingEventCard
                    eventSchedule={eventSchedule}
                    eventPageURL={PageURL.to(
                      Pages.rsvp.register,
                      {
                        chabadHouseSlug,
                        eventScheduleId: eventSchedule.eventScheduleID,
                      },
                      null,
                    )}
                    key={eventSchedule.eventScheduleID}
                    user={user}
                  />
                ))}
            </div>
          )}
          {loading ? (
            <div className="mt-16">
              <Loader />
            </div>
          ) : (
            hasMore && (
              <button
                className="btn btn-accent btn-large mt-16"
                onClick={() => this.loadEventSchedules(true)}
                style={{
                  margin: "48px auto 8px",
                  display: "block",
                  minWidth: 160,
                }}
              >
                Load More
              </button>
            )
          )}
        </InfiniteScrollContainer>
      </div>
    );
  }
}
