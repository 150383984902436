import React from "react";
import AutoApplyReward from "./AutoApplyReward";
import ManualApplyReward from "./ManualApplyReward";

function ApplyReward(props) {
  const { autoApply, ...passProps } = props;

  return autoApply ? (
    <AutoApplyReward {...passProps} />
  ) : (
    <ManualApplyReward {...passProps} />
  );
}

export default React.memo(ApplyReward);
