import React from "react";
import Layout from "../../components/Layout.vm";
import ProfileForm from "../profile/components/ProfileForm.vm";

export default class YourIsraelContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      pageRoute: {
        query: { exp, shliach, token },
      },
    } = props;
    const isShliachView = !!(exp && shliach && token); //do not check student profile status for shliach view

    this.state = {
      isShliachView,
      checkProfileStatus: !isShliachView, // do not show enrollment form until profile status has been checked
      profileStatusCheckError: false,
      showCompleteProfileForm: false,
    };
  }

  onProfileIsComplete = (success = true) => {
    if (this.state.showCompleteProfileForm) {
      this.setState({
        showCompleteProfileForm: false,
      });
    }

    if (!success) {
      this.setState({
        profileStatusCheckError: true,
      });
    }
  };

  render() {
    const { children, title } = this.props;

    const {
      isShliachView,
      checkProfileStatus,
      profileStatusCheckError,
      showCompleteProfileForm,
    } = this.state;

    return isShliachView ? (
      <React.Fragment>
        <header className="shliach-view-header">
          <div className="container full-width">
            <div className="flex flex-align-center">
              <p className="fw-700 xxl-text">Shliach View Mode</p>
              <span className="accent-text small-text ml-16">
                (You are viewing this form in Shliach View mode. Submission is
                disabled.)
              </span>
            </div>
          </div>
        </header>
        <div className="page-wrapper">
          <div className="page your-israel-page">
            {React.cloneElement(children, {
              ...this.props,
              isShliachView: true,
            })}
          </div>
        </div>
      </React.Fragment>
    ) : (
      <Layout {...this.props} title={title}>
        <div className="page your-israel-page">
          {profileStatusCheckError ? (
            <div>
              Sorry, something went wrong and we could not retrieve your profile
              information for Your Israel enrollment. Please reload the page to
              try again.
            </div>
          ) : checkProfileStatus || showCompleteProfileForm ? (
            <ProfileForm
              completeProfileReason="Your Israel"
              isCompleteProfilePrompt={true}
              onProfileFetched={(success, isProfileComplete) => {
                this.setState({
                  checkProfileStatus: false,
                  showCompleteProfileForm: !isProfileComplete,
                });
                if (!success) {
                  //need to show error msg if profile retrieval failed
                  this.onProfileIsComplete(false);
                }
              }}
              onProfileIsComplete={() => this.onProfileIsComplete()}
              requiredFields={[
                "Gender",
                "HasTakenIsraelCourses",
                "JewishIsraelKnowledgeLevel",
              ]}
              //NOTE: to hook up to custom profile validation implementation, we'll need to send in a programID and t/f will need to request YI info in container instead of in children, as with implementation update for RSVP
              //we can then remove the requiredFields prop and usage in profileForm
            />
          ) : (
            React.cloneElement(children, this.props)
          )}
        </div>
      </Layout>
    );
  }
}
