import React, { useCallback, useState } from "react";
import FadeOutMessage from "../../../../../components/FadeOutMessage";

function ApplyPromoCode(props) {
  const {
    applyPromoCodes,
    billingPromoCodes,
    billingTotal,
    discountableTotal,
    validPromoCodes,
  } = props;

  const [promoCodeError, setPromoCodeError] = useState("");
  const [promoCodeText, setPromoCodeText] = useState("");

  const onApplyPromoCode = useCallback(
    (event) => {
      event.preventDefault();

      if (!promoCodeText) return;

      let canApplyPromoCode = true;
      const validPromoCode = validPromoCodes.find(
        (promo) =>
          promo.code.toLowerCase() === promoCodeText.trim().toLowerCase(),
      );
      if (validPromoCode) {
        const { counter, threshold } = validPromoCode;
        if (threshold && counter >= threshold) {
          canApplyPromoCode = false;
        }
      } else {
        canApplyPromoCode = false;
      }

      setPromoCodeError("");
      setPromoCodeText("");

      if (!canApplyPromoCode) {
        setPromoCodeError("Invalid promo code");
      } else if (discountableTotal.minus(validPromoCode.discountAmount) < 0) {
        setPromoCodeError(
          `Promo code discount exceeds the ${
            billingTotal > discountableTotal ? "pre-deposit total" : "total"
          }`,
        );
      } else if (billingPromoCodes.indexOf(validPromoCode.code) >= 0) {
        setPromoCodeError(
          `Promo code ${validPromoCode.code} has already been applied`,
        );
      } else {
        applyPromoCodes([...billingPromoCodes, validPromoCode.code]);
      }
    },
    [
      applyPromoCodes,
      billingPromoCodes,
      billingTotal,
      discountableTotal,
      promoCodeText,
      validPromoCodes,
    ],
  );

  const onEnter = useCallback(
    (event) => {
      if (event.key === "Enter") onApplyPromoCode(event);
    },
    [onApplyPromoCode],
  );

  const onRemovePromoCode = useCallback(
    (promoCode) => {
      applyPromoCodes(billingPromoCodes.filter((code) => code !== promoCode));
    },
    [applyPromoCodes, billingPromoCodes],
  );

  return (
    <div className="promo-code mb-24" style={{ maxWidth: "280px" }}>
      <div className="flex flex-align-center mb-8">
        <div className="input-container full-width">
          <label>Enter Promo Code</label>
          <input
            type="text"
            className="full-width"
            name="promoCodeText"
            value={promoCodeText}
            onChange={(e) => setPromoCodeText(e.target.value)}
            onKeyDown={onEnter}
          />
        </div>
        <button
          className="btn btn-accent btn-large ml-16"
          style={{ width: "80px", minWidth: "80px" }}
          disabled={!promoCodeText}
          onClick={onApplyPromoCode}
        >
          Apply
        </button>
      </div>

      {promoCodeError && (
        <FadeOutMessage
          message={promoCodeError}
          onTimeout={() => setPromoCodeError("")}
        />
      )}

      <div>
        {billingPromoCodes?.map((promoCode, index) => (
          <div key={index} className="promo-code-tag mr-8 mb-4">
            {promoCode}
            <i
              className="material-icons ml-8 link-text-secondary"
              onClick={() => onRemovePromoCode(promoCode)}
            >
              close
            </i>
          </div>
        ))}
      </div>
    </div>
  );
}

export default React.memo(ApplyPromoCode);
