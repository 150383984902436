import React from "react";
import Layout from "../../components/Layout.vm";
import ProfileForm from "../profile/components/ProfileForm.vm";

import Pages from "..";
import { PageLink } from "../../lib";
import { TravelTripTypes } from "./TripConsts";

const getRequiredFieldsByEventType = (eventType) => {
  const isTravelTrip =
    eventType &&
    TravelTripTypes.map((t) => t.toLowerCase()).includes(
      eventType.toLowerCase(),
    );

  if (!isTravelTrip) {
    return [
      "Allergies",
      "FatherBackground",
      "Gender",
      "IsJewish",
      "MotherBackground",
    ];
  }

  return [
    "Allergies",
    "FatherBackground",
    "FatherEmail",
    "FatherName",
    "FatherPhone",
    "Gender",
    "GraduationYear",
    "HadBarBatMitzvah",
    "HasTakenIsraelCourses",
    "JewishEducation",
    "JewishIsraelKnowledgeLevel",
    "MotherBackground",
    "MotherEmail",
    "MotherName",
    "MotherPhone",
    "ProfileImageURL",
  ];
};

export default class TripContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      pageRoute: {
        query: { exp, shliach, token },
      },
    } = props;
    const isShliachView = !!(exp && shliach && token); //do not check student profile status for shliach view

    this.state = {
      isShliachView,
      checkProfileStatus: !isShliachView, // do not show registration form until profile status has been checked
      profileStatusCheckError: false,
      showCompleteProfileForm: false,
    };
  }

  onProfileIsComplete = (success = true) => {
    if (this.state.showCompleteProfileForm) {
      this.setState({
        showCompleteProfileForm: false,
      });
    }

    if (!success) {
      this.setState({
        profileStatusCheckError: true,
      });
    }
  };

  render() {
    const {
      children,
      pageRoute: { page, params, location },
      title,
    } = this.props;

    const {
      isShliachView,
      checkProfileStatus,
      profileStatusCheckError,
      showCompleteProfileForm,
    } = this.state;

    return isShliachView ? (
      <React.Fragment>
        <header className="shliach-view-header">
          <div className="container full-width">
            <div className="flex flex-align-center mobile-block">
              <p className="fw-700 xxl-text mobile-large-text mr-16">
                Shliach View Mode
              </p>
              <span className="accent-text small-text">
                (You are viewing this form in Shliach View mode. Submission is
                disabled.)
              </span>
            </div>
          </div>
        </header>
        <div className="page-wrapper">
          <div className="page trip-page">
            {React.cloneElement(children, {
              ...this.props,
              isShliachView: true,
            })}
          </div>
        </div>
      </React.Fragment>
    ) : (
      <Layout {...this.props} title={title}>
        <div className="page trip-page">
          {checkProfileStatus || showCompleteProfileForm ? (
            <ProfileForm
              completeProfileReason="trip registration"
              isCompleteProfilePrompt={true}
              onProfileFetched={(success, isProfileComplete) => {
                this.setState({
                  checkProfileStatus: false,
                  showCompleteProfileForm: !isProfileComplete,
                });
                if (!success) {
                  //need to show error msg if profile retrieval failed
                  this.onProfileIsComplete(false);
                }
              }}
              onProfileIsComplete={() => this.onProfileIsComplete()}
              requiredFields={getRequiredFieldsByEventType(params.eventType)}
              //NOTE: to hook up to custom profile validation implementation, we'll need to send in a programID and t/f will need to request Trip info in container instead of in children, as with implementation update for RSVP
              //we can then remove the requiredFields prop and usage in profileForm
            />
          ) : (
            <React.Fragment>
              {profileStatusCheckError && (
                <div>
                  If you are missing required profile information, you will need
                  to update it before registering for a trip. You can check the
                  status of your profile{" "}
                  <PageLink
                    className="link-text"
                    style={{ width: "220px" }}
                    to={Pages.profile.studentProfile}
                    state={{
                      previousLocationPageType: page.type,
                      previousLocationPath: location.pathname,
                    }}
                  >
                    here
                  </PageLink>
                  .
                </div>
              )}
              {React.cloneElement(children, this.props)}
            </React.Fragment>
          )}
        </div>
      </Layout>
    );
  }
}
