import React from "react";
import { Prompt } from "react-router";
import { injectStripe } from "react-stripe-elements";
import JoinWaitlist from "./JoinWaitlist";
import PolicyAgreementAndQuestions from "./PolicyAgreementAndQuestions";
import Seating from "./Seating";
import RegistrationPayment from "./RegistrationPayment";
import UpdateCampusModal from "./UpdateCampusModal";
import { handleErrorMessage, toTitleCase } from "../../../../../lib";
import _set from "lodash.set";
import _isEqual from "lodash.isequal";
import _cloneDeep from "lodash.clonedeep";
import RegistrationNotAvailable from "./RegistrationNotAvailable";
import {
  constructStripeBillingDetails,
  submitOneTimeStripePayment,
} from "../../../../../lib/payment";

export function EventRegistrationForm(props) {
  const { eventSchedule, doEventRegistration, eventRegistration } = props;

  return (
    <RegistrationForm
      rsvpType="event"
      rsvpItem={eventSchedule}
      doRegistration={(registration) =>
        doEventRegistration({
          ...registration,
          eventScheduleID: eventSchedule.eventScheduleID,
        })
      }
      completedRegistration={eventRegistration}
      allowWaitlist
      completionAction="Register"
      {...props}
    />
  );
}
export const EventRegistrationFormWithStripe = injectStripe(
  EventRegistrationForm,
);

export function ResourceRegistrationForm(props) {
  const { resource, doResourceRegistration, resourceRegistration } = props;

  return (
    <RegistrationForm
      rsvpType="resource"
      rsvpItem={resource}
      doRegistration={(registration) =>
        doResourceRegistration({
          ...registration,
          resourceID: resource.resourceID,
        })
      }
      completedRegistration={resourceRegistration}
      allowWaitlist={false}
      completionAction="Sign Up"
      {...props}
    />
  );
}
export const ResourceRegistrationFormWithStripe = injectStripe(
  ResourceRegistrationForm,
);

class RegistrationForm extends React.PureComponent {
  wizardSteps = {};

  constructor(props) {
    super(props);

    const {
      personId,
      user,
      rsvpItem: {
        askForDonation,
        attendeesByTable,
        registrationFeeAmount,
        registrationQuestions = [],
      },
    } = props;

    this.wizardSteps = this.getWizardSteps(
      attendeesByTable && attendeesByTable.length > 0,
      !!registrationFeeAmount || !!askForDonation,
    );

    const state = {
      didAcceptPolicy: false,
      ccInfoComplete: false,
      willPayDonation: false,
      cardHolderFullName: `${user.firstName} ${user.lastName}`,
      errorMessage: "",
      registration: {
        notes: "",
        registrationQuestionResponses: registrationQuestions.map((rq) => ({
          isMandatory: rq.isMandatory, //isMandatory and questionText fields are not necessary for API and will be ignored, mapped for easy access in FE
          questionText: rq.questionText,
          registrationQuestionId: rq.id,
          responseType: rq.responseType, //responseType field is required by API for response value formatting
          value: "",
        })),
        paymentInfo: {
          registrationFeeAmount,
          donationAmount: null,
        },
        source: "Portal",
        personId,
        sortOrder: null,
        updateCampus: false,
      },
      showUpdateCampusModal: false,
      validateStepAndAdvance: false,
      submitAttempted: false,
      wizardStepIndex: 0,
      loading: false,
    };

    state.initialState = _cloneDeep(state);
    this.state = state;
  }

  componentDidMount() {
    const {
      rsvpItem: { isStudentMissingCH },
    } = this.props;
    if (isStudentMissingCH) {
      this.setState({ showUpdateCampusModal: true });
    }
  }

  getWizardSteps = (seatingSelectionAvailable, showPaymentScreen) => {
    const steps = [];

    if (seatingSelectionAvailable) {
      steps.push({ component: Seating, ref: "seating" });
    }
    steps.push({ component: PolicyAgreementAndQuestions, ref: "policy" });
    if (showPaymentScreen) {
      steps.push({ component: RegistrationPayment, ref: "payment" });
    }

    return steps;
  };

  onChange = (name, value) => {
    let state = _cloneDeep(this.state);
    _set(state, name, value);

    if (state.errorMessage) {
      state.errorMessage = "";
    }

    this.setState(state);
  };

  onChangeRegistration = (name, value) =>
    this.onChange(`registration.${name}`, value);

  submitRegistration = async (waitlistRegistration) => {
    this.setState({ errorMessage: "", submitAttempted: true, loading: true });

    const { registration, willPayDonation, cardHolderFullName } = this.state;
    const {
      rsvpItem: { registrationFeeAmount },
      doRegistration,
      stripe,
    } = this.props;

    registration.status = waitlistRegistration ? "Waitlisted" : "Registered";
    registration.sendRegistrantEmail = true;
    if (!willPayDonation) {
      delete registration.paymentInfo.donationAmount;
    }

    var error = null;
    if (registrationFeeAmount || willPayDonation) {
      const billingDetails = constructStripeBillingDetails(cardHolderFullName);
      await submitOneTimeStripePayment(
        stripe,
        billingDetails,
        registration,
        doRegistration,
      )
        // catch stripe errors
        .catch((err) => (error = err));
    } else {
      await doRegistration(registration);
    }

    const { onCompleteRegistration, completedRegistration } = this.props;

    // catch api errors - we can't have both api and stripe errors so no need to check if error is already set
    if (completedRegistration.error) {
      error = completedRegistration.error;
    }

    if (error) {
      this.handleSubmissionError(error);
    } else {
      this.setState({ navigating: true }, onCompleteRegistration);
    }
  };

  handleSubmissionError = (error) => {
    const errorMessage = handleErrorMessage(error);
    this.setState({
      errorMessage: this.getDisplayErrorMessage(errorMessage),
      loading: false,
    });
  };

  goToStep = (index) => {
    if (this.wizardSteps[index]) {
      // TODO why is this check necessary?
      this.setState({ wizardStepIndex: index });
    }
  };

  handleValidationAndAdvanceStep = (errorMessage) => {
    this.setState({ validateStepAndAdvance: false });

    if (errorMessage) {
      this.setState({ errorMessage });
      return;
    }

    const { wizardStepIndex } = this.state;
    const finalStep = wizardStepIndex === this.wizardSteps.length - 1;
    if (finalStep) {
      this.submitRegistration(false);
    } else {
      this.setState({ submitAttempted: false });
      this.goToStep(wizardStepIndex + 1);
    }
  };

  getButtonsSection = () => {
    const { wizardStepIndex, willPayDonation, errorMessage, loading } =
      this.state;

    const {
      completedRegistration,
      rsvpItem: { registrationFeeAmount },
      completionAction = "Register",
    } = this.props;

    const finalStep = wizardStepIndex === this.wizardSteps.length - 1;
    const isLoading = loading || completedRegistration.loading;

    const nextSubmitButtonText = isLoading
      ? "Processing..."
      : !finalStep
      ? "Continue"
      : !!registrationFeeAmount
      ? `Pay & ${completionAction}`
      : willPayDonation
      ? `Donate & ${completionAction}`
      : `${completionAction}`;

    return (
      <div className="flex flex-align-center flex-justify-end relative rsvp-form-btns">
        {wizardStepIndex > 0 && (
          <button
            className="btn btn-accent btn-large mobile-full-width mr-16"
            disabled={isLoading}
            onClick={() => this.goToStep(wizardStepIndex - 1)}
            style={{ minWidth: 120 }}
          >
            Back
          </button>
        )}

        <button
          className="btn btn-accent btn-large mobile-full-width"
          disabled={isLoading}
          onClick={() =>
            this.setState({
              submitAttempted: true,
              validateStepAndAdvance: true,
            })
          }
          style={{ minWidth: 175 }}
        >
          {nextSubmitButtonText}
        </button>
        {errorMessage && (
          <p
            className="error-text mt-8"
            style={{ position: "absolute", bottom: -24 }}
          >
            {errorMessage}
          </p>
        )}
      </div>
    );
  };

  // TODO send back on api as properties
  getDisplayErrorMessage = (errorMessage) => {
    const { rsvpType } = this.props;
    if (errorMessage.indexOf("already registered") > 0) {
      return `You are already registered for this ${rsvpType}`;
    } else if (errorMessage.indexOf("already waitlisted") > 0) {
      return `You are currently waitlisted for this ${rsvpType}`;
    } else if (errorMessage.indexOf("not available") > 0) {
      return `${toTitleCase(
        rsvpType,
      )} registration is not available for students who are not attending a local campus`;
    }

    return errorMessage;
  };

  render() {
    const {
      chabadHouseSlug,
      completedRegistration,
      rsvpItem,
      rsvpType,
      allowWaitlist,
    } = this.props;

    const {
      didAcceptPolicy,
      ccInfoComplete,
      willPayDonation,
      cardHolderFullName,
      errorMessage,
      initialState,
      navigating,
      registration,
      showUpdateCampusModal,
      validateStepAndAdvance,
      submitAttempted,
      wizardStepIndex,
      loading,
    } = this.state;

    return (
      <React.Fragment>
        <Prompt
          when={
            !navigating && !_isEqual(registration, initialState.registration)
          }
          message="Are you sure you want to leave this page before submitting your registration?"
        />
        {rsvpItem.isFullyBooked ? (
          rsvpItem.registrationFeeAmount || !allowWaitlist ? (
            <RegistrationNotAvailable
              rsvpType={rsvpType}
              chabadHouseSlug={chabadHouseSlug}
            />
          ) : (
            <JoinWaitlist
              errorMessage={errorMessage}
              loading={completedRegistration.loading || loading}
              submitRegistration={() => this.submitRegistration(true)}
            />
          )
        ) : (
          this.wizardSteps[wizardStepIndex] && (
            <div className="full-width">
              {React.createElement(
                this.wizardSteps[wizardStepIndex].component,
                {
                  rsvpItem,

                  //registration fields:
                  registration,
                  onChangeRegistration: this.onChangeRegistration,

                  //policy agreement:
                  didAcceptPolicy,
                  onToggleAcceptPolicy: (didAccept) =>
                    this.onChange("didAcceptPolicy", didAccept),

                  // payment:
                  ccInfoComplete,
                  setCcInfoComplete: (complete) =>
                    this.onChange("ccInfoComplete", complete),
                  willPayDonation,
                  setWillPayDonation: (willPay) =>
                    this.onChange("willPayDonation", willPay),
                  cardHolderFullName,
                  setCardHolderFullName: (name) =>
                    this.onChange("cardHolderFullName", name),

                  //validation
                  validateStepAndAdvance,
                  submitAttempted,
                  handleValidationAndAdvanceStep:
                    this.handleValidationAndAdvanceStep,
                },
              )}
              {this.getButtonsSection()}
            </div>
          )
        )}

        <UpdateCampusModal
          campusName={rsvpItem.chPrimaryCampusName}
          chabadHouseName={rsvpItem.chabadHouseName}
          chabadHouseSlug={chabadHouseSlug}
          confirmUpdateCampus={() => {
            this.onChange("registration.updateCampus", true);
            this.setState({
              showUpdateCampusModal: false,
            });
          }}
          message={errorMessage}
          show={showUpdateCampusModal}
        />
      </React.Fragment>
    );
  }
}
