import React from "react";
import Dropzone from "react-dropzone";
import FadeOutErrorMessage from "./FadeOutErrorMessage";
import FilePreviewModal from "./FilePreviewModal";

export default class FileUpload extends React.PureComponent {
  state = {
    errorMessage: "",
    loading: false,
    showFilePreviewModal: false,
    successMessage: "",
  };

  selectDocument = (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];

      this.setState({
        errorMessage: "",
      });

      const fileWithPreview = Object.assign(file, {
        previewURL: URL.createObjectURL(file),
      });

      this.props.submitDocument("add", fileWithPreview, this.props.index);
    } else {
      this.setState({
        errorMessage: "File could not be uploaded. Please try again.",
      });
    }
  };

  render() {
    const {
      description,
      documentURL,
      disabled,
      error,
      index,
      isPhone,
      isSecure,
      title,
    } = this.props;
    const { errorMessage, loading, showFilePreviewModal, successMessage } =
      this.state;

    return (
      <div>
        <p className="medium-text fw-700 mb-16">{title || ""}</p>

        <Dropzone
          accept="application/pdf,image/png,image/jpeg,image/bmp,image/jpg"
          className={`dropzone${disabled ? " disabled" : ""}`}
          disabled={disabled || loading}
          onDrop={this.selectDocument}
          style={{ background: "#000" }}
        >
          {({ getRootProps, getInputProps }) => (
            <div>
              {!documentURL && (
                <p
                  {...getRootProps()}
                  className={`uppercase-text fw-500 link-text ${
                    error ? " error" : ""
                  }`}
                >
                  <input {...getInputProps()} />
                  {index ? "Upload another document" : "Upload document"}
                </p>
              )}
            </div>
          )}
        </Dropzone>

        {(errorMessage || successMessage) && (
          <FadeOutErrorMessage
            className={successMessage ? "success-message" : ""}
            message={errorMessage || successMessage}
            onTimeout={() =>
              this.setState({
                errorMessage: "",
                successMessage: "",
              })
            }
          />
        )}

        {loading ? (
          <div className="accent-text">Uploading Document...</div>
        ) : (
          documentURL && (
            <div className="flex flex-align-center mt-16">
              <p
                className="flex flex-align-center accent-text link-text"
                onClick={() => this.setState({ showFilePreviewModal: true })}
              >
                <i className="material-icons mr-8">description</i>
                {description}
              </p>
              <p
                className="material-icons large-text link-text fw-700 ml-16"
                onClick={() => {
                  this.props.submitDocument("remove", this.props.index);
                }}
              >
                close
              </p>
            </div>
          )
        )}

        <FilePreviewModal
          close={() => this.setState({ showFilePreviewModal: false })}
          isPhone={isPhone}
          isSecure={isSecure}
          show={showFilePreviewModal}
          title={`${description} Preview`}
          type={"application/pdf"}
          url={documentURL}
        />
      </div>
    );
  }
}
