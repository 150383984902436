import React from "react";
import { PageLink } from "../../../../../lib";
import Pages from "../../../..";

export default ({ chabadHouseSlug }) => (
  <div className="card flat-card full-width flex flex-align-center flex-justify-center text-center rsvp-state-card">
    <div className="text-center">
      <img src={"/images/registration_closed.svg"} alt="" height="200" />
      <p className="fw-700 mt-24 rsvp-state-card-title">
        This resource is currently unavailable
      </p>
      <p className="mt-16 medium-text">
        Make sure to check out one of the many other resources
      </p>
      <PageLink
        className="btn btn-accent btn-medium mt-32 inline-flex"
        params={{ chabadHouseSlug }}
        style={{ padding: "0 16px" }}
        to={Pages.rsvp.events}
      >
        View all resources
      </PageLink>
    </div>
  </div>
);
