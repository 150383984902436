import React from "react";
import AccountPageWrapper from "./components/AccountPageWrapper";
import Loader from "../../components/Loader";
import ValidatedInput from "../../components/ValidatedInput";
import {
  handleErrorMessage,
  handleInputState,
  handlePreventDefault,
  Navigation,
  validatePassword,
} from "../../lib";
import Pages from "../../pages";

export default class ResetPass extends React.PureComponent {
  state = {
    confirmNewPassword: "",
    currentPassword: "",
    newPassword: "",
    showFormValidation: false,
    submitFormAttempted: false,
  };

  isIncompleteResetPass = () => {
    const { confirmNewPassword, currentPassword, newPassword } = this.state;

    return (
      !currentPassword ||
      !newPassword ||
      !confirmNewPassword ||
      !validatePassword(currentPassword) ||
      confirmNewPassword !== newPassword
    );
  };

  onChangeInput = handleInputState(this);

  onSubmit = handlePreventDefault(() => {
    this.setState(
      {
        showFormValidation: true,
        submitFormAttempted: true,
      },
      async () => {
        if (this.isIncompleteResetPass()) {
          return;
        }

        const { currentPassword, newPassword } = this.state;
        await this.props.actions.doResetPass(currentPassword, newPassword);

        if (!this.props.error) {
          Navigation.go(Pages.account.login.path, { resetPassRedirect: true });
        } else {
          this.setState({
            confirmNewPassword: "",
            currentPassword: "",
            newPassword: "",
            showFormValidation: false,
          });
        }
      },
    );
  });

  reloadForm() {
    this.setState({ formSubmitted: false });
  }

  render() {
    const { loading, error } = this.props;
    const {
      confirmNewPassword,
      currentPassword,
      newPassword,
      showFormValidation,
      submitFormAttempted,
    } = this.state;

    return (
      <AccountPageWrapper>
        <h1 className="uppercase-text fw-700 large-text text-center mb-40">
          Reset Your Password
        </h1>
        <form className="account-form">
          <ValidatedInput
            input={<input type="password" />}
            label="Current Password"
            name="currentPassword"
            onChange={this.onChangeInput}
            required={true}
            showRequired={showFormValidation}
            value={currentPassword}
          />
          <ValidatedInput
            input={<input type="password" />}
            label="New Password"
            name="newPassword"
            onChange={this.onChangeInput}
            required={true}
            showRequired={showFormValidation}
            showValidation={showFormValidation}
            validate={(pw) => validatePassword(pw)}
            validationMessage="Password must be at least 8 characters long, and must contain an uppercase and lowercase letter, a digit, and a special character."
            value={newPassword}
          />
          <ValidatedInput
            input={<input type="password" />}
            label="Confirm New Password"
            name="confirmNewPassword"
            onChange={this.onChangeInput}
            required={true}
            showRequired={showFormValidation}
            showValidation={showFormValidation}
            validate={(pwConfirmation, pw) => pwConfirmation === pw}
            validationMessage="Passwords do not match"
            validationValue={newPassword}
            value={confirmNewPassword}
          />

          {!loading ? (
            <React.Fragment>
              <button
                className="btn btn-large btn-fullWidth btn-accent mt-16"
                onClick={this.onSubmit}
                type="submit"
              >
                Reset Password
              </button>

              <div className="error-message-container mt-4">
                <span className="error-message center">
                  {showFormValidation && this.isIncompleteResetPass()
                    ? "Please complete required/incomplete fields"
                    : !!error && submitFormAttempted
                    ? handleErrorMessage(error)
                    : ""}
                </span>
              </div>
            </React.Fragment>
          ) : (
            <div className="btn-large-loader">
              <Loader />
            </div>
          )}
        </form>
      </AccountPageWrapper>
    );
  }
}
