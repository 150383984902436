import React from "react";
import MoreUpcomingEventsList from "./registrationCompleted/MoreUpcomingEventsList";

export default function EventRegistrationCancelled(props) {
  const { showUpcomingEvents = true, registrationPaymentAmount } = props;

  return (
    <div className="full-width">
      <div className="card flat-card flex flex-align-center flex-justify-center rsvp-state-card">
        <div className="text-center">
          <img src="/images/registration_complete.svg" alt="" height="240" />
          <p className="fw-700 mt-24 rsvp-state-card-title">
            You successfully cancelled your reservation
          </p>
          {registrationPaymentAmount && (
            <p className="medium-text mt-16" style={{ color: "#808080" }}>
              Your registration fee of ${registrationPaymentAmount} was
              refunded.
            </p>
          )}
        </div>
      </div>
      {showUpcomingEvents && <MoreUpcomingEventsList {...props} />}
    </div>
  );
}
