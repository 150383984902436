import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { ActivityActions } from "../../state/activity/actions";
import Home from "./Home";

export default connect(
  function mapState(state) {
    return {
      lastLogin: state.activity.login && state.activity.login.lastLoginAt,
      user: state.auth.person,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          getActivity: ActivityActions.getActivity,
        },
        dispatch,
      ),
    };
  },
)(Home);
