import React from "react";
import Modal from "../../../../../components/Modal";
import { Navigation, PageLink } from "../../../../../lib";
import Pages from "../../../../../pages";

export default class UpdateCampusModal extends React.Component {
  render() {
    const {
      campusName,
      chabadHouseName,
      chabadHouseSlug,
      confirmUpdateCampus,
      show,
    } = this.props;

    return (
      <Modal className="large-modal" show={show}>
        <p className="large-text fw-700 mb-16">Campus Update Required</p>
        <div className="line-height-double">
          <p className="mb-8">
            Registration for this event is only open to students attending
            campuses serviced by {chabadHouseName || "the event Chabad House"}.
          </p>
          <p className="mb-8">
            By registering for this event, the school in your account profile
            will be automatically set to {campusName}.
          </p>
          <p>
            You can{" "}
            <PageLink
              className="link-text"
              to={Pages.profile.studentProfile}
              state={{ previousLocationPath: Navigation.location.pathname }}
            >
              view your profile
            </PageLink>{" "}
            to see your current school setting and update to a different local
            campus.
          </p>
        </div>
        <div className="modal-btns flex-align-center">
          <PageLink
            className="link-text uppercase-text mr-16"
            params={{ chabadHouseSlug }}
            to={Pages.rsvp.events}
          >
            Back to all events
          </PageLink>
          <button
            className="btn btn-accent btn-medium"
            onClick={confirmUpdateCampus}
            style={{ padding: "0 16px" }}
          >
            Yes, Update my Campus
          </button>
        </div>
      </Modal>
    );
  }
}
