import React, { useState } from "react";
import { CardElement } from "react-stripe-elements";
import ValidatedInput from "./ValidatedInput";

export default function StripeCardElement(props) {
  const { onComplete, submitAttempted } = props;
  const [cardIsComplete, setCardIsComplete] = useState(false);

  return (
    <div
      className={`stripe-card-element ${
        !cardIsComplete && submitAttempted && "error"
      }`}
    >
      <CardElement
        onChange={({ complete }) => {
          setCardIsComplete(complete);
          onComplete(complete);
        }}
      />
    </div>
  );
}

export function StripeCardElementWithName(props) {
  const {
    onComplete,
    cardHolderFullName,
    onChangeName,
    isNameRequired,
    submitAttempted,
  } = props;

  return (
    <div className="stripe-info-container">
      <StripeCardElement
        onComplete={onComplete}
        submitAttempted={submitAttempted}
      />
      <div className="flex flex-align-center mobile-block mt-16">
        <div className="accent-text-dark nowrap-text mr-8 mobile-mb-8">
          Cardholder Full Name
        </div>
        <ValidatedInput
          autoComplete="cc-name"
          className="custom-input full-width"
          name="cardHolderFullName"
          onChange={(e) => onChangeName(e.target.name, e.target.value)}
          required={isNameRequired}
          showRequired={submitAttempted && isNameRequired}
          type="text"
          value={cardHolderFullName}
        />
      </div>
    </div>
  );
}
