import { AuthRequest } from "../../lib";

export const RecoverPassActions = {
  RECOVER_PASS_ERROR: "RECOVER_PASS_ERROR",
  RECOVER_PASS_COMPLETE: "RECOVER_PASS_COMPLETE",
  SET_RECOVER_PASS_LOADING: "SET_RECOVER_PASS_LOADING",

  doRecoverPass(recoveryEmail) {
    return async function doingRecoverPass(dispatch, getState) {
      dispatch(RecoverPassActions.setRecoverPassLoading(true));

      const response = await AuthRequest.post("Account/ForgotPw", {
        clientPortal: "Student",
        email: recoveryEmail,
      }).catch((err) => {
        dispatch(RecoverPassActions.recoverPassError(err));
        return undefined;
      });

      //TODO - when just return what happens?  will never stop loading...???
      if (!response) {
        return;
      }

      dispatch(RecoverPassActions.recoverPassComplete());
    };
  },
  setRecoverPassLoading(payload) {
    return {
      type: RecoverPassActions.SET_RECOVER_PASS_LOADING,
      payload,
    };
  },
  recoverPassComplete(payload) {
    return {
      type: RecoverPassActions.RECOVER_PASS_COMPLETE,
      payload,
    };
  },
  recoverPassError(payload) {
    return {
      type: RecoverPassActions.RECOVER_PASS_ERROR,
      payload,
    };
  },
};
