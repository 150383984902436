import React, { useState } from "react";
import { PageLink } from "../../../../../lib";
import Pages from "../../../../../pages";
import ConfirmationModal from "../../../../../components/ConfirmationModal";
import { RsvpRegistrationStatuses } from "../../../../../lib/RsvpHelpers";

export default function EventRegistrationClosed({
  chabadHouseSlug,
  registrationStatus,
  eventRegistrationId,
  actions,
  onCancelRegistration,
  isRegistrationClosed,
}) {
  const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const isStudentRegistered =
    registrationStatus === RsvpRegistrationStatuses.Registered;
  const isStudentWaitlisted =
    registrationStatus === RsvpRegistrationStatuses.Waitlisted;
  const isStudentPendingPayment =
    registrationStatus === RsvpRegistrationStatuses.PendingPayment;

  const onConfirmCancel = async () => {
    setLoading(true);
    setError(null);
    setShowCancelConfirmModal(false);
    const response = await actions.deleteEventRegistration(eventRegistrationId);
    setLoading(false);
    if (response.error) {
      setError("An error occured while canceling your registration");
    } else {
      onCancelRegistration();
    }
  };

  return (
    <div className="card flat-card full-width flex flex-align-center flex-justify-center text-center rsvp-state-card">
      <div className="text-center">
        {isStudentRegistered && (
          <div>
            <img src="/images/registration_complete.svg" alt="" height="200" />
            <p className="fw-700 mt-24 rsvp-state-card-title">
              You have already registered for this event
            </p>
          </div>
        )}
        {isStudentWaitlisted && (
          <div>
            <img src="/images/registration_complete.svg" alt="" height="200" />
            <p className="fw-700 mt-24 rsvp-state-card-title">
              You are waitlisted for this event
            </p>
            <p className="mt-16 large-text">
              We'll be in touch if space becomes available
            </p>
          </div>
        )}
        {isStudentPendingPayment && (
          <div>
            <img src="/images/registration_complete.svg" alt="" height="200" />
            <p className="fw-700 mt-24 rsvp-state-card-title">
              Pending Payment
            </p>
            <p className="mt-16 large-text">
              It looks like you've already tried registering for this event
              using SMS.
            </p>
            ›
            <p className="mt-8 large-text">
              To complete your registration, text MYEVENTS.
            </p>
          </div>
        )}
        {isRegistrationClosed && (
          <div>
            <img src="/images/registration_closed.svg" alt="" height="200" />
            <p className="fw-700 mt-24 rsvp-state-card-title">
              Registration for this event is closed
            </p>
          </div>
        )}

        <p className="mt-32 medium-text">
          Make sure to check out one of the many other events
        </p>
        <div>
          <PageLink
            className="btn btn-accent btn-medium mt-16 inline-flex"
            params={{ chabadHouseSlug }}
            style={{ padding: "0 16px" }}
            to={Pages.rsvp.events}
            disabled={loading}
          >
            View all events
          </PageLink>
        </div>
        {(isStudentRegistered || isStudentWaitlisted) && (
          <div>
            <button
              className="btn btn-light btn-medium mt-32 inline-flex"
              onClick={() => setShowCancelConfirmModal(true)}
              disabled={loading}
            >
              {isStudentRegistered
                ? "Cancel Registration"
                : "Take Me Off The Waitlist"}
              {loading && "..."}
            </button>

            {showCancelConfirmModal && (
              <ConfirmationModal
                title="Confirm Cancellation"
                cancel={() => setShowCancelConfirmModal(false)}
                confirm={onConfirmCancel}
                show={showCancelConfirmModal}
                message={
                  isStudentRegistered
                    ? "Are you sure you want to cancel your registration?"
                    : "Are you sure you want to be removed from the waitlist?"
                }
              />
            )}
          </div>
        )}
        {error && (
          <div className="flex flex-justify-center mt-16">
            <div className="error-message">{error}</div>
          </div>
        )}
      </div>
    </div>
  );
}
