import React, { useCallback } from "react";
import Checkbox from "../../../../components/form/Checkbox";
import Select from "../../../../components/form/Select";
import ValidatedInput from "../../../../components/ValidatedInput";
import {
  formatCurrency,
  getFullNameDisplay,
  Navigation,
  PageLink,
} from "../../../../lib";
import Pages from "../../..";

function Overview(props) {
  const {
    onChange,
    registration: {
      didConfirmInfo,
      profileDetails: { tShirtSize },
      trackID,
    },
    submitAttempted,
    sys: { tShirtSizes = [] },
    trip: {
      campusName,
      event: { isTShirtSizeRequired, tracks: allEventTracks },
      isStudentIneligibleForAllTracks,
      programScheduleName,
      shliachFirstName,
      shliachLastName,
      shliachTitle,
      shluchaFirstName,
      shluchaLastName,
      shluchaTitle,
      specialInstructions,
      tracks,
    },
  } = props;

  const showRequiredConfirmCampusError = submitAttempted && !didConfirmInfo;
  const showRequiredTrackError =
    !isStudentIneligibleForAllTracks && submitAttempted && !trackID;
  const isMultiTrackEvent =
    allEventTracks &&
    allEventTracks.length > 1 &&
    !allEventTracks.some((t) => t.regionIDs.length);

  const onToggleTrack = useCallback(
    (track) => {
      const { id: trackId, isBeforeEarlyBirdDeadline } = track;
      onChange("trackID", trackId, {
        didUseEarlyBird: isBeforeEarlyBirdDeadline,
      }); //update didUseEarlyBird based on selected track early bird deadline
    },
    [onChange],
  );

  return (
    <>
      <div className="trip-form-section-inner">
        <p className="fw-900 mb-8" style={{ fontSize: "22px" }}>
          Overview
        </p>
      </div>

      <div className="trip-form-section-inner">
        <div>
          <p
            className={`fw-700 medium-text mt-16 mb-8 ${
              showRequiredConfirmCampusError ? "error-text" : ""
            }`}
          >
            Confirm School
          </p>
          <p className="accent-text-dark line-height-double">
            If the information listed below is incorrect, please update the
            school in your profile{" "}
            <PageLink
              className="link-text"
              to={Pages.profile.studentProfile}
              state={{
                previousLocationPageType: Navigation.page.type,
                previousLocationPath: Navigation.location.pathname,
              }}
            >
              here
            </PageLink>
            .
          </p>
        </div>
        <p className="fw-700 mt-16">{campusName}</p>
        <p className="accent-text-dark mt-8">
          {[
            getFullNameDisplay(shliachFirstName, shliachLastName, shliachTitle),
            getFullNameDisplay(shluchaFirstName, shluchaLastName, shluchaTitle),
          ].join(" & ")}
        </p>

        <Checkbox
          checked={didConfirmInfo}
          className="line-height-large mt-24"
          error={showRequiredConfirmCampusError}
          label={`I confirm that the above Chabad and school information is correct for my ${
            programScheduleName || "trip"
          } registration.`}
          name="didConfirmInfo"
          onChange={onChange}
        />
      </div>

      <div className="trip-form-section-inner">
        {isMultiTrackEvent && (
          <React.Fragment>
            <div>
              <p
                className={`fw-700 medium-text mt-16 mb-8 ${
                  showRequiredTrackError ? "error-text" : ""
                }`}
              >
                Choose Track
              </p>
              <p className="accent-text-dark line-height-double">
                If your desired track is not listed, please confirm that you
                have the correct date of birth set in your profile.
              </p>
            </div>
            {showRequiredTrackError && (
              <p className="error-text mt-16 mb-8 ml-8">
                * Please select a track for registration
              </p>
            )}
          </React.Fragment>
        )}

        {isStudentIneligibleForAllTracks ? (
          <p className="error-text mt-24 line-height-double">
            You are not eligible for {programScheduleName || "this trip"},
            likely due to age restrictions. Please check{" "}
            <PageLink
              className="link-text"
              to={Pages.profile.studentProfile}
              state={{
                previousLocationPageType: Navigation.page.type,
                previousLocationPath: Navigation.location.pathname,
              }}
            >
              your profile
            </PageLink>{" "}
            to confirm that you have the correct date of birth listed.
          </p>
        ) : (
          <div className="mt-24">
            {tracks &&
              tracks.map((track) => {
                const {
                  earlyBirdPrice,
                  id,
                  isBeforeEarlyBirdDeadline,
                  isFullToCapacity,
                  regularPrice,
                  trackDescription,
                  trackName,
                } = track;
                const isEligibleForEarlyBird =
                  !!earlyBirdPrice && isBeforeEarlyBirdDeadline;

                return (
                  <div
                    key={id}
                    className={
                      isMultiTrackEvent ? "card track-card" : "relative"
                    }
                  >
                    <div className={isFullToCapacity ? "disabled-track" : ""}>
                      {isMultiTrackEvent ? (
                        <div className="radio-button-container mb-8">
                          <div className="flex">
                            <input
                              type="checkbox"
                              id={`track-${id}`}
                              checked={trackID === id}
                              onChange={() => onToggleTrack(track)}
                              disabled={isFullToCapacity}
                            />
                            <label
                              className={`medium-text fw-700 ${
                                isFullToCapacity ? "disabled" : ""
                              }`}
                              htmlFor={`track-${id}`}
                            >
                              {trackName}
                            </label>
                          </div>
                        </div>
                      ) : (
                        <p className="medium-text fw-700 mb-16">{trackName}</p>
                      )}
                      {trackDescription && (
                        <p className="accent-text-dark mb-16">
                          {trackDescription}
                        </p>
                      )}
                      <div
                        className="flex flex-justify-space mb-4"
                        style={{ maxWidth: "280px" }}
                      >
                        <p className="fw-700">Registration Fee</p>
                        <p
                          className={
                            isEligibleForEarlyBird ? "strike-through" : ""
                          }
                        >
                          {formatCurrency(regularPrice, "$")}
                        </p>
                      </div>
                      {isEligibleForEarlyBird && (
                        <div
                          className="flex flex-justify-space"
                          style={{ maxWidth: "280px" }}
                        >
                          <p className="fw-700 error-text">
                            Early Bird Special
                          </p>
                          <p className="error-text">
                            {formatCurrency(earlyBirdPrice, "$")}
                          </p>
                        </div>
                      )}
                    </div>
                    {isFullToCapacity &&
                      (isMultiTrackEvent ? (
                        <p className="isFullError">Sorry, this track is full</p>
                      ) : (
                        <p className="isFullError" style={{ bottom: "auto" }}>
                          Sorry, {programScheduleName || "this trip"} is filled
                          to capacity and you cannot register at this time
                        </p>
                      ))}
                  </div>
                );
              })}
          </div>
        )}

        {specialInstructions && (
          <p className="accent-text-dark mt-24">{specialInstructions}</p>
        )}
      </div>

      {isTShirtSizeRequired && (
        <div className="trip-form-section-inner">
          <p className="fw-700 medium-text mt-16 mb-16">
            Choose Preferred T-shirt Size
          </p>
          <ValidatedInput
            input={
              <Select
                className="full-width"
                options={tShirtSizes?.map((s) => ({
                  label: s.displayValue,
                  value: s.enumValue,
                }))}
                placeholder="Select Size"
              />
            }
            label="Select Size"
            name="profileDetails.tShirtSize"
            onChange={onChange}
            required={true}
            showRequired={submitAttempted}
            value={tShirtSize}
          />
        </div>
      )}
    </>
  );
}

export default React.memo(Overview);
