import React from "react";
import Modal from "./Modal";
import Loader from "./Loader";

export default class ConfirmationModal extends React.Component {
  render() {
    const {
      cancel,
      cancelText,
      confirm,
      confirmText,
      errorMessage,
      message,
      noConfirm,
      loading,
      show,
      title,
    } = this.props;

    return (
      <Modal show={show}>
        <p className="large-text fw-700 mb-16">{title || "Confirm"}</p>
        <div className="accent-text" style={{ whiteSpace: "pre-wrap" }}>
          {message}
        </div>
        {loading ? (
          <div className="modal-btns-loader">
            <Loader />
          </div>
        ) : (
          <div className="modal-btns relative">
            <p className="link-text uppercase-text" onClick={cancel}>
              {cancelText || "Cancel"}
            </p>
            {!noConfirm && (
              <p className="link-text uppercase-text ml-40" onClick={confirm}>
                {confirmText || "Confirm"}
              </p>
            )}

            {errorMessage && (
              <span
                className="error-text"
                style={{
                  position: "absolute",
                  whiteSpace: "nowrap",
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  bottom: "-20px",
                }}
              >
                {errorMessage}
              </span>
            )}
          </div>
        )}
      </Modal>
    );
  }
}
