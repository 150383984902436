import React from "react";
import Checkout from "./checkout/Checkout";

function Payment(props) {
  const {
    cancelRegistration,
    isShliachView,
    onChange,
    registration,
    registrationValidation,
    setSubmitAttempted,
    submitAttempted,
    submitRegistration,
    sys: { countries = [] },
    trip,
    ui: { isPhone },
  } = props;

  return (
    <>
      <p className="section-title">Payment</p>
      <Checkout
        cancelRegistration={cancelRegistration}
        isPhone={isPhone}
        isShliachView={isShliachView}
        onChange={onChange}
        registration={registration}
        registrationValidation={registrationValidation}
        setSubmitAttempted={setSubmitAttempted}
        submitAttempted={submitAttempted}
        submitRegistration={submitRegistration}
        systemCountries={countries}
        trip={trip}
      />
    </>
  );
}

export default React.memo(Payment);
