import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RsvpActions } from "../../state";
import RsvpResourceRegistrationContainer from "./RsvpResourceRegistrationContainer";

export default connect(
  function mapState(state) {
    return {
      ...state.rsvp,
      personId: state.auth.person?.id,
      sys: state.sys,
      user: state.auth.person,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...RsvpActions,
        },
        dispatch,
      ),
    };
  },
)(RsvpResourceRegistrationContainer);
