import React from "react";
import InfiniteScrollContainer from "../../../../../../components/InfiniteScrollContainer";
import Loader from "../../../../../../components/Loader";
import UpcomingEventCard from "../../../events/UpcomingEventCard";
import UpcomingEventRegistration from "./UpcomingEventRegistration";
import { PageLink, PageURL } from "../../../../../../lib";
import Pages from "../../../../../../pages";

export default class MoreUpcomingEventsList extends React.PureComponent {
  state = {
    page: 1,
    resultsView: 12,
  };

  componentDidMount() {
    this.loadEventSchedules();
  }

  componentWillUnmount() {
    this.props.resetEventSchedules();
  }

  loadEventSchedules = async (loadMore) => {
    let { page, resultsView } = this.state;

    if (loadMore) {
      page = page + 1;
      this.setState({ page });
    }

    const { chabadHouseSlug, getEventSchedules } = this.props;
    await getEventSchedules(chabadHouseSlug, page, resultsView, true);
  };

  render() {
    const {
      chabadHouseSlug,
      completedEventRegistration,
      eventSchedules: {
        data: {
          results: eventSchedules,
          numberOfRows: totalEventSchedules,
        } = {},
        error,
        loading,
      },
    } = this.props;

    const hasMore =
      eventSchedules &&
      totalEventSchedules > 0 &&
      eventSchedules.length > 0 &&
      totalEventSchedules > eventSchedules.length;

    const cannotDisplayUpcomingEvents =
      !completedEventRegistration || !completedEventRegistration.id || error;
    //checking completedEventRegistration for extra safety to ensure that we have the necessary details, but this prop should always be accessible

    return cannotDisplayUpcomingEvents ? (
      <div className="mt-24 text-center">
        {error && (
          <p className="large-text mb-16">
            We couldn't load upcoming events at this time
          </p>
        )}
        <p className="fw-700 xl-text">
          Check out more great upcoming events{" "}
          <PageLink
            className="link-text"
            params={{ chabadHouseSlug }}
            to={Pages.rsvp.events}
          >
            here
          </PageLink>
        </p>
      </div>
    ) : !loading && !totalEventSchedules ? (
      <p className="mt-24 fw-700 xl-text">
        Stay tuned for more great upcoming events...
      </p>
    ) : (
      <InfiniteScrollContainer
        className="mt-24"
        onScrollEnd={
          loading || !hasMore ? null : () => this.loadEventSchedules(true)
        }
        scrollEndOffset={100}
      >
        <p className="fw-700 xxl-text mb-16 mobile-text-center">
          More great upcoming events
        </p>
        <div className="rsvp-events-grid">
          {eventSchedules &&
            eventSchedules.map((eventSchedule) => (
              <UpcomingEventCard
                eventRegistrationComponent={
                  <UpcomingEventRegistration
                    completedEventRegistration={completedEventRegistration}
                    eventScheduleID={eventSchedule.eventScheduleID}
                    isFullyBooked={eventSchedule.isFullyBooked}
                    eventPageUrl={PageURL.to(
                      Pages.rsvp.register,
                      {
                        chabadHouseSlug,
                        eventScheduleId: eventSchedule.eventScheduleID,
                      },
                      null,
                    )}
                  />
                }
                eventSchedule={eventSchedule}
                key={eventSchedule.eventScheduleID}
              />
            ))}
        </div>
        {loading ? (
          <div className="mt-16">
            <Loader />
          </div>
        ) : (
          hasMore && (
            <button
              className="btn btn-accent btn-large mt-16"
              onClick={() => this.loadEventSchedules(true)}
              style={{
                margin: "48px auto 8px",
                display: "block",
                minWidth: 160,
              }}
            >
              Load More
            </button>
          )
        )}
      </InfiniteScrollContainer>
    );
  }
}
