import React from "react";
import AccountPageWrapper from "./components/AccountPageWrapper";
import GoogleIdentitySignin from "./components/GoogleIdentitySignin";
import Loader from "../../components/Loader";
import LoginRegisterHeader from "./components/LoginRegisterHeader";
import ReCAPTCHA from "react-google-recaptcha";
import ValidatedInput from "../../components/ValidatedInput";

import {
  handleErrorMessage,
  handleInputState,
  handlePreventDefault,
  Navigation,
  PageURL,
  validateEmail,
  validatePassword,
} from "../../lib";
import { withAppInsights } from "../../lib/AppInsights";
import Pages from "../../pages";

const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

class Register extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      confirmPassword: "",
      email: "",
      error: "",
      firstName: "",
      lastName: "",
      password: "",
      recaptchaLoaded: false,
      recaptchaError: false,
      showFormValidation: false,
      submitFormAttempted: false,
    };

    this.handleChangeInput = handleInputState(this);

    this._recaptchaRef = React.createRef();
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  isIncompleteRegistration = () => {
    const { confirmPassword, email, firstName, lastName, password } =
      this.state;

    return (
      !firstName ||
      !lastName ||
      !email ||
      !password ||
      !validateEmail(email) ||
      password !== confirmPassword ||
      !validatePassword(password)
    );
  };

  onChange = (name, value) => this.setState({ [name]: value });

  onChangeInput = (e) => {
    this.handleChangeInput(e);

    if (this.state.error) {
      this.setState({ error: null });
    }
  };

  onBasicRegistration = handlePreventDefault(() => {
    this.setState(
      {
        error: null,
        showFormValidation: true,
        submitFormAttempted: true,
      },
      async () => {
        if (this.isIncompleteRegistration()) {
          return;
        }

        this._recaptchaRef.current.reset();
        this._recaptchaRef.current.execute();
      },
    );
  });

  onCaptchaExecution = async (recaptcha) => {
    const {
      pageRoute: {
        query: { after },
      },
    } = this.props;
    const { email, firstName, lastName, password } = this.state;

    await this.props.actions.doBasicRegistration({
      email,
      firstName,
      lastName,
      password,
      recaptcha,
    });

    if (!this.props.error && this.props.data) {
      const registrationPayload = this.props.data;
      const confirmRegistrationURL = PageURL.to(
        Pages.account.confirm.path,
        null,
        {
          after,
          id: registrationPayload.id,
        },
      );
      Navigation.go(confirmRegistrationURL, {
        confirmRegistrationEmail: registrationPayload.email,
      });
    } else {
      this.setState({
        confirmPassword: "",
        error: this.props.error,
        password: "",
        showFormValidation: false,
      });
    }
  };

  // onFacebookRegistration = async fbUser => {
  //   await this.props.actions.doFacebookRegistration(fbUser);

  //   if (this.props.error) {
  //     this.setState({
  //       error: this.props.error
  //     });
  //   }
  // };

  onGoogleRegistration = () => {
    if (this.props.error) {
      this.setState({
        error: this.props.error,
        showFormValidation: false,
        submitFormAttempted: true,
      });
    }
  };

  render() {
    const {
      pageRoute: { query },
      loading,
    } = this.props;

    const {
      confirmPassword,
      email,
      error,
      firstName,
      lastName,
      password,
      recaptchaLoaded,
      recaptchaError,
      showFormValidation,
      submitFormAttempted,
    } = this.state;

    return (
      <AccountPageWrapper>
        <LoginRegisterHeader pageLinkQuery={query} />

        <div className="login-btns">
          <GoogleIdentitySignin
            onSubmit={this.onGoogleRegistration}
            register={true}
          />
        </div>

        <p className="mt-24 mb-24 text-center medium-text">
          or sign up using your email address
        </p>
        <form className="account-form">
          <div className="flex flex-align-center flex-justify-space name-input-containers mobile-block">
            <ValidatedInput
              label="First Name"
              name="firstName"
              onChange={this.onChangeInput}
              required={true}
              showRequired={showFormValidation}
              value={firstName}
            />
            <ValidatedInput
              label="Last Name"
              name="lastName"
              onChange={this.onChangeInput}
              required={true}
              showRequired={showFormValidation}
              value={lastName}
            />
          </div>
          <ValidatedInput
            label="Email"
            name="email"
            onChange={this.onChangeInput}
            required={true}
            showRequired={showFormValidation}
            showValidation={showFormValidation}
            validate={(email) => validateEmail(email)}
            validationMessage="Invalid email address"
            value={email}
          />
          <ValidatedInput
            input={<input type="password" />}
            label="Password"
            name="password"
            onChange={this.onChangeInput}
            required={true}
            showRequired={showFormValidation}
            showValidation={showFormValidation}
            validate={(pw) => validatePassword(pw)}
            validationMessage="Password must be at least 8 characters long, and must contain an uppercase and lowercase letter, a digit, and a special character."
            value={password}
          />
          <ValidatedInput
            input={<input type="password" />}
            label="Confirm Password"
            name="confirmPassword"
            onChange={this.onChangeInput}
            required={true}
            showRequired={showFormValidation}
            showValidation={showFormValidation}
            validate={(pwConfirmation, pw) => pwConfirmation === pw}
            validationMessage="Passwords do not match"
            validationValue={password}
            value={confirmPassword}
          />
          <ReCAPTCHA
            asyncScriptOnLoad={({ loaded: recaptchaLoaded }) => {
              if (this._isMounted) {
                this.setState({ recaptchaLoaded });
              }
            }}
            badge="bottomleft"
            onChange={this.onCaptchaExecution}
            onErrored={() => {
              if (this._isMounted) {
                this.setState({ recaptchaError: true });
              }
            }}
            ref={this._recaptchaRef}
            sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
            size="invisible"
          />

          {!loading ? (
            <React.Fragment>
              <button
                className="btn btn-large btn-fullWidth btn-accent mt-16"
                disabled={!recaptchaLoaded || recaptchaError}
                onClick={this.onBasicRegistration}
                type="submit"
              >
                Create Account
              </button>

              <div className="error-message-container mt-4">
                <span className="error-message center">
                  {showFormValidation && this.isIncompleteRegistration()
                    ? "Please complete required/incomplete fields"
                    : !!error && submitFormAttempted
                    ? handleErrorMessage(
                        error,
                        "There was an error creating your account. Please try again.",
                      )
                    : ""}
                </span>
              </div>

              {!!recaptchaError && (
                <div className="error-message-container mt-4">
                  <span className="error-message center">
                    Recaptcha validation failed. Please try reloading the page
                    before attempting submission.
                  </span>
                </div>
              )}
            </React.Fragment>
          ) : (
            <div className="btn-large-loader">
              <Loader />
            </div>
          )}
        </form>
      </AccountPageWrapper>
    );
  }
}

export default withAppInsights(Register);
