import { TripActions } from "./actions";
import { AuthActions } from "../auth/actions";

export const TripState = {
  name: "trip",
  persist: false,
  defaults: {
    findMyTrip: {
      data: {},
      error: undefined,
      loading: false,
      success: undefined,
    },
    trip: {
      data: {},
      error: undefined,
      loading: false,
      success: undefined,
    },
    // registration: {
    //   data: {},
    //   error: undefined,
    //   loading: false,
    //   success: undefined
    // },
    register: {
      error: undefined,
      loading: false,
      success: undefined,
    },
  },
  handlers: {
    [TripActions.SET_FIND_TRIP_LOADING]: function (state, action) {
      return {
        ...state,
        findMyTrip: {
          ...state.findMyTrip,
          loading: !!action.payload,
        },
      };
    },
    [TripActions.FIND_TRIP_COMPLETE]: function (state, action) {
      return {
        ...state,
        findMyTrip: {
          ...state.findMyTrip,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [TripActions.FIND_TRIP_ERROR]: function (state, action) {
      return {
        ...state,
        findMyTrip: {
          ...state.findMyTrip,
          loading: false,
          success: false,
          error: action.payload.response,
          data: {},
        },
      };
    },

    [TripActions.SET_GET_TRIP_LOADING]: function (state, action) {
      return {
        ...state,
        trip: {
          ...state.trip,
          loading: !!action.payload,
        },
      };
    },
    [TripActions.GET_TRIP_COMPLETE]: function (state, action) {
      return {
        ...state,
        trip: {
          ...state.trip,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [TripActions.GET_TRIP_ERROR]: function (state, action) {
      return {
        ...state,
        trip: {
          ...state.trip,
          loading: false,
          success: false,
          error: action.payload.response,
          data: {},
        },
      };
    },

    // [TripActions.SET_GET_TRIP_REGISTRATION_LOADING]: function (state, action) {
    //   return {
    //     ...state,
    //     registration: {
    //       ...state.registration,
    //       loading: !!action.payload
    //     }
    //   };
    // },
    // [TripActions.GET_TRIP_REGISTRATION_COMPLETE]: function (state, action) {
    //   return {
    //     ...state,
    //     registration: {
    //       ...state.registration,
    //       loading: false,
    //       success: true,
    //       error: undefined,
    //       data: action.payload
    //     }
    //   };
    // },
    // [TripActions.GET_TRIP_REGISTRATION_ERROR]: function (state, action) {
    //   return {
    //     ...state,
    //     registration: {
    //       ...state.registration,
    //       loading: false,
    //       success: false,
    //       error: action.payload.response,
    //       data: {}
    //     }
    //   };
    // },

    [TripActions.SET_TRIP_REGISTRATION_LOADING]: function (
      state,
      action,
    ) {
      return {
        ...state,
        register: {
          ...state.register,
          loading: !!action.payload,
        },
      };
    },
    [TripActions.TRIP_REGISTRATION_COMPLETE]: function (state, action) {
      return {
        ...state,
        register: {
          ...state.register,
          loading: false,
          success: true,
          error: undefined,
        },
      };
    },
    [TripActions.TRIP_REGISTRATION_ERROR]: function (state, action) {
      return {
        ...state,
        register: {
          ...state.register,
          loading: false,
          success: false,
          error: action.payload.response,
        },
      };
    },
    [AuthActions.DO_SIGNOUT]: function (state, action) {
      return TripState.defaults;
    },
  },
};
