import { ProfileActions } from "./actions";
import { AuthActions } from "../auth/actions";

export const ProfileState = {
  name: "profile",
  persist: false,
  defaults: {
    get: {
      data: {},
      error: undefined,
      loading: false,
      success: undefined,
    },
    settings: {
      data: [],
      error: undefined,
      loading: false,
      success: undefined,
    },
    submit: {
      error: undefined,
      loading: false,
      success: undefined,
    },
  },
  handlers: {
    [ProfileActions.SET_GET_PROFILE_LOADING]: function (state, action) {
      return {
        ...state,
        get: {
          ...state.get,
          loading: !!action.payload,
        },
      };
    },
    [ProfileActions.GET_PROFILE_COMPLETE]: function (state, action) {
      return {
        ...state,
        get: {
          ...state.get,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [ProfileActions.GET_PROFILE_ERROR]: function (state, action) {
      return {
        ...state,
        get: {
          ...state.get,
          loading: false,
          success: false,
          error: action.payload.response,
          data: {},
        },
      };
    },
    [ProfileActions.SET_GET_PROFILE_SETTINGS_LOADING]: function (
      state,
      action,
    ) {
      return {
        ...state,
        settings: {
          ...state.settings,
          loading: !!action.payload,
        },
      };
    },
    [ProfileActions.GET_PROFILE_SETTINGS_COMPLETE]: function (state, action) {
      return {
        ...state,
        settings: {
          ...state.settings,
          loading: false,
          success: true,
          error: undefined,
          data: action.payload,
        },
      };
    },
    [ProfileActions.GET_PROFILE_SETTINGS_ERROR]: function (state, action) {
      return {
        ...state,
        settings: {
          ...state.settings,
          loading: false,
          success: false,
          error: action.payload.response,
          data: [],
        },
      };
    },
    [ProfileActions.SET_SUBMIT_PROFILE_LOADING]: function (state, action) {
      return {
        ...state,
        submit: {
          ...state.submit,
          loading: !!action.payload,
        },
      };
    },
    [ProfileActions.SUBMIT_PROFILE_COMPLETE]: function (state, action) {
      return {
        ...state,
        submit: {
          ...state.submit,
          loading: false,
          success: true,
          error: undefined,
        },
      };
    },
    [ProfileActions.SUBMIT_PROFILE_ERROR]: function (state, action) {
      return {
        ...state,
        submit: {
          ...state.submit,
          loading: false,
          success: false,
          error: action.payload.response,
        },
      };
    },
    [ProfileActions.UPDATE_PROFILE]: function (state, action) {
      return {
        ...state,
        get: {
          ...state.get,
          data: action.payload,
        },
      };
    },
    [AuthActions.DO_SIGNOUT]: function (state, action) {
      return ProfileState.defaults;
    },
  },
};
