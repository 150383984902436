import { AuthRequest } from "../../lib";

export const YourIsraelActions = {
  SET_GET_YOUR_ISRAEL_LOADING: "SET_GET_YOUR_ISRAEL_LOADING",
  GET_YOUR_ISRAEL_COMPLETE: "GET_YOUR_ISRAEL_COMPLETE",
  GET_YOUR_ISRAEL_ERROR: "GET_YOUR_ISRAEL_ERROR",
  UPDATE_YOUR_ISRAEL: "UPDATE_YOUR_ISRAEL",

  GET_COURSE_COMPLETE: "GET_COURSE_COMPLETE",
  GET_COURSE_ERROR: "GET_COURSE_ERROR",
  SET_GET_COURSE_LOADING: "SET_GET_COURSE_LOADING",
  RESET_COURSE: "RESET_COURSE",

  COURSE_ENROLLMENT_COMPLETE: "COURSE_ENROLLMENT_COMPLETE",
  COURSE_ENROLLMENT_ERROR: "COURSE_ENROLLMENT_ERROR",
  SET_COURSE_ENROLLMENT_LOADING: "SET_COURSE_ENROLLMENT_LOADING",
  RESET_COURSE_ENROLLMENT: "RESET_COURSE_ENROLLMENT",

  COURSE_ENROLLMENT_UPDATE_COMPLETE: "COURSE_ENROLLMENT_UPDATE_COMPLETE",
  COURSE_ENROLLMENT_UPDATE_ERROR: "COURSE_ENROLLMENT_UPDATE_ERROR",
  SET_COURSE_ENROLLMENT_UPDATE_LOADING: "SET_COURSE_ENROLLMENT_UPDATE_LOADING",
  RESET_COURSE_ENROLLMENT_UPDATE: "RESET_COURSE_ENROLLMENT_UPDATE",

  COURSE_ENROLLMENT_CANCELLATION_COMPLETE:
    "COURSE_ENROLLMENT_CANCELLATION_COMPLETE",
  COURSE_ENROLLMENT_CANCELLATION_ERROR: "COURSE_ENROLLMENT_CANCELLATION_ERROR",
  SET_COURSE_ENROLLMENT_CANCELLATION_LOADING:
    "SET_COURSE_ENROLLMENT_CANCELLATION_LOADING",
  RESET_COURSE_ENROLLMENT_CANCELLATION: "RESET_COURSE_ENROLLMENT_CANCELLATION",

  getYourIsrael(shliachView) {
    return async (dispatch) => {
      dispatch(YourIsraelActions.setGetYourIsraelLoading(true));

      let error, response, success;
      try {
        const yourIsraelProgramResponse = await AuthRequest.get(
          "EduPrograms/byType/YourIsrael",
        );
        if (yourIsraelProgramResponse && yourIsraelProgramResponse.data) {
          const yourIsraelProgramId = yourIsraelProgramResponse.data.payload.id;
          let yourIsraelReqUrl = `EduStudents/student?eduProgramId=${yourIsraelProgramId}`;
          if (shliachView) {
            yourIsraelReqUrl += `&shliachView=true`;
          }

          response = await AuthRequest.get(yourIsraelReqUrl);
          success = true;
        }
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(YourIsraelActions.getYourIsraelError(error));
        return undefined;
      }

      dispatch(YourIsraelActions.getYourIsraelComplete(response.data.payload));

      return response.data;
    };
  },
  setGetYourIsraelLoading(payload) {
    return {
      type: YourIsraelActions.SET_GET_YOUR_ISRAEL_LOADING,
      payload,
    };
  },
  getYourIsraelComplete(payload) {
    return {
      type: YourIsraelActions.GET_YOUR_ISRAEL_COMPLETE,
      payload,
    };
  },
  getYourIsraelError(payload) {
    return {
      type: YourIsraelActions.GET_YOUR_ISRAEL_ERROR,
      payload,
    };
  },
  updateYourIsrael(payload) {
    return {
      type: YourIsraelActions.UPDATE_YOUR_ISRAEL,
      payload,
    };
  },

  getYourIsraelCourse() {
    return async (dispatch, getState) => {
      dispatch(YourIsraelActions.setGetCourseLoading(true));

      let error, response, success;
      try {
        const {
          yourIsrael: {
            data: { eduChabadHouse, eduProgramID, eduStudentEnrollmentID } = {},
          },
        } = getState().yourIsrael;

        if (eduChabadHouse) {
          //get the your israel course which is going to be the only course set up for the your israel program
          const coursesResponse = await AuthRequest.get(
            `EduPrograms/${eduProgramID}/courses/basic`,
          );
          const yourIsraelCourse =
            coursesResponse &&
            coursesResponse.data &&
            coursesResponse.data.payload[0];
          //get course details (schedules, enrollments) as relevant to the ch/student, and chabad house details
          if (yourIsraelCourse) {
            const [courseResponse, chabadHouseDetailsResponse] =
              await Promise.all([
                AuthRequest.get(
                  `EduStudents/Courses/${yourIsraelCourse.id}?eduChabadHouseEnrollmentID=${eduChabadHouse.id}` +
                    (eduStudentEnrollmentID
                      ? `&eduStudentEnrollmentID=${eduStudentEnrollmentID}`
                      : ""),
                ),
                AuthRequest.get(`EduChabadHouses/${eduChabadHouse.id}/public`),
              ]);

            courseResponse.data.payload.courseLocation =
              chabadHouseDetailsResponse.data.payload;
            response = courseResponse;
          }

          success = true;
        }
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(YourIsraelActions.getCourseError(error));
        return undefined;
      }

      dispatch(YourIsraelActions.getCourseComplete(response.data.payload));

      return response.data.payload;
    };
  },
  setGetCourseLoading(payload) {
    return {
      type: YourIsraelActions.SET_GET_COURSE_LOADING,
      payload,
    };
  },
  getCourseComplete(payload) {
    return {
      type: YourIsraelActions.GET_COURSE_COMPLETE,
      payload,
    };
  },
  getCourseError(payload) {
    return {
      type: YourIsraelActions.GET_COURSE_ERROR,
      payload,
    };
  },
  resetCourse() {
    return {
      type: YourIsraelActions.RESET_COURSE,
    };
  },

  doCourseEnrollment(courseScheduleId) {
    return async (dispatch, getState) => {
      dispatch(YourIsraelActions.setCourseEnrollmentLoading(true));

      let error, response, success, yourIsraelEnrollmentResponse;
      try {
        let {
          yourIsrael: { data: { eduStudentEnrollmentID, eduProgramID } = {} },
        } = getState().yourIsrael;

        //enroll in YI if student is not already enrolled
        if (!eduStudentEnrollmentID) {
          yourIsraelEnrollmentResponse = await AuthRequest.post("EduStudents", {
            eduProgramID,
          });

          if (yourIsraelEnrollmentResponse) {
            eduStudentEnrollmentID =
              yourIsraelEnrollmentResponse.data.payload.id;
          }
        }

        response = await AuthRequest.post("EduStudents/CourseSchedules", {
          eduCourseScheduleID: courseScheduleId,
          eduStudentEnrollmentID,
        });
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(YourIsraelActions.courseEnrollmentError(error));

        //refresh YourIsrael info if student enrolled in YI scucessfully to ensure course enrollment retry doesnt attempt to enroll in YI again
        if (yourIsraelEnrollmentResponse) {
          dispatch(YourIsraelActions.getYourIsrael());
        }

        return undefined;
      }

      dispatch(YourIsraelActions.getYourIsrael());
      dispatch(
        YourIsraelActions.courseEnrollmentComplete(response.data.payload),
      );

      return response.data;
    };
  },
  setCourseEnrollmentLoading(payload) {
    return {
      type: YourIsraelActions.SET_COURSE_ENROLLMENT_LOADING,
      payload,
    };
  },
  courseEnrollmentComplete(payload) {
    return {
      type: YourIsraelActions.COURSE_ENROLLMENT_COMPLETE,
      payload,
    };
  },
  courseEnrollmentError(payload) {
    return {
      type: YourIsraelActions.COURSE_ENROLLMENT_ERROR,
      payload,
    };
  },
  resetCourseEnrollment() {
    return {
      type: YourIsraelActions.RESET_COURSE_ENROLLMENT,
    };
  },

  doCourseEnrollmentUpdate(courseEnrollment) {
    return async (dispatch) => {
      dispatch(YourIsraelActions.setCourseEnrollmentUpdateLoading(true));

      let error, response, success;
      try {
        response = await AuthRequest.post(
          "EduStudents/CourseSchedules",
          courseEnrollment,
        );
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(YourIsraelActions.courseEnrollmentUpdateError(error));
        return undefined;
      }

      dispatch(
        YourIsraelActions.courseEnrollmentUpdateComplete(response.data.payload),
      );

      return response.data;
    };
  },
  setCourseEnrollmentUpdateLoading(payload) {
    return {
      type: YourIsraelActions.SET_COURSE_ENROLLMENT_UPDATE_LOADING,
      payload,
    };
  },
  courseEnrollmentUpdateComplete(payload) {
    return {
      type: YourIsraelActions.COURSE_ENROLLMENT_UPDATE_COMPLETE,
      payload,
    };
  },
  courseEnrollmentUpdateError(payload) {
    return {
      type: YourIsraelActions.COURSE_ENROLLMENT_UPDATE_ERROR,
      payload,
    };
  },
  resetCourseEnrollmentUpdate() {
    return {
      type: YourIsraelActions.RESET_COURSE_ENROLLMENT_UPDATE,
    };
  },
};
