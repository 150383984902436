import React from "react";
import Select from "../../components/form/Select";
import ValidatedInput from "../../components/ValidatedInput";

export default class BillingAddress extends React.PureComponent {
  onChangeCountry = (name, value) => {
    const { address, onChange } = this.props;

    const resetState = address.state && value !== address.country;
    onChange(name, value, resetState ? { "address.state": "" } : null);
  };

  render() {
    const {
      address,
      countries,
      isDigitalPay,
      onChange,
      onChangeEvt,
      submitAttempted,
    } = this.props;

    const hasBillingAddress =
      address.address1 ||
      address.address2 ||
      address.city ||
      address.state ||
      address.country ||
      address.zip;

    return (
      <div>
        <p className="medium-text fw-700 mb-16">Billing Info</p>
        <div className="mb-16">
          <ValidatedInput
            label="Address"
            name="address.address1"
            onChange={onChangeEvt}
            input={
              <input
                autoComplete="billing address-line1"
                placeholder="Address"
                type="text"
              />
            }
            required={!isDigitalPay && hasBillingAddress}
            showRequired={submitAttempted}
            value={address.address1}
          />
        </div>
        <div className="input-container mb-16">
          <label>Apt, Suite, Floor (optional)</label>
          <input
            autoComplete="billing address-line2"
            name="address.address2"
            onChange={onChangeEvt}
            placeholder="Apt, Suite, Floor"
            type="text"
            value={address.address2}
          />
        </div>
        <div className="mb-16">
          <ValidatedInput
            className="full-width mr-8"
            label="Country"
            name="address.country"
            onChange={this.onChangeCountry}
            input={
              <Select
                autoComplete="billing country"
                clearable={true}
                options={
                  countries &&
                  countries.map((country) => ({
                    label: country.name,
                    value: country.name,
                  }))
                }
                placeholder="Select Country"
                searchable={true}
              />
            }
            required={!isDigitalPay && hasBillingAddress}
            showRequired={submitAttempted}
            value={address.country}
          />
        </div>
        <div className="mb-16">
          <ValidatedInput
            className="mr-8"
            name="address.city"
            onChange={onChangeEvt}
            input={
              <input autoComplete="billing address-level2" placeholder="City" />
            }
            label="City"
            required={!isDigitalPay && hasBillingAddress}
            showRequired={submitAttempted}
            value={address.city}
          />
        </div>
        <div className="input-container nested-input-container mb-16">
          <label>State</label>
          {address.country === "USA" ||
          address.country === "Canada" ||
          address.country === "Mexico" ? (
            <Select
              autoComplete="billing address-level1"
              classname="full-width mr-8"
              clearable={true}
              name="address.state"
              onChange={onChange}
              options={
                address.country &&
                (
                  (
                    countries.find(
                      (country) => country.name === address.country,
                    ) || {}
                  ).states || []
                ).map((state) => ({
                  label: state.name,
                  value: state.name,
                }))
              }
              placeholder="Select State"
              searchable={true}
              value={address.state}
            />
          ) : (
            <input
              autoComplete="billing address-level1"
              className="half-width"
              name="address.state"
              onChange={onChangeEvt}
              placeholder="State"
              type="text"
              value={address.state}
            />
          )}
        </div>
        <div className="input-container">
          <label>Zip</label>
          <div className="full-width">
            <input
              autoComplete="billing postal-code"
              className="billing-postal-code"
              name="address.zip"
              onChange={onChangeEvt}
              placeholder="Zip"
              type="text"
              value={address.zip}
            />
          </div>
        </div>
      </div>
    );
  }
}
