import React from "react";
import Loader from "../../components/Loader";
import { withAppInsights } from "../../lib/AppInsights";

class CourseSurveyCompletedPage extends React.PureComponent {
  state = {
    didTrackSurveyCompletion: false,
    surveyEnrollmentMismatchError: false,
    trackCompletionError: false,
    trackCompletionRetryCount: 0,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.trackSurveyCompletion();
  }

  trackSurveyCompletion = async () => {
    const {
      pageRoute: {
        query: { cse_id: courseScheduleEnrollmentID },
      },
    } = this.props;
    const { onSurveyCompleted, onSurveyCompletionTracked } = window.parent;

    const courseScheduleEnrollment = onSurveyCompleted && onSurveyCompleted();
    if (
      !courseScheduleEnrollment ||
      !courseScheduleEnrollmentID ||
      courseScheduleEnrollment.id.toString() !==
        courseScheduleEnrollmentID.toString()
    ) {
      this.setState({ surveyEnrollmentMismatchError: true }); //error matching survey courseScheduleEnrollmentID to current course sched: display msg for student to reach out to Admin to secure credits
      return;
    }

    await this.props.actions.doCourseEnrollmentUpdate({
      id: courseScheduleEnrollment.id,
      eduCourseScheduleID: courseScheduleEnrollment.eduCourseScheduleID,
      eduStudentEnrollmentID: courseScheduleEnrollment.eduStudentEnrollmentID,
      didCompleteSurvey: true,
    });

    if (this.props.success) {
      this.setState({ didTrackSurveyCompletion: true });

      if (onSurveyCompletionTracked) {
        onSurveyCompletionTracked();
      }
    } else {
      const { retryCount } = this.state;
      if (retryCount < 3) {
        this.setState(
          { retryCount: retryCount + 1 },
          this.trackSurveyCompletion,
        );
        return;
      }

      this.setState({ trackCompletionError: true }); //error tracking survey completion: display msg to students re processing time for completion status update
    }
  };

  render() {
    const {
      didTrackSurveyCompletion,
      surveyEnrollmentMismatchError,
      trackCompletionError,
    } = this.state;

    return (
      <div className="page container">
        {surveyEnrollmentMismatchError ? (
          <div className="text-center">
            <img src="/images/error.svg" alt="error-bot" height="240" />
            <p className="medium-text mt-24 mb-16">
              Sorry, something went wrong!
            </p>
            <p className="medium-text accent-text fw-500">
              We are unable to track your Course Review completion
              <br />
              Please notify{" "}
              <a href="mailto:help@chabadoncampus.org" className="link-text">
                help@chabadoncampus.org
              </a>{" "}
              that you've completed the review.
            </p>
          </div>
        ) : !didTrackSurveyCompletion ? (
          <Loader />
        ) : (
          <div className="text-center">
            <img src="/images/your-israel-logo.png" alt="" height="240" />
            <p className="large-text fw-700 mt-24 mb-16">
              Thank you for submitting your Course Review and Evaluation!
            </p>
            <p className="medium-text accent-text line-height-double">
              We appreciate your feedback and participation.
              {trackCompletionError && (
                <React.Fragment>
                  <br />
                  Your submission is being processed; your course completion
                  status will be updated shortly.
                </React.Fragment>
              )}
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default withAppInsights(CourseSurveyCompletedPage);
