import React from "react";
import { TripRegistrationStatuses } from "../../TripConsts";

export default ({
  trip: {
    event: { isTravelTrip },
    programScheduleName,
    studentRegistrationStatus,
  },
}) => {
  // prev form caching code:
  // useEffect(() => {
  //   const { studentId } = this.props;
  //   if (localStorage.getItem(`registration-${studentId}`)) {
  //     localStorage.removeItem(`registration-${studentId}`);
  //   }
  // }, []);

  return (
    <div className="trip-page-form-state">
      {!isTravelTrip ? (
        <>
          <img src="/images/registration_complete.svg" alt="" height="200" />
          <p className="fw-700 mt-32" style={{ fontSize: "34px" }}>
            You have already registered for {programScheduleName || "this trip"}
            .
          </p>
          <p className="mt-16 large-text">
            Questions? Please contact us at{" "}
            <a
              href="mailto:help@chabadoncampus.org"
              className="link-text"
              target="_blank"
              rel="noopener noreferrer"
            >
              help@chabadoncampus.org
            </a>{" "}
            or{" "}
            <a href="tel:+1-718-510-8181,0" className="link-text">
              718 510 8181 x 0
            </a>
          </p>
        </>
      ) : studentRegistrationStatus === TripRegistrationStatuses.Accepted ? (
        <>
          <img src="/images/registration_accepted.svg" alt="" height="200" />
          <p className="fw-700 mt-32" style={{ fontSize: "34px" }}>
            You are registered for {programScheduleName || "this trip"}!
          </p>
          <p className="large-text mt-16">Stay tuned for email updates</p>
        </>
      ) : studentRegistrationStatus === TripRegistrationStatuses.Waitlisted ? (
        <>
          <img src="/images/registration_pending.svg" alt="" height="240" />
          <p className="fw-700 mt-32" style={{ fontSize: "34px" }}>
            You are currently on the waitlist for the {programScheduleName}{" "}
            trip.
          </p>
          <p className="large-text mt-16">
            You will be notified if a spot opens up.
          </p>
        </>
      ) : studentRegistrationStatus === TripRegistrationStatuses.Rejected ? (
        <>
          <img src="/images/registration_rejected.svg" alt="" height="200" />
          <p className="fw-700 mt-24" style={{ fontSize: "24px" }}>
            Sorry, we were not able to accept you for the {programScheduleName}{" "}
            trip.
          </p>
          <p className="large-text mt-16" style={{ maxWidth: "672px" }}>
            The {programScheduleName} trip has limited spots and must consider a
            combination of factors to determine whom those spots are allotted to
            each year, including school and involvement in local Chabad.
          </p>
        </>
      ) : studentRegistrationStatus === TripRegistrationStatuses.Cancelled ? (
        <>
          <img src="/images/registration_cancelled.svg" alt="" height="200" />
          <p className="fw-700 mt-32" style={{ fontSize: "34px" }}>
            You cancelled your application
          </p>
          <p className="large-text mt-16">
            We hope to see you at another Chabad on Campus program soon.
          </p>
        </>
      ) : (
        <>
          <img src="/images/registration_pending.svg" alt="" height="200" />
          <p className="fw-700 mt-24" style={{ fontSize: "34px" }}>
            Your application is pending.
          </p>
          <p className="large-text mt-16" style={{ maxWidth: "672px" }}>
            Thank you for your patience. If you do not hear from us within three
            weeks, feel free to reach out to{" "}
            <a
              href="mailto:help@chabadoncampus.org"
              className="link-text"
              target="_blank"
              rel="noopener noreferrer"
            >
              help@chabadoncampus.org
            </a>
            .
          </p>
        </>
      )}
    </div>
  );
};
