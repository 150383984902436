import React from "react";
import { Prompt } from "react-router";
import CourseLocation from "./enrollment/CourseLocation";
import CourseSchedule from "./enrollment/CourseSchedule";
import ConfirmEnrollment from "./enrollment/ConfirmEnrollment";
import moment from "moment";

export default class EnrollmentForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      didReviewAndAgree: false,
      submitAttempted: false,
    };
  }

  submitEnrollment = async () => {
    if (!this.state.submitAttempted) {
      this.setState({ submitAttempted: true });
    }

    if (!this.state.didReviewAndAgree) {
      return;
    }

    const {
      doCourseEnrollment,
      yourIsraelCourseSchedule: { id: courseId },
    } = this.props;

    await doCourseEnrollment(courseId);

    const {
      enrollment: { success },
      onCompleteEnrollment,
    } = this.props;

    if (success) {
      onCompleteEnrollment();
    }
  };

  render() {
    const {
      enrollment,
      isShliachView,
      navigating,
      yourIsraelCourse,
      yourIsraelCourseSchedule,
    } = this.props;

    const { didReviewAndAgree, submitAttempted } = this.state;

    return (
      <div className="your-israel-page-form-container container">
        <div className="card">
          <Prompt
            when={!navigating && didReviewAndAgree}
            message="Are you sure you want to leave this page before submitting your enrollment?"
          />
          <div className="your-israel-form-banner">
            <img
              src="/images/your-israel-logo.png"
              alt="your israel logo"
              height="64"
            />
          </div>
          <div className="your-israel-form-section">
            <p className="fw-900 xxl-text mb-16">Enrollment Review</p>
            <p className="accent-text line-height-double">
              Please review the details of the course you would like to enroll
              in. You are enrolling in {yourIsraelCourseSchedule.name}. This
              exciting course takes place on the dates listed below.{" "}
              {yourIsraelCourseSchedule.registrationEndDate &&
                `Note that course enrollment closes on ${moment(
                  yourIsraelCourseSchedule.registrationEndDate,
                ).format("MMMM D @ h:mm a")} ${
                  yourIsraelCourseSchedule.timezoneName
                }.`}
            </p>
          </div>
          <CourseLocation
            location={yourIsraelCourse.courseLocation}
            notes={yourIsraelCourseSchedule.notes}
          />
          <div className="your-israel-form-section">
            <CourseSchedule courseSchedule={yourIsraelCourseSchedule} />
          </div>
          <div className="your-israel-form-section">
            <ConfirmEnrollment
              courseName={yourIsraelCourseSchedule.name}
              didReviewAndAgree={didReviewAndAgree}
              enrollment={enrollment}
              isShliachView={isShliachView}
              onConfirmEnrollment={this.submitEnrollment}
              submitAttempted={submitAttempted}
              toggleDidReviewAndAgree={() =>
                this.setState({ didReviewAndAgree: !didReviewAndAgree })
              }
            />
          </div>
        </div>
      </div>
    );
  }
}
