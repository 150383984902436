import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import Modal from "react-modal";
import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import querySerializer from "query-string";

import {
  AppRouter,
  ErrorSentry,
  setSentryUser,
  hasAuthRequestToken,
  setAuthRequestToken,
  onAuthFailed,
  getImpersonationAuthCookie,
  hasImpersonationAuthCookie,
  appInsightsInit,
  gaInit,
  frontChatInit,
  loadPolyfills,
  Navigation,
  logRocketInit,
} from "./lib";

import { AppArea, AppPages } from "./pages";
import { AuthActions, activateStore, store } from "./state";
import * as serviceWorker from "./serviceWorker";
// import serviceWorkerConfig from "./serviceWorkerConfig";

import "./assets/css/styles.scss";

loadPolyfills();

appInsightsInit();

gaInit();

logRocketInit();

AppRouter.configure({
  loginCheck: hasAuthRequestToken,
  loginPath: AppPages.account.login.path,
  loginRedirectParam: "after",
  pageOptions: {
    anon: false,
    pathExact: true,
  },
  rootArea: AppArea,
});

async function main() {
  await activateStore();
  preloadAuthToken();
  render();
}

function isIframe() {
  try {
    if (window.self === window.top) {
      return false; // site is not in iframe
    }
    // return iframe origin type
    return window.self.location.origin === window.top.location.origin
      ? "same" // same = site within the iframe matches the top level (site containing the iframe) origin (practical usage is for the survey completion popup)
      : "other"; // other = site within the iframe does not match the top level origin
  } catch (e) {
    return "other"; // if there is an error accessing window.top.location, origin is 'other'
  }
}

function preloadAuthToken() {
  onAuthFailed(async (err) => {
    return store.dispatch(AuthActions.handleAuthFailure(err));
  });

  const {
    location: { pathname, search },
  } = Navigation;
  const {
    exp: shliachExp,
    shliach,
    token: shliachToken,
  } = querySerializer.parse(search) || {};

  const isShliachViewAuth = shliach && shliachExp && shliachToken;
  const isShliachAllowedView =
    pathname.indexOf("trips") >= 0 || pathname.indexOf("yourisrael") >= 0;

  if (isShliachViewAuth && isShliachAllowedView) {
    //route-param authentication for trip registration pg shliach view
    setAuthRequestToken(shliachToken, shliachExp);
    frontChatInit();
  } else {
    const {
      auth: {
        accountID,
        token,
        expiration,
        isImpersonated,
        person,
        userEmail,
        userEmailHash,
      } = {},
    } = store.getState();

    let hasImpersonationAuthToken = false;
    //if has impersonation auth cookie, sign in with impersonation auth or load auth token if previously authenticated
    if (hasImpersonationAuthCookie()) {
      const impersonationAuth = getImpersonationAuthCookie();

      //first check if current auth matches impersonation auth cookie
      hasImpersonationAuthToken =
        isImpersonated &&
        token &&
        expiration === impersonationAuth.expiration &&
        userEmail === impersonationAuth.userEmail;

      //if not currently authenticated, sign in with impersonation auth
      if (!hasImpersonationAuthToken) {
        return store.dispatch(
          AuthActions.doImpersonationSignin(impersonationAuth),
        );
      }
    } else if (isImpersonated) {
      //if current auth is impersonated and there is no impersonation auth cookie present (session ended), sign out of account to ensure that impersonation does not extend past a single browser session
      return store.dispatch(AuthActions.doSignout(false)); //no need to disable google auto-select when auto-signing out of an impersonated user account
    }

    const setAuthToken = isImpersonated ? hasImpersonationAuthToken : !!token;
    if (setAuthToken) {
      setAuthRequestToken(token, expiration);
      if (accountID) {
        setSentryUser(accountID, person, userEmail);
      }
    }

    frontChatInit(userEmail, userEmailHash);
    if (isIframe() === "same") {
      // don't display front chat icon within an iframe with 'same' origin
      const frontChatContainer = document.getElementById(
        "front-chat-container",
      );
      if (frontChatContainer) frontChatContainer.style.display = "none";
    }
  }
}
// preloadAuthToken(); // removing this method invocation bec it is invoked in main();

Modal.setAppElement("body");

function render() {
  ReactDOM.render(
    <ErrorSentry>
      <ReduxProvider store={store}>
        <AppRouter />
      </ReduxProvider>
    </ErrorSentry>,
    document.getElementById("root"),
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

//If we want this app to work as a PWA we will need to replace unregisterServiceWorker with registerServiceWorker code below
//And will need to configure nginx settings to avoid caching the service worker (like done for shluchim portal)
// if (process.env.NODE_ENV === 'production') {
//   serviceWorker.register(serviceWorkerConfig);
// }

main();
