import React from "react";

export default class RsvpHeader extends React.PureComponent {
  render() {
    const {
      rsvpLocation: {
        bannerImageURL,
        chabadHouseName,
        logoImageURL,
        phoneNumber,
        websiteURL,
      },
    } = this.props;

    return (
      <div className="rsvp-location-header">
        <div className="rsvp-location-header-img-container">
          {bannerImageURL && (
            <img
              alt="rsvp banner"
              className="location-img"
              src={bannerImageURL}
            />
          )}
        </div>
        <div className="container">
          {logoImageURL && (
            <img
              alt={chabadHouseName}
              className="location-logo"
              src={logoImageURL}
            />
          )}
          <p className="location-title">{chabadHouseName}</p>
          <div className="text-center">
            {phoneNumber && (
              <a
                className="link-text-secondary block mt-16 mobile-mt-8"
                href={`tel:${phoneNumber}`}
              >
                {phoneNumber}
              </a>
            )}
            {websiteURL && (
              <a
                className="link-text-secondary block mt-16 mobile-mt-8"
                href={websiteURL}
                style={{
                  textDecoration: "underline",
                  wordBreak: "break-all",
                  lineHeight: 1.25,
                }}
              >
                {websiteURL.replace(/^https?:\/\//i, "").replace(/\/$/, "")}
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }
}
