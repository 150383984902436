export const TripTypes = {
  InternationalPegisha: "InternationalPegisha",
  RegionalPegisha: "RegionalPegisha",
  JewishU: "JewishU",
  LivingLinks: "LivingLinks",
};

export const TravelTripTypes = [
  TripTypes.JewishU,
  TripTypes.LivingLinks,
];

export const TripRegistrationStatuses = {
  Pending: "Pending",
  Rejected: "Rejected",
  Accepted: "Accepted",
  CheckedIn: "CheckedIn",
  Cancelled: "Cancelled",
  Waitlisted: "Waitlisted",
};

export const TripTermTypes = {
  LongText: "LongText",
  ShortText: "ShortText"
};