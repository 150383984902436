import React, { useCallback, memo } from "react";
import Checkbox from "../../../components/form/Checkbox";
import DateInput from "../../../components/form/DateInput";
import ImageWithCropUpload from "../../../components/ImageWithCropUpload";
import PhoneInput from "../../../components/form/PhoneInput";
import Toggle from "../../../components/form/Toggle";
import ValidatedInput from "../../../components/ValidatedInput";
import { validateEmail } from "../../../lib";

const PersonalInfo = (props) => {
  const onBlurName = (e) => {
    const enteredName = e.target.value;
    const nameParts = enteredName.split(" ").filter((n) => n);
    const capitalizedName = nameParts
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");
    if (capitalizedName !== enteredName) {
      props.onChangeProfile(e.target.name, capitalizedName);
    }
  };

  const {
    countries,
    getProfileFieldLabel,
    isProfileFieldRequired,
    onChangeProfile,
    onChangeProfileEvt,
    initialProfile,
    profile: {
      address,
      cell,
      cellCountryID,
      didConsentToMarketingContact,
      didConsentToMarketingQuotes,
      email,
      isJewish,
      person: {
        dob = "",
        firstName = "",
        gender = "",
        hebrewName = "",
        lastName = "",
        profileImageURL = "",
      } = {},
    } = { person: {} },
    profileValidationErrors,
    showFormValidation,
    updateProfileValidation,
  } = props;

  const initialURL = initialProfile?.person?.profileImageURL;
  const uploadPicturePrompt = useCallback(
    (pictureSaved) => {
      const changePictureText =
        "Been a while since you updated your profile picture? ";
      return (
        <div className="mb-8">
          <span
            className={`line-height-double block mb-8 ${
              showFormValidation &&
              isProfileFieldRequired("person.profileImageURL") &&
              !profileImageURL
                ? "error"
                : "accent-text"
            }`}
            style={{ textTransform: "none", maxWidth: 570 }}
          >
            {`${pictureSaved ? changePictureText : ""} Upload a well-lit,
        high-res headshot to bring your profile up to date.`}
          </span>
        </div>
      );
    },
    [isProfileFieldRequired, profileImageURL, showFormValidation],
  );
  const uploadLink = (
    <span>
      {" "}
      UPLOAD PROFILE IMAGE{" "}
      {isProfileFieldRequired("person.profileImageURL") ? (
        <span className="ml-4 required-text">*</span>
      ) : (
        <span className="ml-4 mobile-ml-0 mobile-mt-8 xs-text">(Optional)</span>
      )}
    </span>
  );
  return (
    <div className="profile-form-section">
      <p className="section-title">Personal information</p>
      <div className="mb-16">
        <ImageWithCropUpload
          name="person.profileImageURL"
          uploadLink={uploadLink}
          label={
            <div className="flex mobile-block">
              {uploadPicturePrompt(!!initialURL)}
            </div>
          }
          uploadedLabel={uploadPicturePrompt(!!initialURL)}
          onChange={onChangeProfile}
          imageName="Profile Image"
          imageType="profile"
          imageURL={profileImageURL}
        />
      </div>
      <div className="profile-form-grid">
        <ValidatedInput
          label={getProfileFieldLabel("First Name", "person.firstName")}
          name="person.firstName"
          onBlur={onBlurName}
          onChange={onChangeProfileEvt}
          required={isProfileFieldRequired("person.firstName")}
          showRequired={showFormValidation}
          showValidation={showFormValidation}
          validate={(fn) => !fn || fn.length > 1}
          validationMessage="Please enter a full first name"
          value={firstName}
        />
        <ValidatedInput
          label={getProfileFieldLabel("Last Name", "person.lastName")}
          name="person.lastName"
          onBlur={onBlurName}
          onChange={onChangeProfileEvt}
          required={isProfileFieldRequired("person.lastName")}
          showRequired={showFormValidation}
          showValidation={showFormValidation}
          validate={(fn) => !fn || fn.length > 1}
          validationMessage="Please enter a full last name"
          value={lastName}
        />
        <ValidatedInput
          label={getProfileFieldLabel("Hebrew Name", "person.hebrewName")}
          name="person.hebrewName"
          onBlur={onBlurName}
          onChange={onChangeProfileEvt}
          required={isProfileFieldRequired("person.hebrewName")}
          showRequired={showFormValidation}
          value={hebrewName}
        />
        <ValidatedInput
          input={<DateInput maxDate={new Date()} />}
          label={getProfileFieldLabel("Birthday", "person.dob")}
          name="person.dob"
          onChange={onChangeProfile}
          required={isProfileFieldRequired("person.dob")}
          showRequired={showFormValidation}
          value={dob}
        />
        <ValidatedInput
          label={getProfileFieldLabel("Email", "email")}
          name="email"
          onChange={onChangeProfileEvt}
          required={isProfileFieldRequired("email")}
          showRequired={showFormValidation}
          showValidation={showFormValidation}
          validate={(email) => {
            const isValid = validateEmail(email);
            updateProfileValidation("email", isValid);
            return isValid;
          }}
          validationMessage="Invalid email address"
          value={email}
        />
        <div
          className={`input-container ${
            profileValidationErrors.indexOf("cell") >= 0 ||
            (showFormValidation && isProfileFieldRequired("cell") && !cell)
              ? "error"
              : ""
          }`}
        >
          <label>{getProfileFieldLabel("Phone", "cell")}</label>
          <PhoneInput
            countries={countries || []}
            countryId={cellCountryID}
            defaultCountryName={address && address.country} //fallback on country associated with student's address
            error={profileValidationErrors.indexOf("cell") >= 0}
            name="cell"
            onBlur={(name, isValid) => updateProfileValidation(name, isValid)}
            onChange={(name, value) => onChangeProfile(name, value)}
            onChangeCountryId={(countryId) => {
              onChangeProfile("cellCountryID", countryId);
            }}
            value={cell}
          />
        </div>
        <ValidatedInput
          className="toggle-container"
          input={
            <Toggle
              allowNoOption={true}
              noOptionValue="Unknown"
              options={[
                {
                  value: "Male",
                  display: "Male",
                },
                {
                  value: "Female",
                  display: "Female",
                },
              ]}
            />
          }
          label={getProfileFieldLabel("Gender", "person.gender")}
          labelClassName="uppercase-text xs-text accent-text mb-4 block"
          name="person.gender"
          onChange={onChangeProfile}
          required={isProfileFieldRequired("person.gender")}
          showRequired={showFormValidation}
          value={gender === "Unknown" ? "" : gender}
        />
        <ValidatedInput
          className="toggle-container"
          input={
            <Toggle
              allowNoOption={true}
              options={[
                {
                  value: true,
                  display: "Yes",
                },
                {
                  value: false,
                  display: "No, but interested",
                },
              ]}
            />
          }
          label={getProfileFieldLabel("Jewish", "isJewish")}
          labelClassName="uppercase-text xs-text accent-text mb-4 block"
          name="isJewish"
          onChange={onChangeProfile}
          required={isProfileFieldRequired("isJewish")}
          showRequired={
            showFormValidation && isJewish !== true && isJewish !== false
          }
          value={isJewish}
        />
      </div>
      <div className="flex" style={{ maxWidth: 650 }}>
        <Checkbox
          checked={didConsentToMarketingContact}
          className="mt-16"
          name="didConsentToMarketingContact"
          onChange={onChangeProfile}
        />
        <div className="mt-12 line-height-large">
          {/* added this click event to mimick the behavior of a checkbox label because I could not figure out how to get this all on one line within the label */}
          <span
            onClick={() =>
              onChangeProfile(
                "didConsentToMarketingContact",
                !didConsentToMarketingContact,
              )
            }
          >
            Yes! I would like to be contacted about other Chabad on Campus
            opportunities via SMS text and email. Message and data rates may
            apply.{" "}
          </span>
          <span
            className="link-text"
            onClick={() =>
              window
                .open("https://chabadoncampus.org/privacy-policy/", "_blank")
                .focus()
            }
          >
            View Privacy Policy and Terms
          </span>
        </div>
      </div>
      <Checkbox
        checked={didConsentToMarketingQuotes}
        className="mt-16"
        label="Chabad on Campus can use my quotes in future publications or marketing materials"
        name="didConsentToMarketingQuotes"
        onChange={onChangeProfile}
      />
    </div>
  );
};

export default memo(PersonalInfo);
