import React from "react";
import { Prompt } from "react-router";
import ConfirmationModal from "../../../components/ConfirmationModal";
import FadeOutMessage from "../../../components/FadeOutMessage";
import Loader from "../../../components/Loader";

import AddressInfo from "./AddressInfo";
import AllergyAndDietaryInfo from "./AllergyAndDietaryInfo";
import CurrentEducationInfo from "./CurrentEducationInfo";
import FamilyBackgroundInfo from "./FamilyBackgroundInfo";
import IsraelEducationInfo from "./IsraelEducationInfo";
import JewishEducationInfo from "./JewishEducationInfo";
import OtherInfo from "./OtherInfo";
import ParentContactInfo from "./ParentContactInfo";
import PersonalInfo from "./PersonalInfo";

import {
  getFormattedValuesForForm,
  handleErrorMessage,
  handlePreventDefault,
  Navigation,
  replaceValuesInObject,
  TwoWayMap,
} from "../../../lib";
import _cloneDeep from "lodash.clonedeep";
import _get from "lodash.get";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";

export default class ProfileForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      initialProfile: null,
      loading: false,
      navigating: false,
      profile: null,
      profileValidationErrors: [],
      showFormValidation: false,
      showMoreBackgroundInfo: false,
      showProfileForm: !props.isCompleteProfilePrompt,
      showSubmitSuccessMessage: false,
      showUpdateEmailConfirmationModal: false,
      submitFormAttempted: false,
      updateEmailConfirmed: false,
    };
  }

  componentDidMount() {
    this.getUserProfile();
    this.props.actions.getCampuses();
  }

  getUserProfile = async () => {
    this.setState({ loading: true }); //managing loading in local state so as not to mount the profile form until profile settings have been loaded

    const {
      actions: { getProfile },
      studentId,
    } = this.props;
    const profile = await getProfile(studentId);

    const {
      isCompleteProfilePrompt,
      onProfileFetched,
      onProfileIsComplete,
      profile: { success },
    } = this.props;

    let profileIsComplete;
    if (success && profile) {
      const formattedProfile = getFormattedValuesForForm(profile, [
        "person.dob",
      ]);
      //default marketing checkboxes to true if not yet set
      if (profile.didConsentToMarketingContact === null) {
        formattedProfile.didConsentToMarketingContact = true;
      }
      if (profile.didConsentToMarketingQuotes === null) {
        formattedProfile.didConsentToMarketingQuotes = true;
      }
      this.setState(
        {
          initialProfile: formattedProfile,
          profile: _cloneDeep(formattedProfile),
        },
        async () => {
          await this.getProfileSettings();
          this.setState({
            loading: false,
            showMoreBackgroundInfo: this.isMoreBackgroundInfoRequired(),
          });

          if (isCompleteProfilePrompt) {
            if (this.isIncompleteProfile()) {
              this.setState({
                showFormValidation: true,
                showProfileForm: true,
              });
              profileIsComplete = false;
            } else {
              onProfileIsComplete(); //hide complete profile modal
              profileIsComplete = true;
            }
          }
        },
      );
    } else {
      this.setState({ loading: false });
    }

    if (isCompleteProfilePrompt) {
      onProfileFetched(success && profile, profileIsComplete);
    }
  };

  getProfileSettings = async () => {
    const {
      actions: { getProfileSettings },
      chabadHouseId,
      programId,
    } = this.props;
    const {
      profile: { campus },
    } = this.state;

    //we only retrieve profile settings by profile campus if chabadHouseId & programId are not both specified (when both are specified API will retrieve settings for campus's first CH enrolled in the program)
    const campusId =
      chabadHouseId && programId ? undefined : campus && campus.id;

    await getProfileSettings(campusId, chabadHouseId, programId);
  };

  isIncompleteProfile = () => {
    const { profile, profileValidationErrors } = this.state;

    if (!profile) return;

    const {
      allergies,
      hasAllergies,
      hasNoJewishEducation,
      dietaryNotes,
      jewishEducations,
      person: { firstName, lastName } = {},
    } = profile;

    const {
      profileSettings: { data: profileSettings },
      requiredFields,
    } = this.props;

    // custom required fields validation - from profileSettings data and requiredFields prop (the latter is provided when rendering complete profile for Trips or Your Israel)
    // NOTE: requiredFields prop can be removed if YI/Trips are hooked up for custom profile settings and program id is sent to the API to retrieve required fields for the program via profileSettings
    const requiredFieldNames = [
      ...(requiredFields || []),
      ...(profileSettings || [])
        .filter((ps) => ps.isRequired)
        .map((ps) => ps.field),
    ];

    if (
      requiredFieldNames.some((fieldName) => {
        const profileFieldAccessor = this.profileFieldsMap.revGet(fieldName);
        const profileFieldValue = _get(profile, profileFieldAccessor);

        // custom Gender and JewishEducation validation:
        if (fieldName === "Gender" && profileFieldValue === "Unknown") {
          return true;
        }

        if (
          fieldName === "JewishEducation" &&
          !hasNoJewishEducation &&
          !jewishEducations.length
        ) {
          return true;
        }

        return (
          !profileFieldValue &&
          profileFieldValue !== true &&
          profileFieldValue !== false
        );
      })
    ) {
      return true;
    }

    // other validation
    return (
      // Names: must be at least 2 characters in length
      (firstName && firstName.length < 2) ||
      (lastName && lastName.length < 2) ||
      // Jewish education:
      (hasNoJewishEducation && jewishEducations.length) || // if 'none' is true no other fields can be selected
      jewishEducations.some((j) => !j.affiliation || !j.name) || // selected jewish education's affiliation and name must be completed
      // Allergies
      (hasAllergies && !allergies?.length) || // allergy specifications are required if hasAllergies is true
      (hasAllergies && allergies?.includes("Other") && !dietaryNotes) || // dietary note is required if allergies include "Other"
      profileValidationErrors.length
    );
  };

  profileFieldsMap = new TwoWayMap({
    "person.profileImageURL": "ProfileImageURL",
    "person.firstName": "FirstName",
    "person.lastName": "LastName",
    email: "Email",
    cell: "Cell",
    "person.hebrewName": "HebrewName",
    "person.dob": "Dob",
    "person.gender": "Gender",
    isJewish: "IsJewish",
    "address.address1": "Address1",
    "address.city": "City",
    "address.state": "State",
    "address.zip": "Zip",
    "address.country": "Country",
    campus: "Campus",
    class: "Class",
    graduationYear: "GraduationYear",
    studentIDNumber: "StudentIDNumber",
    majorID: "Major",
    doubleMajorID: "DoubleMajor",
    fatherBackground: "FatherBackground",
    motherBackground: "MotherBackground",
    "father.name": "FatherName",
    "mother.name": "MotherName",
    "father.email": "FatherEmail",
    "mother.email": "MotherEmail",
    "father.phoneNumber": "FatherPhone",
    "mother.phoneNumber": "MotherPhone",
    doParentsShareResidence: "DoParentsShareResidence",
    hebrewLevel: "HebrewLevel",
    hadBarBatMitzvah: "HadBarBatMitzvah",
    hasTakenIsraelCourses: "HasTakenIsraelCourses",
    jewishIsraelKnowledgeLevel: "JewishIsraelKnowledgeLevel",
    tShirtSize: "TShirtSize",
    // NOTE: These fields are for requiredFields prop usage only, not available or accommodated via profileSettings
    hasAllergies: "Allergies",
    jewishEducations: "JewishEducation",
  });

  isProfileFieldRequired = (fieldAccessor) => {
    const {
      profileSettings: { data: profileSettings },
      requiredFields,
    } = this.props;

    const fieldName = this.profileFieldsMap.get(fieldAccessor);

    const requiredFieldNames = [
      ...(requiredFields || []), // NOTE: the requiredFields prop can be removed if YI/Trips are hooked up for custom profile settings and program id is sent to the API to retrieve required fields for the program via profileSettings
      ...(profileSettings || [])
        .filter((ps) => ps.isRequired)
        .map((ps) => ps.field),
    ];

    return requiredFieldNames.includes(fieldName);
  };

  isMoreBackgroundInfoRequired = () => {
    const {
      profileSettings: { data: profileSettings },
      requiredFields,
    } = this.props;

    const moreBackgroundInfoFields = [
      "HebrewLevel",
      "HasTakenIsraelCourses",
      "JewishEducation",
      "JewishIsraelKnowledgeLevel",
      "TShirtSize",
    ];

    const requiredFieldNames = [
      ...(requiredFields || []), // NOTE: the requiredFields prop can be removed if YI/Trips are hooked up for custom profile settings and program id is sent to the API to retrieve required fields for the program via profileSettings
      ...(profileSettings || [])
        .filter((ps) => ps.isRequired)
        .map((ps) => ps.field),
    ];

    return requiredFieldNames.some((n) => moreBackgroundInfoFields.includes(n));
  };

  getProfileFieldLabel = (label, fieldName) => (
    <React.Fragment>
      <span>{label}</span>
      {this.isProfileFieldRequired(fieldName) && (
        <span className="ml-4 required-text">*</span>
      )}
    </React.Fragment>
  );

  onChangeProfileEvt = (event) => {
    this.onChangeProfile(event.target.name, event.target.value);
  };

  onChangeProfile = (name, value, other) => {
    this.setState(
      // because subsequent setStates are called to update phoneCountryID from the PhoneInput component, use a state updater func to ensure that updates are executed in the order they are called and previous state updates are not overridden(https://duncanleung.com/avoiding-react-setstate-pitfalls/#solution-use-the-updater-code-classlanguage-textfunctioncode-form-to-queue-state-updates)
      (prevState) => {
        let profile = _cloneDeep(prevState.profile);
        _set(profile, name, value);
        if (other) {
          Object.keys(other).forEach((update) =>
            _set(profile, update, other[update]),
          );
        }
        return { profile };
      },
      () => {
        //if we are retrieving profile settings by campus (rather than by CH/Program), refresh on campus change
        if (
          name === "campus" &&
          value &&
          !(this.props.chabadHouseId && this.props.programId)
        ) {
          this.getProfileSettings();
        }
      },
    );
  };

  onSaveProfile = handlePreventDefault(() => {
    this.setState(
      {
        showFormValidation: true,
        submitFormAttempted: true,
        showSubmitSuccessMessage: false,
      },
      async () => {
        if (this.isIncompleteProfile()) {
          return;
        }

        const { initialProfile, profile, updateEmailConfirmed } = this.state;

        if (profile.email !== initialProfile.email && !updateEmailConfirmed) {
          this.setState({
            showUpdateEmailConfirmationModal: true,
          });
          return;
        }

        const profileForSubmission = _cloneDeep(profile);
        replaceValuesInObject(profileForSubmission, (val) => val === "", null);

        const submittedProfile = await this.props.actions.submitProfile(
          profileForSubmission,
        );

        const {
          isCompleteProfilePrompt,
          onProfileIsComplete,
          submitProfile: { success },
        } = this.props;

        if (success && submittedProfile) {
          if (isCompleteProfilePrompt) {
            onProfileIsComplete(); //hide complete profile modal
          } else if (Navigation.location.state?.previousLocationPath) {
            this.setState({ navigating: true }, () => {
              window.scrollTo({ behavior: "instant", left: 0, top: 0 }); // reset scroll before navigating
              Navigation.go(Navigation.location.state?.previousLocationPath);
            });
          } else {
            const formattedProfile = getFormattedValuesForForm(
              submittedProfile,
              ["person.dob"],
            );
            this.setState({
              initialProfile: formattedProfile,
              profile: _cloneDeep(formattedProfile),
              showSubmitSuccessMessage: true,
              submitFormAttempted: false,
              updateEmailConfirmed: false,
            });
          }
        }
      },
    );
  });

  updateProfileValidation = (name, isValid) => {
    const { profileValidationErrors } = this.state;
    this.setState({
      profileValidationErrors: isValid
        ? profileValidationErrors.filter((err) => err !== name)
        : [...profileValidationErrors, name],
    });
  };

  render() {
    const {
      campuses,
      completeProfileReason,
      isCompleteProfilePrompt,
      profile: { error: profileError },
      profileSettings: { error: profileSettingsError },
      studentId,
      submitProfile: {
        loading: submitProfileLoading,
        error: submitProfileError,
      },
      sys: {
        allergyTypes = [],
        countries = [],
        hebrewLevels = [],
        jewishAffiliationTypes = [],
        jewishBackgroundTypes = [],
        jewishEducationTypes = [],
        jewishIsraelKnowledgeLevels = [],
        majors = [],
        studentClasses = [],
        tShirtSizes = [],
      },
    } = this.props;

    const {
      initialProfile,
      loading, // loading profile or initial profile settings
      navigating,
      profile,
      profileValidationErrors,
      showFormValidation,
      showMoreBackgroundInfo,
      showProfileForm,
      showSubmitSuccessMessage,
      showUpdateEmailConfirmationModal,
      submitFormAttempted,
    } = this.state;

    return !showProfileForm ? (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 100,
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Loader />
      </div>
    ) : (
      <React.Fragment>
        {loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : profileError ? (
          <div>
            Sorry, something went wrong and your profile information could not
            be retrieved.
          </div>
        ) : (
          !!(profile && studentId) && ( //showing profile form conditioned on studentID present to ensure that on logout 'save changes' is not prompted
            <div
              className={`relative${
                isCompleteProfilePrompt ? " container mobile-mt-16" : ""
              }`}
            >
              <Prompt
                when={!navigating && !_isEqual(profile, initialProfile)}
                message="Are you sure you want to leave this page before submitting?  Your changes will be lost."
              />
              <div className="mb-24 profile-form-header">
                <div className="container flex flex-align-center flex-justify-space">
                  <p className="xxl-text fw-700">
                    {isCompleteProfilePrompt
                      ? "Complete your profile to continue"
                      : "My Profile"}
                    {isCompleteProfilePrompt && (
                      <span
                        className="medium-text line-height-large block fw-400 mt-16"
                        style={{ gridColumnStart: "span 2" }}
                      >
                        Please complete your profile to
                        {completeProfileReason
                          ? completeProfileReason.includes("Your Israel")
                            ? ` access ${completeProfileReason}`
                            : ` continue with ${completeProfileReason}`
                          : " continue"}
                        . You can update your profile at any time.
                      </span>
                    )}
                  </p>
                  <div className="save-btn-container relative flex flex-align-center ml-16 mobile-hidden">
                    <button
                      className="btn btn-large btn-accent"
                      disabled={
                        submitProfileLoading ||
                        _isEqual(initialProfile, profile)
                      }
                      onClick={this.onSaveProfile}
                      style={{ width: "180px" }}
                      type="submit"
                    >
                      {submitProfileLoading ? "Saving..." : "Save"}
                    </button>
                    {!submitProfileLoading && (
                      <React.Fragment>
                        <FadeOutMessage
                          className="success-text"
                          message={
                            showSubmitSuccessMessage
                              ? "Changes have been saved"
                              : ""
                          }
                          onTimeout={() =>
                            this.setState({ showSubmitSuccessMessage: false })
                          }
                          timeout={20000}
                        />
                        <span className="error-message">
                          {submitFormAttempted &&
                            (showFormValidation && this.isIncompleteProfile()
                              ? "Please complete required/incomplete fields"
                              : !!submitProfileError
                              ? handleErrorMessage(
                                  submitProfileError,
                                  "There was an error saving your profile. Please try again.",
                                )
                              : "")}
                        </span>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
              <form className="profile-form">
                <div className="container">
                  <PersonalInfo
                    countries={countries}
                    getProfileFieldLabel={this.getProfileFieldLabel}
                    isProfileFieldRequired={this.isProfileFieldRequired}
                    onChangeProfile={this.onChangeProfile}
                    onChangeProfileEvt={this.onChangeProfileEvt}
                    profile={profile}
                    profileValidationErrors={profileValidationErrors}
                    showFormValidation={showFormValidation}
                    updateProfileValidation={this.updateProfileValidation}
                    initialProfile={initialProfile}
                  />

                  <AddressInfo
                    countries={countries}
                    getProfileFieldLabel={this.getProfileFieldLabel}
                    isProfileFieldRequired={this.isProfileFieldRequired}
                    onChangeProfile={this.onChangeProfile}
                    onChangeProfileEvt={this.onChangeProfileEvt}
                    profile={profile}
                    showFormValidation={showFormValidation}
                  />

                  <CurrentEducationInfo
                    campuses={campuses}
                    getProfileFieldLabel={this.getProfileFieldLabel}
                    isProfileFieldRequired={this.isProfileFieldRequired}
                    majors={majors}
                    onChangeProfile={this.onChangeProfile}
                    onChangeProfileEvt={this.onChangeProfileEvt}
                    profile={profile}
                    showFormValidation={showFormValidation}
                    studentClasses={studentClasses}
                  />

                  <AllergyAndDietaryInfo
                    allergyTypes={allergyTypes}
                    isProfileFieldRequired={this.isProfileFieldRequired}
                    jewishAffiliationTypes={jewishAffiliationTypes}
                    jewishEducationTypes={jewishEducationTypes}
                    onChangeProfile={this.onChangeProfile}
                    onChangeProfileEvt={this.onChangeProfileEvt}
                    profile={profile}
                    showFormValidation={showFormValidation}
                  />

                  <FamilyBackgroundInfo
                    getProfileFieldLabel={this.getProfileFieldLabel}
                    isProfileFieldRequired={this.isProfileFieldRequired}
                    jewishBackgroundTypes={jewishBackgroundTypes}
                    onChangeProfile={this.onChangeProfile}
                    profile={profile}
                    showFormValidation={showFormValidation}
                  />

                  <ParentContactInfo
                    countries={countries}
                    getProfileFieldLabel={this.getProfileFieldLabel}
                    isProfileFieldRequired={this.isProfileFieldRequired}
                    onChangeProfile={this.onChangeProfile}
                    onChangeProfileEvt={this.onChangeProfileEvt}
                    profile={profile}
                    profileValidationErrors={profileValidationErrors}
                    showFormValidation={showFormValidation}
                    updateProfileValidation={this.updateProfileValidation}
                  />

                  <div
                    className="flex flex-justify-space flex-align-center pointer mb-24"
                    onClick={() =>
                      this.setState({
                        showMoreBackgroundInfo: !showMoreBackgroundInfo,
                      })
                    }
                    style={{
                      maxWidth: "682px",
                      ...(showMoreBackgroundInfo
                        ? {
                            borderBottom: "1px solid #edecec",
                            paddingBottom: "8px",
                          }
                        : {}),
                    }}
                  >
                    <p style={{ fontSize: "16px", fontWeight: 600 }}>
                      More background questions
                    </p>
                    <i className="material-icons xl-text mr-16">
                      {showMoreBackgroundInfo
                        ? "keyboard_arrow_up"
                        : "keyboard_arrow_down"}
                    </i>
                  </div>
                  {showMoreBackgroundInfo && (
                    <React.Fragment>
                      <JewishEducationInfo
                        getProfileFieldLabel={this.getProfileFieldLabel}
                        hebrewLevels={hebrewLevels}
                        isProfileFieldRequired={this.isProfileFieldRequired}
                        jewishAffiliationTypes={jewishAffiliationTypes}
                        jewishEducationTypes={jewishEducationTypes}
                        onChangeProfile={this.onChangeProfile}
                        onChangeProfileEvt={this.onChangeProfileEvt}
                        profile={profile}
                        showFormValidation={showFormValidation}
                      />

                      <IsraelEducationInfo
                        getProfileFieldLabel={this.getProfileFieldLabel}
                        isProfileFieldRequired={this.isProfileFieldRequired}
                        jewishIsraelKnowledgeLevels={
                          jewishIsraelKnowledgeLevels
                        }
                        onChangeProfile={this.onChangeProfile}
                        profile={profile}
                        showFormValidation={showFormValidation}
                      />

                      <OtherInfo
                        getProfileFieldLabel={this.getProfileFieldLabel}
                        isProfileFieldRequired={this.isProfileFieldRequired}
                        onChangeProfile={this.onChangeProfile}
                        profile={profile}
                        showFormValidation={showFormValidation}
                        tShirtSizes={tShirtSizes}
                      />
                    </React.Fragment>
                  )}
                </div>
              </form>
              <div className="save-btn-container relative flex flex-align-center desktop-hidden tablet-hidden">
                <button
                  className="btn btn-large btn-accent full-width"
                  disabled={
                    submitProfileLoading ||
                    profileSettingsError ||
                    _isEqual(initialProfile, profile)
                  }
                  onClick={this.onSaveProfile}
                  type="submit"
                >
                  {submitProfileLoading ? "Saving..." : "Save"}
                </button>
                {!submitProfileLoading && (
                  <React.Fragment>
                    <FadeOutMessage
                      className="success-text"
                      message={
                        showSubmitSuccessMessage
                          ? "Changes have been saved"
                          : ""
                      }
                      onTimeout={() =>
                        this.setState({ showSubmitSuccessMessage: false })
                      }
                      timeout={20000}
                    />
                    <span className="error-message">
                      {profileSettingsError
                        ? "Profile settings could not be retrieved. Please refresh the page to try again."
                        : submitFormAttempted &&
                          (showFormValidation && this.isIncompleteProfile()
                            ? "Please complete required/incomplete fields"
                            : !!submitProfileError
                            ? handleErrorMessage(
                                submitProfileError,
                                "There was an error saving your profile. Please try again.",
                              )
                            : "")}
                    </span>
                  </React.Fragment>
                )}
              </div>
            </div>
          )
        )}

        <ConfirmationModal
          cancel={() =>
            this.setState({ showUpdateEmailConfirmationModal: false })
          }
          confirm={() => {
            this.setState(
              {
                showUpdateEmailConfirmationModal: false,
                updateEmailConfirmed: true,
              },
              this.onSaveProfile,
            );
          }}
          message="You changed your email address. This will change the email used for login. Are you sure you want to make this change?"
          show={showUpdateEmailConfirmationModal}
          title="Confirm Email Change"
        />
      </React.Fragment>
    );
  }
}
