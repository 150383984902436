import React, { useEffect, useMemo } from "react";
import moment from "moment";
import { formatCurrency, pluralizeText } from "../../../../../lib";

function AutoApplyReward(props) {
  const {
    applyReward,
    availableRewardInfo,
    discountableTotal,
    rewardRequest,
    setRewardError,
    unappliedPromoCreditsDiscount,
  } = props;

  const { creditsRestrictedFromDate, creditValue, numOfCreditsAvailable } =
    availableRewardInfo;
  const dateLimitRules = creditsRestrictedFromDate
    ? `This trip only accepts credits earned since ${moment(
        creditsRestrictedFromDate,
      ).format("M/D/YYYY")}.`
    : "";

  // get the number of credits that cover the discountable total
  const creditsRequiredForTotal = useMemo(
    () =>
      discountableTotal ? Math.ceil(discountableTotal.div(creditValue)) : 0,
    [creditValue, discountableTotal],
  );

  const canApplyReward = useMemo(() => {
    if (!creditsRequiredForTotal) return false;

    return (
      numOfCreditsAvailable >= creditsRequiredForTotal && // sufficient student avail credits to meet credits required for reward redemption
      discountableTotal.div(creditValue).eq(creditsRequiredForTotal) // discountable total is evenly divisible by the credit value
    );
  }, [
    creditValue,
    discountableTotal,
    numOfCreditsAvailable,
    creditsRequiredForTotal,
  ]);

  useEffect(() => {
    if (canApplyReward) {
      applyReward({
        creditValue,
        creditsToRedeem: creditsRequiredForTotal,
      });
      setRewardError(false);
    } else {
      if (rewardRequest) {
        applyReward(null); // unapply the reward if already applied (this should not be needed because we unapply the reward request in the billing calculation if its value exceeds the billable total, but will keep as a fallback in case any other variables change)
      }
      if (creditsRequiredForTotal) {
        setRewardError(true); // set an error bec a reward must be applied
      }
    }
    // effect should run ONLY when canApplyReward status or creditsRequiredForTotal changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canApplyReward, creditsRequiredForTotal]);

  return !canApplyReward && creditsRequiredForTotal ? (
    <div className="error-text fw-700 line-height-double">
      {numOfCreditsAvailable < creditsRequiredForTotal // insufficient credits available for redemption
        ? `You do not have sufficient credits to redeem this trip${
            unappliedPromoCreditsDiscount &&
            numOfCreditsAvailable >=
              creditsRequiredForTotal - unappliedPromoCreditsDiscount
              ? " without a first-time JewishU trip goer discount" // note if have sufficient credits for the promotional rate
              : ""
          }. You have ${numOfCreditsAvailable} of ${creditsRequiredForTotal} ${pluralizeText(
            "credit",
            creditsRequiredForTotal,
          )} required for registration. ${dateLimitRules}`
        : !discountableTotal.div(creditValue).eq(creditsRequiredForTotal) // discountable total is not evenly divisible by the credit value - this should not be a practical occurrence
        ? `Application cannot be submitted because the trip fee does not match the ${formatCurrency(
            creditsRequiredForTotal * creditValue,
            "$",
          )} value of the ${creditsRequiredForTotal} ${pluralizeText(
            "JewishU Credit",
            creditsRequiredForTotal,
          )} required to redeem this trip.`
        : "Unable to apply JewishU Credits to redeem this trip. Contact an admin for assistance."}
    </div>
  ) : null;
}

export default React.memo(AutoApplyReward);
