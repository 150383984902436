import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { TripActions } from "../../../state";
import Registration from "./Registration";

export default connect(
  function mapState(state) {
    return {
      ...state.trip,
      profile: state.profile.get,
      sys: state.sys,
      ui: state.ui,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...TripActions,
        },
        dispatch,
      ),
    };
  },
)(Registration);
