import React from "react";

export default function ResourceRegistrationCompleted({
  resourceName,
  customMessage,
}) {
  return (
    <div className="full-width">
      <div className="card flat-card flex flex-align-center flex-justify-center rsvp-state-card">
        <div className="text-center">
          <img src="/images/registration_complete.svg" alt="" height="240" />
          <p className="fw-700 mt-24 rsvp-state-card-title">Thank you!</p>
          <p className="mt-16 large-text line-height-double text-center">
            {customMessage ||
              `Thank you for signing up for ${resourceName}! We'll be in touch with you with more details.`}
          </p>
        </div>
      </div>
      {/* future: implement */}
      {/* {showMoreResources && <MoreResourcesList {...this.props} />} */}
    </div>
  );
}
