import React from "react";
import AccountPageWrapper from "./components/AccountPageWrapper";
import Loader from "../../components/Loader";
import { Navigation, PageLink } from "../../lib";
import Pages from "../../pages";

export default class Confirm extends React.PureComponent {
  state = {
    showEmailInstructions: false,
  };

  componentDidMount() {
    this.confirmRegistration();
  }

  confirmRegistration = async () => {
    const {
      pageRoute: {
        query: { after, id, source, token },
      },
      actions: { doConfirmRegistration },
    } = this.props;

    if (id && token) {
      const isSmsAccountCreation = !!source && source.toLowerCase() === "sms";
      await doConfirmRegistration(id, token, !isSmsAccountCreation);

      if (!this.props.error) {
        const toPath = isSmsAccountCreation
          ? Pages.account.confirmedForSms.path
          : (after && after !== Pages.main.home.path) ||
            Pages.profile.studentProfile.path; //navigate to profile if no after path specified
        Navigation.go(toPath, { accountConfirmed: true });
      }
    }
  };

  render() {
    const {
      loading,
      error,
      pageRoute: {
        location: { state: locationState },
        query: { after, id, token },
      },
    } = this.props;
    const { confirmRegistrationEmail } = locationState || {};
    const { showEmailInstructions } = this.state;

    const isConfirmationAction = !!(id && token);

    return !isConfirmationAction ? (
      <AccountPageWrapper>
        <div>
          <h1 className="uppercase-text fw-700 large-text text-center mb-40">
            Check Your Email
          </h1>
          <p
            className="medium-text line-height-double mb-56"
            style={{ letterSpacing: ".5px" }}
          >
            We've sent a message to{" "}
            {confirmRegistrationEmail ? (
              <span className="fw-700"> {confirmRegistrationEmail} </span>
            ) : (
              " your email address "
            )}{" "}
            with a link to activate your account
          </p>
        </div>

        <div
          className="link-text flex flex-align-center mb-16"
          onClick={() =>
            this.setState({ showEmailInstructions: !showEmailInstructions })
          }
          style={{ fontSize: "14px" }}
        >
          <i className="material-icons">
            {showEmailInstructions ? "expand_more" : "chevron_right"}
          </i>
          <p>Didn't get an email?</p>
        </div>
        {showEmailInstructions && (
          <div>
            <p
              className="medium-text line-height-double mb-16"
              style={{ letterSpacing: ".5px" }}
            >
              If you don’t see an email from us within a few minutes, a few
              things could have happened:
            </p>
            <p
              className="medium-text line-height-double mb-8"
              style={{ letterSpacing: ".5px" }}
            >
              &bull; The email got lost in your spam folder
            </p>
            <p
              className="medium-text line-height-double mb-8"
              style={{ letterSpacing: ".5px" }}
            >
              &bull; The email address you entered had a typo
            </p>
            <p
              className="medium-text line-height-double mb-8"
              style={{ letterSpacing: ".5px" }}
            >
              &bull; You accidentally gave us another email address
            </p>
            <p
              className="medium-text line-height-double mb-32"
              style={{ letterSpacing: ".5px" }}
            >
              &bull; We can’t deliver the email to this address
            </p>
            {!!id && (
              <PageLink
                className="btn btn-large btn-fullWidth btn-accent mt-16"
                to={Pages.account.resendConfirmation}
                query={{ after, id }}
              >
                Re-enter your email address
              </PageLink>
            )}
          </div>
        )}
      </AccountPageWrapper>
    ) : loading || !error ? (
      <div className="account-page">{loading && <Loader />}</div>
    ) : (
      <AccountPageWrapper>
        <div>
          <h1 className="uppercase-text fw-700 large-text text-center mb-40">
            Something Went Wrong
          </h1>
          <p
            className="medium-text line-height-double mb-56"
            style={{ letterSpacing: ".5px" }}
          >
            We couldn’t confirm this email address. You can try again by
            clicking the button below
          </p>
        </div>
        <button
          className="btn btn-large btn-fullWidth btn-accent mt-16"
          onClick={this.confirmRegistration}
        >
          Confirm Email
        </button>
      </AccountPageWrapper>
    );
  }
}
