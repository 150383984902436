import { AccountArea } from "./account";
import { MainArea } from "./main";
import { ProfileArea } from "./profile";
import { TripArea } from "./trips";
import { RsvpArea } from "./rsvp";
import { YourIsraelArea } from "./yourIsrael";

export const AppPages = {
  account: AccountArea.pages,
  main: MainArea.pages,
  profile: ProfileArea.pages,
  rsvp: RsvpArea.pages,
  trips: TripArea.pages,
  yourIsrael: YourIsraelArea.pages,
};
export default AppPages;

export const AppArea = {
  areas: [
    AccountArea,
    MainArea,
    ProfileArea,
    RsvpArea,
    TripArea,
    YourIsraelArea,
  ],
};
