import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { YourIsraelActions } from "../../state";
import SurveyCompleted from "./SurveyCompleted";

export default connect(
  function mapState(state) {
    return {
      ...state.yourIsrael.enrollmentUpdate,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          doCourseEnrollmentUpdate: YourIsraelActions.doCourseEnrollmentUpdate,
        },
        dispatch,
      ),
    };
  },
)(SurveyCompleted);
