import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "../../../../../components/form/Select";
import { formatCurrency, pluralizeText } from "../../../../../lib";

function ManualApplyReward(props) {
  const { applyReward, availableRewardInfo, discountableTotal, rewardRequest } =
    props;

  const {
    creditValue,
    isPartialRedemptionAllowed,
    maxCredits,
    numOfCreditsAvailable,
  } = availableRewardInfo;

  const [creditsToRedeem, setCreditsToRedeem] = useState("");

  const numOfRedeemableCredits = useMemo(() => {
    if (discountableTotal <= 0) return 0;

    // get the number of credits that fit within the discountable total (applied credits cannot exceed the discountable total)
    let creditsForTotal = Math.floor(discountableTotal.div(creditValue));

    if (!isPartialRedemptionAllowed) {
      const cannotRedeemMaxCredits = // when partial redemption is not allowed: maxCredits = credits required for reward redemption
        creditsForTotal < maxCredits || // credits to cover trip total is less than max credits
        maxCredits > numOfCreditsAvailable; // insufficient student avail credits to meet max credits

      return cannotRedeemMaxCredits ? 0 : maxCredits;
    }

    // if there is a maxCredits value and creditsForTotal exceeds it, limit it to the maxCredits value
    if (maxCredits && creditsForTotal > maxCredits) {
      creditsForTotal = maxCredits;
    }

    return numOfCreditsAvailable < creditsForTotal
      ? numOfCreditsAvailable
      : creditsForTotal;
  }, [
    creditValue,
    discountableTotal,
    isPartialRedemptionAllowed,
    maxCredits,
    numOfCreditsAvailable,
  ]);

  const onApplyReward = useCallback(() => {
    applyReward({
      creditValue,
      creditsToRedeem,
    });
  }, [applyReward, creditValue, creditsToRedeem]);

  useEffect(() => {
    // initialize/reset creditsToRedeem selection
    setCreditsToRedeem(!isPartialRedemptionAllowed ? maxCredits : "");

    // unapply reward if price change makes redemption not allowed
    if (rewardRequest && !numOfRedeemableCredits) {
      applyReward(null);
    }

    // effect should run ONLY when numOfRedeemableCredits changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numOfRedeemableCredits]);

  if (rewardRequest || !numOfRedeemableCredits) return null;

  return (
    <div className="apply-reward-card">
      {isPartialRedemptionAllowed ? (
        <>
          <div className="accent-text line-height-double mb-8">
            You have {numOfCreditsAvailable}{" "}
            {pluralizeText("JewishU Credit", numOfCreditsAvailable)} available
            to redeem toward the registration fee, at{" "}
            {formatCurrency(creditValue, "$")} per credit.
            {numOfRedeemableCredits < numOfCreditsAvailable &&
              `You can redeem up to ${numOfRedeemableCredits}
            ${pluralizeText("credit", numOfRedeemableCredits)}.`}
          </div>
          <div className="flex flex-align-center">
            <Select
              onChange={(_, val) => setCreditsToRedeem(val)}
              options={
                numOfRedeemableCredits
                  ? Array(numOfRedeemableCredits)
                      .fill()
                      .map((_, i) => ({
                        label: `${i + 1} ${pluralizeText(
                          "credit",
                          i + 1,
                        )} (${formatCurrency((i + 1) * creditValue, "$")})`,
                        value: i + 1,
                      }))
                  : []
              }
              placeholder="Select credits"
              value={creditsToRedeem}
            />
            <button
              className="btn btn-accent btn-medium ml-16"
              disabled={!creditsToRedeem}
              style={{ width: "80px", minWidth: "80px" }}
              onClick={onApplyReward}
            >
              Apply
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="accent-text line-height-double mb-8">
            You have {numOfCreditsAvailable}{" "}
            {pluralizeText("JewishU Credit", numOfCreditsAvailable)} available
            to redeem toward the registration fee, at{" "}
            {formatCurrency(creditValue, "$")} per credit. Click below to apply{" "}
            {numOfRedeemableCredits}{" "}
            {pluralizeText("credit", numOfRedeemableCredits)} towards the
            Registration Fee.
          </div>
          <div className="link-text uppercase-text" onClick={onApplyReward}>
            Apply {numOfRedeemableCredits}{" "}
            {pluralizeText("Credit", numOfRedeemableCredits)}
          </div>
        </>
      )}
    </div>
  );
}

export default React.memo(ManualApplyReward);
