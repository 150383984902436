import React from "react";
import FadeOutMessage from "../../components/FadeOutMessage";

export default class ManualPayButton extends React.PureComponent {
  render() {
    const {
      clearErrorMessage,
      disabled,
      errorMessage,
      incompleteRegistration,
      customValidation: customValidationFailed,
      customValidationErrorMessage,
      loading,
      noteText,
      submitAttempted,
      buttonText,
    } = this.props;

    return (
      <div>
        <button
          className="btn pay-btn btn-accent btn-large flex flex-align-center flex-justify-center"
          disabled={disabled || loading}
          type="submit"
        >
          {loading ? "Processing..." : buttonText || "Register"}
        </button>
        {noteText && (
          <p className="accent-text-dark text-center mt-8">{noteText}</p>
        )}

        {submitAttempted &&
          (customValidationFailed || incompleteRegistration ? (
            <span
              className="error-text mt-4 small-text"
              style={{ position: "absolute", right: "0" }}
            >
              {customValidationFailed
                ? customValidationErrorMessage
                : "Please complete required or incomplete fields"}
            </span>
          ) : (
            errorMessage && (
              <FadeOutMessage
                className="error-text mt-4 small-text"
                message={errorMessage}
                onTimeout={clearErrorMessage}
                style={{ position: "absolute", right: "0" }}
                timeout={20000}
              />
            )
          ))}
      </div>
    );
  }
}
