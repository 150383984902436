import React from "react";
import Loader from "../../../components/Loader";
import StripeProvider from "../../../components/StripeProvider";
import RegistrationForm from "./RegistrationForm";

import RegistrationClosed from "./registrationStates/RegistrationClosed";
import RegistrationCompleted from "./registrationStates/RegistrationCompleted";
import RegistrationError from "./registrationStates/RegistrationError";
import RegistrationNotAvailable from "./registrationStates/RegistrationNotAvailable";
import RegistrationStatus from "./registrationStates/RegistrationStatus";

import { Navigation, PageURL } from "../../../lib";
import { TripTypes } from "../TripConsts";

const { REACT_APP_STRIPE_API_KEY } = process.env;

export default class Registration extends React.PureComponent {
  state = {
    initialized: false,
    navigating: false,
  };

  componentDidMount() {
    this.getTrip();
  }

  getTrip = async () => {
    const {
      isShliachView,
      pageRoute: {
        params: { eventType, scheduleId },
      },
    } = this.props;

    await this.props.actions.getTripByStudent(
      scheduleId,
      eventType,
      isShliachView,
    );

    this.setState({ initialized: true });
  };

  navigate = (page, state) => {
    this.setState({ navigating: true }, () => {
      window.scrollTo({ behavior: "instant", left: 0, top: 0 }); // reset scroll before navigating

      const url = PageURL.to(page);
      state ? Navigation.redirect(url, state) : Navigation.go(url);

      if (page === Navigation.page) {
        this.setState({ navigating: false });
      }
    });
  };

  onCompleteRegistration = () => {
    const {
      pageRoute: {
        location: { pathname },
      },
    } = this.props;

    this.navigate(pathname, { registrationCompleted: true });
  };

  render() {
    const {
      actions,
      isShliachView,
      pageRoute: {
        location: { state: pageLocationState },
      },
      profile: { data: profile },
      trip: { data: trip, error: tripError, loading: tripLoading },
      register,
      sys,
      ui,
    } = this.props;

    const { initialized, navigating } = this.state;

    return !initialized || tripLoading ? (
      <div>
        <Loader />
      </div>
    ) : tripError ? (
      <RegistrationError error={tripError} />
    ) : (
      trip &&
      (trip.studentRegistrationStatus ? (
        <RegistrationStatus trip={trip} />
      ) : !trip.isRegistrationOpen ? (
        <RegistrationClosed trip={trip} />
      ) : !trip.isAvailableForThisCampus ? (
        <RegistrationNotAvailable trip={trip} />
      ) : trip.event.type === TripTypes.JewishU && !trip.availableRewardInfo ? (
        <RegistrationNotAvailable juEnrollmentRequired={true} trip={trip} />
      ) : pageLocationState && pageLocationState.registrationCompleted ? (
        <RegistrationCompleted trip={trip} />
      ) : (
        <StripeProvider apiKey={REACT_APP_STRIPE_API_KEY}>
          <RegistrationForm
            doTripRegistration={actions.doTripRegistration}
            isShliachView={isShliachView}
            navigate={this.navigate}
            navigating={navigating}
            onCompleteRegistration={this.onCompleteRegistration}
            profile={profile}
            register={register}
            sys={sys}
            trip={trip}
            ui={ui}
          />
        </StripeProvider>
      ))
    );
  }
}
