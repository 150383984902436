import React from "react";
import { getAddressDisplay } from "../../../../lib";

export default class CourseLocation extends React.PureComponent {
  render() {
    const {
      location: {
        chabadHouseAddress1,
        chabadHouseAddress2,
        chabadHouseCountry,
        chabadHouseCity,
        chabadHouseLogoURL,
        chabadHouseName,
        chabadHousePhone,
        chabadHouseState,
        chabadHouseWebsiteURL,
        chabadHouseZip,
      },
      notes,
    } = this.props;

    return (
      <div className="your-israel-form-section">
        <p className="fw-700 xl-text mb-16 accent-text-dark">Course Location</p>
        <div className="flex">
          {chabadHouseLogoURL && (
            <img
              alt={chabadHouseName}
              src={chabadHouseLogoURL}
              height="96"
              className="mr-32"
            />
          )}
          <div style={{ overflowWrap: "break-word" }}>
            <p className="fw-700 mb-8 medium-text line-height-double">
              {chabadHouseName}
            </p>
            <p className="mb-8 line-height-double">
              {getAddressDisplay(
                chabadHouseAddress1,
                chabadHouseAddress2,
                chabadHouseCity,
                chabadHouseState,
                chabadHouseZip,
                chabadHouseCountry,
              )}
            </p>
            <p className="mb-8">{chabadHousePhone}</p>
            <a
              className="link-text"
              href={chabadHouseWebsiteURL}
              rel="noopener noreferrer"
              style={{ wordBreak: "break-all" }}
              target="_blank"
            >
              {chabadHouseWebsiteURL}
            </a>
          </div>
        </div>
        {notes && (
          <div className="mt-24">
            <p className="fw-700 mb-8 medium-text line-height-double">
              Course Notes
            </p>
            {notes}
          </div>
        )}
      </div>
    );
  }
}
