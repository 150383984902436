import { AuthState } from "./auth/state";
import { ActivityState } from "./activity/state";
import { CampusesState } from "./campuses/state";
import { ConfirmRegistrationState } from "./confirmRegistration/state";
import { LoginState } from "./login/state";
import { ProfileState } from "./profile/state";
import { RecoverPassState } from "./recoverPass/state";
import { RegistrationState } from "./registration/state";
import { ResendConfirmationState } from "./resendRegistrationConfirmation/state";
import { ResetPassState } from "./resetPass/state";
import { RsvpState } from "./rsvp/state";
import { SysState } from "./sys/state";
import { TripState } from "./trip/state";
import { UIState } from "./ui/state";
import { YourIsraelState } from "./yourIsrael/state";

export * from "./activity/actions";
export * from "./auth/actions";
export * from "./campuses/actions";
export * from "./trip/actions";
export * from "./profile/actions";
export * from "./recoverPass/actions";
export * from "./resendRegistrationConfirmation/actions";
export * from "./resetPass/actions";
export * from "./rsvp/actions";
export * from "./sys/actions";
export * from "./yourIsrael/actions";

export default [
  ActivityState,
  AuthState,
  CampusesState,
  ConfirmRegistrationState,
  LoginState,
  ProfileState,
  RecoverPassState,
  RegistrationState,
  ResendConfirmationState,
  ResetPassState,
  RsvpState,
  SysState,
  TripState,
  UIState,
  YourIsraelState,
];
