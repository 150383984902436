import React from "react";
import ImageWithCropUpload from "../../../../components/ImageWithCropUpload";
import Checkbox from "../../../../components/form/Checkbox";
import Select from "../../../../components/form/Select";
import Toggle from "../../../../components/form/Toggle";
import ValidatedInput from "../../../../components/ValidatedInput";
import { TripTypes } from "../../TripConsts";
import {
  getWordCount,
  hasTrueFalseValue,
  pluralizeText,
} from "../../../../lib";

function Application(props) {
  const {
    onChange,
    onChangeEvt,
    registration: {
      profileDetails: {
        allergies,
        dietaryNotes,
        hasAllergies,
        profileImageURL,
        tShirtSize,
      },
      travelApplication: {
        hasMedicalCondition,
        hasPriorTripExperience,
        isTakingMedication,
        isUnderPsychologicalCare,
        jewishCampusInvolvementDescription,
        medicalConditionDescription,
        medicationDescription,
        otherReferralDescription,
        priorTripExperienceDescription,
        psychologicalCareDescription,
        referralSources,
        tripReason,
      },
    },
    submitAttempted,
    sys: {
      allergyTypes = [],
      tripRegistrationReferralSources = [],
      tShirtSizes = [],
    },
    trip: {
      event: { isTShirtSizeRequired, tripLocation, type },
    },
  } = props;

  const isLivingLinksTrip = type === TripTypes.LivingLinks;

  const tripReasonWordCount =
    isLivingLinksTrip && tripReason ? getWordCount(tripReason) : 0;

  return (
    <>
      <p className="section-title">Application</p>

      <div className="subsection">
        <p className="fw-700 mb-16">Review & confirm your profile picture</p>
        <div className="mb-24">
          <ImageWithCropUpload
            name="profileDetails.profileImageURL"
            uploadLink={
              profileImageURL ? (
                <p className="accent-text-dark">
                  Want to update your headshot? Click here to{" "}
                  <span className="accent-text-secondary">
                    upload a new image
                  </span>
                  .
                </p>
              ) : (
                // note: this link should not be seen because the profile image is required in the pre-registration profile check
                <p
                  className={submitAttempted && !profileImageURL ? "error" : ""}
                >
                  Upload a headshot
                </p>
              )
            }
            onChange={onChange}
            imageName="Profile Image"
            imageType="profile"
            imageURL={profileImageURL}
          />
        </div>
        {isTShirtSizeRequired && (
          <div className="tshirt-size">
            <p className="fw-700 mb-16">T-Shirt Size</p>
            <ValidatedInput
              input={
                <Select
                  options={tShirtSizes?.map((s) => ({
                    label: s.displayValue,
                    value: s.enumValue,
                  }))}
                  placeholder="Select Size"
                />
              }
              label="Preferred t-shirt size"
              name="profileDetails.tShirtSize"
              onChange={onChange}
              required={true}
              showRequired={submitAttempted}
              value={tShirtSize}
            />
          </div>
        )}
      </div>

      {isLivingLinksTrip && (
        <div className="subsection">
          <div className="mb-24">
            <p className="trip-form-label">
              Please write two paragraphs addressing why you want to join this
              trip and what you hope to gain.
            </p>
            <textarea
              className={`custom-input${
                submitAttempted && (!tripReason || tripReasonWordCount < 200)
                  ? " error"
                  : ""
              }`}
              name="travelApplication.tripReason"
              onChange={onChangeEvt}
              style={{ height: "200px" }}
              value={tripReason}
            />
            <p
              className={`small-text text-right ${
                submitAttempted && tripReasonWordCount < 200
                  ? "error-text"
                  : "accent-text"
              }`}
            >
              {tripReason
                ? `${tripReasonWordCount} ${pluralizeText(
                    "word",
                    tripReasonWordCount,
                  )} (minimum 200)`
                : "minimum 200 words"}
            </p>
          </div>
          <div className="mb-24">
            <p className="accent-text line-height-large mb-16">
              How did you hear about the trip? (Check all that apply)
              {submitAttempted && !referralSources.length && (
                <span className="error-text ml-8">
                  * Please select at least one option
                </span>
              )}
            </p>
            <div>
              {tripRegistrationReferralSources?.map((source, index) => (
                <div key={index} className="mb-16">
                  <Checkbox
                    checked={referralSources.includes(source.enumValue)}
                    id={`referralSource-${source.enumValue}`}
                    label={source.displayValue}
                    name={source.enumValue}
                    onChange={(type, value) =>
                      onChange(
                        "travelApplication.referralSources",
                        value
                          ? [...referralSources, type]
                          : referralSources.filter((r) => r !== type),
                        type === "Other" && !value
                          ? {
                              "travelApplication.otherReferralDescription": "",
                            }
                          : undefined,
                      )
                    }
                  />
                </div>
              ))}
            </div>
            {referralSources.includes("Other") && (
              <>
                <p className="trip-form-label mt-16">Please explain</p>
                <textarea
                  className={`custom-input${
                    submitAttempted && !otherReferralDescription ? " error" : ""
                  }`}
                  name="travelApplication.otherReferralDescription"
                  onChange={onChangeEvt}
                  style={{ height: "48px" }}
                  value={otherReferralDescription}
                />
              </>
            )}
          </div>
          <div className="mb-24">
            <p className="trip-form-label">
              Have you previously participated in a trip to{" "}
              {tripLocation || "this destination"}?
            </p>
            <Toggle
              error={
                submitAttempted && !hasTrueFalseValue(hasPriorTripExperience)
              }
              name="travelApplication.hasPriorTripExperience"
              onChange={(name, value) =>
                onChange(
                  name,
                  value,
                  !value
                    ? {
                        "travelApplication.priorTripExperienceDescription": "",
                      }
                    : undefined,
                )
              }
              options={[
                {
                  value: true,
                  display: "Yes",
                },
                {
                  value: false,
                  display: "No",
                },
              ]}
              value={hasPriorTripExperience}
            />
            {hasPriorTripExperience && (
              <>
                <p className="trip-form-label mt-16">Please explain</p>
                <textarea
                  className={`custom-input${
                    submitAttempted && !priorTripExperienceDescription
                      ? " error"
                      : ""
                  }`}
                  name="travelApplication.priorTripExperienceDescription"
                  onChange={onChangeEvt}
                  style={{ height: "48px" }}
                  value={priorTripExperienceDescription}
                />
              </>
            )}
          </div>
          <div>
            <p className="trip-form-label">
              Please tell us about your current Jewish campus involvement
            </p>
            <textarea
              className={`custom-input${
                submitAttempted && !jewishCampusInvolvementDescription
                  ? " error"
                  : ""
              }`}
              name="travelApplication.jewishCampusInvolvementDescription"
              onChange={onChangeEvt}
              style={{ height: "48px" }}
              value={jewishCampusInvolvementDescription}
            />
          </div>
        </div>
      )}

      <div className="subsection">
        <p className="subsection-title mb-24">
          Allergies & Dietary Restrictions
        </p>
        <div>
          <p className="trip-form-label">
            Do you have any allergies or dietary needs?
          </p>
          <Toggle
            error={submitAttempted && !hasTrueFalseValue(hasAllergies)}
            name="profileDetails.hasAllergies"
            onChange={(name, value) =>
              onChange(name, value, { "profileDetails.allergies": [] })
            }
            options={[
              {
                value: true,
                display: "Yes",
              },
              {
                value: false,
                display: "No",
              },
            ]}
            value={hasAllergies}
          />
          {hasAllergies && (
            <div className="mt-24">
              <p className="accent-text mb-16">
                Select all that apply
                {submitAttempted && !allergies.length && (
                  <span className="error-text ml-8">
                    * Please select at least one option
                  </span>
                )}
              </p>
              <div className="profile-form-grid">
                {allergyTypes?.map((allergyType) => (
                  <Checkbox
                    checked={allergies.includes(allergyType.enumValue)}
                    id={`allergies-${allergyType.enumValue}`}
                    key={allergyType.enumValue}
                    label={allergyType.displayValue}
                    name={allergyType.enumValue}
                    onChange={(type, value) =>
                      onChange(
                        "profileDetails.allergies",
                        value
                          ? [...allergies, type]
                          : allergies.filter((a) => a !== type),
                      )
                    }
                  />
                ))}
              </div>
            </div>
          )}
          <div
            className={`input-container mt-16${
              submitAttempted && allergies.includes("Other") && !dietaryNotes
                ? " error"
                : ""
            }`}
            style={{ maxWidth: "unset" }}
          >
            <label>
              Additional Dietary Information and/or Severity of Allergies
            </label>
            <textarea
              className="custom-input"
              name="profileDetails.dietaryNotes"
              onChange={onChangeEvt}
              style={{ background: "transparent", border: "none" }}
              value={dietaryNotes}
            />
          </div>
        </div>
      </div>

      <div className="subsection">
        <p className="subsection-title mb-24">Medical details</p>
        <div className="mb-24">
          <p className="trip-form-label">
            Do you have any medical conditions we should know about for the
            trip?
          </p>
          <Toggle
            error={submitAttempted && !hasTrueFalseValue(hasMedicalCondition)}
            name="travelApplication.hasMedicalCondition"
            onChange={(name, value) =>
              onChange(
                name,
                value,
                !value
                  ? { "travelApplication.medicalConditionDescription": "" }
                  : undefined,
              )
            }
            options={[
              {
                value: true,
                display: "Yes",
              },
              {
                value: false,
                display: "No",
              },
            ]}
            value={hasMedicalCondition}
          />
          {hasMedicalCondition && (
            <>
              <p className="trip-form-label mt-16">Please explain</p>
              <textarea
                className={`custom-input${
                  submitAttempted && !medicalConditionDescription
                    ? " error"
                    : ""
                }`}
                name="travelApplication.medicalConditionDescription"
                onChange={onChangeEvt}
                style={{ height: "48px" }}
                value={medicalConditionDescription}
              />
            </>
          )}
        </div>
        <div className="mb-24">
          <p className="trip-form-label">
            Are you currently taking any medication?
          </p>
          <Toggle
            error={submitAttempted && !hasTrueFalseValue(isTakingMedication)}
            name="travelApplication.isTakingMedication"
            onChange={(name, value) =>
              onChange(
                name,
                value,
                !value
                  ? { "travelApplication.medicationDescription": "" }
                  : undefined,
              )
            }
            options={[
              {
                value: true,
                display: "Yes",
              },
              {
                value: false,
                display: "No",
              },
            ]}
            value={isTakingMedication}
          />
          {isTakingMedication && (
            <>
              <p className="trip-form-label mt-16">Please explain</p>
              <textarea
                className={`custom-input${
                  submitAttempted && !medicationDescription ? " error" : ""
                }`}
                name="travelApplication.medicationDescription"
                onChange={onChangeEvt}
                style={{ height: "48px" }}
                value={medicationDescription}
              />
            </>
          )}
        </div>
        <div>
          <p className="trip-form-label">
            Are you currently seeing a psychologist or psychiatrist?
          </p>
          <Toggle
            error={
              submitAttempted && !hasTrueFalseValue(isUnderPsychologicalCare)
            }
            name="travelApplication.isUnderPsychologicalCare"
            onChange={(name, value) =>
              onChange(
                name,
                value,
                !value
                  ? { "travelApplication.psychologicalCareDescription": "" }
                  : undefined,
              )
            }
            options={[
              {
                value: true,
                display: "Yes",
              },
              {
                value: false,
                display: "No",
              },
            ]}
            value={isUnderPsychologicalCare}
          />
          {isUnderPsychologicalCare && (
            <>
              <p className="trip-form-label mt-16">Please explain</p>
              <textarea
                className={`custom-input${
                  submitAttempted && !psychologicalCareDescription
                    ? " error"
                    : ""
                }`}
                name="travelApplication.psychologicalCareDescription"
                onChange={onChangeEvt}
                style={{ height: "48px" }}
                value={psychologicalCareDescription}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default React.memo(Application);
