import React from "react";
import { PageLink, PageURL, formatCurrency } from "../../../../lib";
import Pages from "../../../../pages";

export default function AvailableResourcesList(props) {
  const {
    resources: {
      data: resourcesData,
      error,
    },
    pageRoute: {
      params: { chabadHouseSlug },
    },
    user,
  } = props;

  const { results: resources = [] } = resourcesData || {};

  const fullyBookedIcon = () => (
    <p className="flex flex-align-center error-text mb-8">
      <i className="material-icons medium-text mr-4">info</i>Resource full
    </p>
  );

  const requestResourceLink = (resourceId) => {
    const resourcePageUrl = PageURL.to(
      Pages.rsvp.registerResource,
      {
        chabadHouseSlug,
        resourceId,
      },
      null,
    );

    return (
      <PageLink
        className="link-text uppercase-text"
        to={
          user
            ? resourcePageUrl
            : PageURL.to(Pages.account.login, null, {
                after: encodeURI(resourcePageUrl),
              })
        }
      >
        Request
      </PageLink>
    );
  };

  const displayResourceInfo = (resource) => {
    return (
      <div key={resource.resourceID} className="rsvp-resources-card">
        <img
          alt={resource.name}
          className="rsvp-resource-img mr-8"
          src={resource.imageURL || "/images/rsvp-event-card-placeholder.png"}
        />
        <div className="rsvp-resources-card-details">
          <div>
            <p className="medium-text fw-700 mb-8">{resource.name}</p>
            <p className="line-height-large">{resource.description}</p>
            {!!resource.registrationFeeAmount && (
              <p className="fw-700 mt-8">
                {formatCurrency(resource.registrationFeeAmount, "$")}
              </p>
            )}
          </div>
          <div className="mt-16">
            {resource.isFullyBooked
              ? fullyBookedIcon()
              : requestResourceLink(resource.resourceID)}
          </div>
        </div>
      </div>
    );
  };

  const availableResources = resources.filter(
    (resource) => resource.state === "Available",
  );

  return (
    <div className="rsvp-resources-container">
      <div className="xl-text fw-700 mb-16">Resources</div>
      {error ? (
        <div>
          <img
            src="/images/error.svg"
            alt="error-bot"
            className="rsvp-resources-no-results-img"
          />
          <p className="xxl-text mt-24 mb-16">An error has occured</p>
          <p className="medium-text accent-text fw-500">
            Our server is being stubborn, please try again
          </p>
        </div>
      ) : (
        availableResources.map((resource) => displayResourceInfo(resource))
      )}
    </div>
  );
}
