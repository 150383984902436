import React from "react";
import Layout from "../../components/Layout.vm";
import Loader from "../../components/Loader";
import RsvpHeader from "./components/events/RsvpHeader";
import RsvpError from "./components/RsvpError";
import RsvpNotAvailable from "./components/events/RsvpNotAvailable";
import UpcomingEventsList from "./components/events/UpcomingEventsList";
import { withAppInsights } from "../../lib/AppInsights";
import AvailableResourcesList from "./components/events/AvailableResourcesList";
import { isMobileView, PageURL, Navigation } from "../../lib";

class RsvpEvents extends React.PureComponent {
  state = {
    showResourcesTab: true,
  };

  componentDidMount() {
    this.getRsvpLocation();
    this.getResources();
  }

  getRsvpLocation = () => {
    const {
      pageRoute: {
        params: { chabadHouseSlug },
      },
    } = this.props;
    this.props.actions.getRsvpLocation(chabadHouseSlug);
  };

  getResources = async () => {
    const {
      actions: { getRsvpResources },
      pageRoute: {
        params: { chabadHouseSlug },
      },
    } = this.props;
    const response = await getRsvpResources(chabadHouseSlug, true);
    const resources =
      response.data?.results?.filter(
        (resource) => resource.state === "Available",
      ) || [];
    if (!resources.length) {
      this.setState({ showResourcesTab: false });
    }
  };

  getListsForDesktopView = () => {
    const {
      actions: {
        resetRsvpEventSchedules,
        getRsvpEventSchedules,
        getRsvpResources,
      },
      eventSchedules,
      resources,
      pageRoute,
      rsvpLocation: { data: rsvpLocation },
      user,
    } = this.props;

    return (
      <div className="events-resources-lists">
        <UpcomingEventsList
          eventSchedules={eventSchedules}
          getEventSchedules={getRsvpEventSchedules}
          resetEventSchedules={resetRsvpEventSchedules}
          rsvpLocation={rsvpLocation}
          pageRoute={pageRoute}
          user={user}
        />
        {this.state.showResourcesTab && (
          <AvailableResourcesList
            resources={resources}
            getResources={getRsvpResources}
            pageRoute={pageRoute}
            user={user}
          />
        )}
      </div>
    );
  };

  views = {
    events: {
      component: UpcomingEventsList,
      value: "events",
      label: "Upcoming Events",
    },
    resources: {
      component: AvailableResourcesList,
      value: "resources",
      label: "Resources",
    },
  };

  getListsForMobileView = () => {
    const {
      actions: {
        resetRsvpEventSchedules,
        getRsvpEventSchedules,
        getRsvpResources,
      },
      eventSchedules,
      resources,
      pageRoute,
      pageRoute: {
        query: { rsvpType },
      },
      rsvpLocation: { data: rsvpLocation },
      user,
    } = this.props;

    return (
      <>
        <div className="flex events-resources-tabs">
          {Object.values(this.views).map(
            (view, i) =>
              this.state.showResourcesTab && (
                <p
                  className={`rsvp-list-tab link-text-secondary ${
                    view.value === decodeURIComponent(rsvpType) ||
                    (!rsvpType && i === 0) // default active tab to "events" if none specified
                      ? "rsvp-tab-link-active"
                      : "rsvp-tab-link"
                  }`}
                  key={i}
                  onClick={() => this.onChangeViewForMobile(view.value)}
                >
                  {view.label}
                </p>
              ),
          )}
        </div>
        {rsvpType === "resources" ? ( // default to "events" if not specified
          <AvailableResourcesList
            resources={resources}
            getResources={getRsvpResources}
            pageRoute={pageRoute}
            user={user}
          />
        ) : (
          <UpcomingEventsList
            eventSchedules={eventSchedules}
            getEventSchedules={getRsvpEventSchedules}
            resetEventSchedules={resetRsvpEventSchedules}
            rsvpLocation={rsvpLocation}
            pageRoute={pageRoute}
            user={user}
          />
        )}
      </>
    );
  };

  onChangeViewForMobile = (newView) => {
    const {
      pageRoute: { page, query, params },
    } = this.props;

    const url = PageURL.to(page, params, {
      ...query,
      rsvpType: newView,
    });
    Navigation.redirect(url);
  };

  render() {
    const {
      rsvpLocation: { data: rsvpLocation, error, loading },
    } = this.props;

    return (
      <Layout
        {...this.props}
        title={`RSVP ${(rsvpLocation && rsvpLocation.chabadHouseName) || ""}`}
      >
        <div className="page rsvp-page">
          {loading ? (
            <div className="full-page-loader">
              <Loader />
            </div>
          ) : error ? (
            error.response && error.response.status === 403 ? (
              <RsvpNotAvailable />
            ) : (
              <RsvpError error={error} errorType="RSVP location" />
            )
          ) : (
            rsvpLocation &&
            rsvpLocation.enrollmentID && (
              <div className="container">
                <RsvpHeader rsvpLocation={rsvpLocation} />
                {isMobileView()
                  ? this.getListsForMobileView()
                  : this.getListsForDesktopView()}
              </div>
            )
          )}
        </div>
      </Layout>
    );
  }
}

export default withAppInsights(RsvpEvents);
