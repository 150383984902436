import React from "react";
import BillingAddress from "../../../../shared/BillingAddress";
import BillingSummary from "./BillingSummary";
import CardPaymentInfo from "../../../../shared/CardPaymentInfo";
import ConfirmationModal from "../../../../../components/ConfirmationModal";
import DigitalPayButton from "./DigitalPayButton";
import ManualPayButton from "../../../../shared/ManualPayButton";
import { injectStripe } from "react-stripe-elements";

import { handleErrorMessage } from "../../../../../lib";
import { TripTypes } from "../../../TripConsts";
import {
  getPromoDiscountInCredits,
  isTripRedeemedViaRewardCredits,
} from "../../RegistrationFormHelpers";
import Big from "big.js";
import moment from "moment";
import _cloneDeep from "lodash.clonedeep";
import _set from "lodash.set";

class Checkout extends React.PureComponent {
  constructor(props) {
    super(props);

    const { registration } = props;

    const state = {
      billing: {
        ...registration.billing,
        ccInfoComplete: false,
      },

      billingSummary: [],

      discountableTotal: "",
      rewardError: false,

      errorMessage: "",
      isDigitalPay: false,
      loading: false,

      showCancelRegistrationConfirmationModal: false,
    };

    state.initialState = _cloneDeep(state);

    this.state = state;
  }

  componentDidMount() {
    this.calculateRegistrationBillingSummary();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.registration === this.props.registration) {
      return;
    }

    this.calculateRegistrationBillingSummary();
  }

  calculateRegistrationBillingSummary = () => {
    const {
      onChange,
      registration: {
        billingPromoCodes,
        rewardRequest,
        tourScheduleIDs,
        trackID,
      },
      trip,
    } = this.props;
    const { availableRewardInfo, tours, tracks, validPromoCodes } = trip;

    const isRedeemedViaCredits = isTripRedeemedViaRewardCredits(trip);

    const tripTrack = tracks.find((t) => t.id === trackID) || {};

    const items = [
      {
        title: "Registration Fee",
        price: tripTrack.regularPrice,
        priceAlt: "Please select a Track",
        creditValue: isRedeemedViaCredits
          ? tripTrack.regularPrice / availableRewardInfo.creditValue
          : null,
      },
    ];
    let total = Big(tripTrack.regularPrice || 0);

    if (tripTrack.earlyBirdPrice && tripTrack.isBeforeEarlyBirdDeadline) {
      items[0].subItems = [
        {
          title: "Early Bird Special",
          price: tripTrack.regularPrice - tripTrack.earlyBirdPrice,
          isDiscount: true,
        },
      ];
      total = Big(tripTrack.earlyBirdPrice);
    }

    tours.forEach((tour) => {
      const isEnrolledInTour = !!tour.schedules.find(
        (sched) => tourScheduleIDs.indexOf(sched.id) >= 0,
      );
      if (isEnrolledInTour) {
        items.push({
          title: tour.name,
          price: tour.price,
          priceAlt: "Free",
        });
        total = total.plus(tour.price || 0);
      }
    });

    if (tripTrack.depositAmount) {
      items.push({
        title: "Deposit",
        price: tripTrack.depositAmount,
      });
      total = total.plus(tripTrack.depositAmount);
    }

    if (billingPromoCodes?.length) {
      const promoCodeItems = [];

      billingPromoCodes.forEach((promoCode) => {
        const validPromoCode = validPromoCodes.find(
          (promo) => promo.code === promoCode,
        );
        if (validPromoCode) {
          promoCodeItems.push({
            title: validPromoCode.code,
            price: validPromoCode.discountAmount,
            isDiscount: true,
            isPromo: true,
            creditValue: isRedeemedViaCredits
              ? validPromoCode.discountAmount / availableRewardInfo.creditValue
              : null,
          });
          total = total.minus(validPromoCode.discountAmount || 0);
        }
      });

      if (promoCodeItems.length) {
        items.push({
          title: "Promo Codes",
          subItems: promoCodeItems,
        });
      }
    }

    const discountableTotal = total.minus(tripTrack.depositAmount || 0); // exclude deposit amount for discountable total

    if (rewardRequest) {
      const rewardRequestAmount =
        rewardRequest.creditValue * rewardRequest.creditsToRedeem;

      // if the applied reward request exceeds the discountable total, clear the reward request and return (billing summary will be recalculated after the change)
      if (discountableTotal < rewardRequestAmount) {
        onChange("rewardRequest", null);
        return;
      }

      items.push({
        title: "JewishU Credits",
        price: rewardRequestAmount,
        creditValue: rewardRequest.creditsToRedeem,
        isDiscount: true,
        isReward: true,
      });
      total = total.minus(rewardRequestAmount);
    }

    if (total < 0) {
      total = Big(0); //ensure that total is never a negative int
    }

    this.setState({
      billing: {
        ...this.state.billing,
        amountCents: total.times(100),
      },
      billingSummary: items,
      discountableTotal,
    });
  };

  isIncompletePaymentOrRegistration = () => {
    const { registrationValidation } = this.props;

    const {
      billing: {
        address: { address1, address2, city, country, state, zip },
        ccInfoComplete,
        useCardOnFile,
      },
      isDigitalPay,
    } = this.state;

    const hasBillingAddress =
      address1 || address2 || city || state || country || zip;

    return (
      registrationValidation.isIncomplete ||
      (hasBillingAddress && (!address1 || !city || !country)) ||
      (!isDigitalPay && !useCardOnFile && !ccInfoComplete)
    );
  };

  //Managing billing state internally to avoid re-calculating unnecessarily and to avoid caching sensitive billing info
  onChangePayment = (name, value, other) => {
    let billing = _cloneDeep(this.state.billing);
    _set(billing, name, value);

    if (other) {
      Object.keys(other).forEach((update) =>
        _set(billing, update, other[update]),
      );
    }

    this.setState({ billing });
  };

  onChangePaymentEvt = (event) => {
    this.onChangePayment(
      event.target.name,
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value,
    );
  };

  onClickDigitalPay = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          ccInfoComplete: true,
          errorMessage: "",
          isDigitalPay: true,
        },
        () => {
          this.props.setSubmitAttempted();
          resolve(!this.isIncompletePaymentOrRegistration());
        },
      );
    });
  };

  onSubmitDigitalPay = async (token) => {
    return await this.onSubmitPayment(null, true, token);
  };

  onSubmitPayment = async (
    event,
    isDigitalPay = false,
    digitalPayToken = null,
  ) => {
    if (event) {
      event.preventDefault();
    }

    this.setState({
      errorMessage: "",
      isDigitalPay,
    });
    this.props.setSubmitAttempted();

    if (this.isIncompletePaymentOrRegistration()) {
      return;
    }

    this.setState({ loading: true });

    const { stripe, submitRegistration, systemCountries } = this.props;

    const {
      billing: { address, cardHolderFullName, useCardOnFile },
    } = this.state;

    let token;

    if (isDigitalPay) {
      token = digitalPayToken;
    } else if (!useCardOnFile) {
      const stripeValues = {
        name: cardHolderFullName,
        address_line1: address.address1,
        address_line2: address.address2,
        address_city: address.city,
        address_state: address.state,
        address_zip: address.zip,
        address_country: (
          systemCountries.find((country) => country.name === address.country) ||
          {}
        ).code,
      };

      const stripeToken = await stripe.createToken(stripeValues);
      token = stripeToken.token;
    }

    if (!useCardOnFile && !token) {
      this.setState({
        errorMessage:
          "Unable to process credit card payment.  Please try again.",
        loading: false,
      });
      return;
    }

    const register = await submitRegistration({
      ...this.state.billing,
      stripeToken: token,
    });
    const { error, success } = register || {};

    if (!success) {
      this.setState({
        errorMessage: handleErrorMessage(error),
        loading: false,
      });
    }

    if (isDigitalPay) {
      return success ? "success" : "fail";
    }
  };

  render() {
    const {
      cancelRegistration,
      isPhone,
      isShliachView,
      onChange,
      registration: { billingPromoCodes, rewardRequest },
      stripe: { paymentRequest: digitalPaymentRequest },
      submitAttempted,
      systemCountries,
      trip: {
        availableRewardInfo,
        credCardInfo,
        event: { isTravelTrip, type },
        isStudentIneligibleForAllTracks,
        latestCancellationDate,
        programScheduleName,
        validPromoCodes,
      },
    } = this.props;

    const {
      billing: { address, amountCents, cardHolderFullName, useCardOnFile },
      billingSummary,
      discountableTotal,
      errorMessage,
      isDigitalPay,
      loading,
      rewardError,
      showCancelRegistrationConfirmationModal,
    } = this.state;

    const isIncompletePaymentOrRegistration =
      this.isIncompletePaymentOrRegistration();

    const promoCreditsDiscount =
      type === TripTypes.JewishU &&
      getPromoDiscountInCredits(
        validPromoCodes,
        availableRewardInfo.creditValue,
      );

    const PaymentDisclaimer = (
      <p className="accent-text-dark line-height-double mb-24">
        {isTravelTrip ? (
          <>
            For security purposes, and to validate your application, please
            provide your payment information.{" "}
            <span className="fw-700">
              Your credit card will only be charged
              {type === TripTypes.JewishU
                ? " and your JewishU credits withdrawn "
                : " "}
              upon being accepted for this trip.
            </span>{" "}
            If you wish to decline acceptance, you will have one business day
            from when notified to cancel and receive a full refund
            {type === TripTypes.JewishU ? " of your credits and deposit" : ""}.
          </>
        ) : (
          <>
            Choose a payment method for registration. You may cancel up until{" "}
            <span className="fw-700">
              {moment(latestCancellationDate).format("MMMM DD, YYYY")} at{" "}
              {moment(latestCancellationDate).format("h:mm A")} EST
            </span>{" "}
            and receive a full refund.
          </>
        )}
      </p>
    );

    return (
      <form
        action="/autocomplete"
        autoComplete="on"
        className="checkout-form"
        method="post"
        onSubmit={isShliachView ? null : this.onSubmitPayment}
      >
        <div>
          {promoCreditsDiscount && (
            <p className="accent-text-dark line-height-double mb-24">
              <span className="fw-700">
                First-time JewishU trip goers can use promo{" "}
                {validPromoCodes[0].code} for a {promoCreditsDiscount} credit
                discount.
              </span>{" "}
              Note that if you are not a first-time JewishU trip goer, use of
              the promo will invalidate your application.
            </p>
          )}

          <BillingSummary
            applyPromoCodes={(promoCodes) =>
              onChange("billingPromoCodes", promoCodes)
            }
            applyReward={(rewardRequest) =>
              onChange("rewardRequest", rewardRequest)
            }
            autoApplyReward={type === TripTypes.JewishU}
            availableRewardInfo={availableRewardInfo}
            billingPromoCodes={billingPromoCodes}
            billingSummary={billingSummary}
            billingTotal={amountCents > 0 ? amountCents.div(100) : 0}
            discountableTotal={discountableTotal}
            rewardRequest={rewardRequest}
            rewardError={rewardError}
            setRewardError={(rewardError) => this.setState({ rewardError })}
            unappliedPromoCreditsDiscount={
              !billingPromoCodes?.length && promoCreditsDiscount
            }
            validPromoCodes={validPromoCodes}
          />

          <div className="trip-form-section">
            <p className="subsection-title">Payment method</p>

            {PaymentDisclaimer}

            <CardPaymentInfo
              billingInfo={
                <BillingAddress
                  address={address}
                  countries={systemCountries}
                  isDigitalPay={isDigitalPay}
                  onChange={this.onChangePayment}
                  onChangeEvt={this.onChangePaymentEvt}
                  submitAttempted={submitAttempted}
                />
              }
              cardHolderFullName={cardHolderFullName}
              cardOnFile={credCardInfo}
              digitalPayButton={
                !isShliachView &&
                !rewardError && (
                  <DigitalPayButton
                    amountCents={amountCents}
                    clearErrorMessage={() =>
                      this.setState({ errorMessage: "" })
                    }
                    createPaymentRequest={digitalPaymentRequest}
                    incompleteRegistration={isIncompletePaymentOrRegistration}
                    isPhone={isPhone}
                    isStudentIneligibleForAllTracks={
                      isStudentIneligibleForAllTracks
                    }
                    onClickDigitalPay={this.onClickDigitalPay}
                    {...(isDigitalPay
                      ? {
                          errorMessage,
                          loading,
                          submitAttempted,
                        }
                      : {})}
                    submitPayment={this.onSubmitDigitalPay}
                  />
                )
              }
              isDigitalPay={isDigitalPay}
              isPhone={isPhone}
              onChange={this.onChangePayment}
              onChangeEvt={this.onChangePaymentEvt}
              submitAttempted={submitAttempted}
              useCardOnFile={useCardOnFile}
            />
          </div>

          {!isShliachView && (
            <div className="trip-form-checkout-btns">
              <button
                className="btn btn-light btn-large"
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    showCancelRegistrationConfirmationModal: true,
                  });
                }}
                type="button"
              >
                Cancel
              </button>
              <ManualPayButton
                buttonText={isTravelTrip ? "Apply" : "Register"}
                clearErrorMessage={() => this.setState({ errorMessage: "" })}
                customValidationFailed={isStudentIneligibleForAllTracks}
                customValidationErrorMessage="Please review registration form for errors"
                disabled={rewardError}
                incompleteRegistration={isIncompletePaymentOrRegistration}
                loading={loading}
                noteText={isTravelTrip ? "You won't be charged yet" : ""}
                {...(!isDigitalPay
                  ? {
                      errorMessage,
                      loading,
                      submitAttempted,
                    }
                  : {})}
              />
            </div>
          )}

          <ConfirmationModal
            cancel={() =>
              this.setState({
                showCancelRegistrationConfirmationModal: false,
              })
            }
            confirm={cancelRegistration}
            message={`Are you sure you want to cancel your registration for ${
              programScheduleName || "this trip"
            }?`}
            show={showCancelRegistrationConfirmationModal}
          />
        </div>
      </form>
    );
  }
}

export default injectStripe(Checkout);
