import _cloneDeep from "lodash.clonedeep";
import _get from "lodash.get";
import _set from "lodash.set";
import moment from "moment";

export function formatCurrency(num, currency) {
  if (!num) {
    return `${currency}0`;
  }

  num = parseFloat(num).toFixed(2);
  var parts = num.toString().split(".");
  return (
    currency +
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (parts[1] ? "." + parts[1] : "")
  );
}

function formatDateForInput(dateString) {
  let date = "";
  if (dateString) {
    date = dateString;
    if (dateString.toString().length > 10) {
      var d = new Date(new Date(dateString)),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
  }
  return date;
}

export function formatNumber(numString) {
  return numString
    ? numString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : 0;
}

export function getAddressDisplay(
  address1,
  address2,
  city,
  state,
  zip,
  country,
) {
  return (
    (address1 ? `${address1}, ` : "") +
    (address2 ? `${address2}, ` : "") +
    (address1 || address2 ? "\n" : "") +
    (city ? city : "") +
    (city && (state || zip || country) ? ", " : " ") +
    (state ? state : "") +
    (zip ? ` ${zip}, ` : "") +
    (country ? ` ${country}` : "")
  );
}

export function getDateDisplay(date) {
  const dateParts = date.toString().split(/\s+/).slice(0, 3);
  return dateParts.slice(0, 2).join(", ") + " " + dateParts[2];
}

export function getEndOfDay(date) {
  return moment(date).add(1, "days").subtract(1, "minutes");
}

export function getFormattedValuesForForm(values, dateFields) {
  const formattedValues = _cloneDeep(values);

  replaceValuesInObject(formattedValues, (val) => val === null, "");

  if (dateFields) {
    dateFields.forEach((dateFieldName) => {
      const dateField = _get(formattedValues, dateFieldName);
      _set(formattedValues, dateFieldName, formatDateForInput(dateField));
    });
  }

  return formattedValues;
}

export function getFullNameDisplay(firstName, lastName, title) {
  return [title, firstName, lastName].filter((val) => !!val).join(" ");
}

export function getYearOptions(startYear, endYear) {
  const numberOfYears = endYear - startYear + 1;
  return [...Array(numberOfYears).keys()].map((i) => i + startYear);
}

// get a versioned image source path when image at static path is updated
export function getVersionedImgSrc(fileName) {
  return `/images/${fileName}?${process.env.REACT_APP_IMG_CACHE_VERSION}`;
}

export function handleErrorMessage(error, altMessage) {
  //parsing out errorData to handle alternative error response patterns - some with data wrapped in response obj and some with data direct in error body
  const errorData =
    error && (error.data || (error.response && error.response.data));
  let errorMessage = errorData && errorData.messages && errorData.messages[0];
  if (errorMessage === "Error processing request") errorMessage = null; //default 500 Internal Server Error message that we do not want to display in UI

  return (
    errorMessage ||
    altMessage ||
    "An unexpected error has occurred. Please try again."
  );
  //TODO - need to figure out how to handle this properly because don't always want message[0] displayed in the UI
}

export function joinCommaDelimitedList(items) {
  return items
    ? [items.slice(0, -1).join(", "), items.slice(-1)[0]].join(
        items.length < 2 ? "" : " and ",
      )
    : "";
}

export function nth(n) {
  return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
}

export function pluralizeText(text, count, altPluralization) {
  return count === 1 ? text : altPluralization || text + "s";
}

export function removeEmptyFromObj(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object") removeEmptyFromObj(obj[key]);
    else if (obj[key] === null || obj[key] === "") delete obj[key];
  });
}

export function replaceValuesInObject(obj, replaceIf, replacementValue) {
  Object.keys(obj).forEach((key) => {
    if (replaceIf(obj[key])) {
      obj[key] = replacementValue;
    } else if (obj[key]) {
      if (obj[key] instanceof Array) {
        obj[key].forEach((rec) =>
          replaceValuesInObject(rec, replaceIf, replacementValue),
        );
      } else if (obj[key] instanceof Object) {
        replaceValuesInObject(obj[key], replaceIf, replacementValue);
      }
    }
  });
}

export function toTitleCase(str) {
  return str
    ? str.replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
      )
    : str;
}

export function trimTrailingSlash(path) {
  if (path) {
    const { length } = path;
    if (path.charAt(length - 1) === "/") {
      return path.substr(0, length - 1);
    }
  }
  return path;
}

export function validateEmail(email) {
  return email
    ? /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email.trim())
    : true;
}

export function validatePassword(password) {
  return password
    ? /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/.test(password)
    : true;
}

// Check size of window to be consistent with our scss definition of mobile
export function isMobileView() {
  return window.innerWidth <= 560;
}

export class TwoWayMap {
  constructor(map) {
    this.map = map;
    this.reverseMap = {};
    for (let key in map) {
      const value = map[key];
      this.reverseMap[value] = key;
    }
  }
  get(key) {
    return this.map[key];
  }
  revGet(key) {
    return this.reverseMap[key];
  }
}
/**
 * Load document script
 * Primary source for this function https://stackoverflow.com/a/72210710/12892971
 */
export function loadScript(src, async = true, defer = true) {
  return new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve(true);

    const script = document.createElement("script");
    script.src = src;
    script.async = async;
    script.defer = defer;
    script.onload = () => resolve(true);
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });
}

export function getWordCount(text) {
  return text?.trim().split(/\s+/).length || 0;
}

export function hasTrueFalseValue(bool) {
  return bool === true || bool === false;
}