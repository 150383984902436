import React from "react";
// import {
//   FacebookIcon,
//   FacebookShareButton,
//   TwitterIcon,
//   TwitterShareButton,
//   WhatsappIcon,
//   WhatsappShareButton,
// } from "react-share";

export default ({
  trip: {
    event: { isTravelTrip },
    isAcceptanceRequired,
    programScheduleName,
    // programURL,
    // tripEventHashtag,
  },
}) => (
  <div className="trip-page-form-state">
    <img src="/images/registration_complete.svg" alt="" height="200" />
    <p className="fw-700 mt-24" style={{ fontSize: "34px" }}>
      {isTravelTrip ? "Good going!" : "Thank you!"}
    </p>
    <p
      className="mt-16 large-text line-height-double text-center"
      style={{ maxWidth: "672px" }}
    >
      {isTravelTrip
        ? `Your application has been received for the ${programScheduleName} trip. It will be reviewed and we will follow up with you as soon as we have an update.`
        : isAcceptanceRequired
        ? "Your application is being reviewed. You will be notified and your card will be charged upon acceptance."
        : `Your registration has been completed successfully. Check your inbox for a confirmation email. We can’t wait to see you @ ${programScheduleName}.`}
    </p>
    {isTravelTrip && (
      <p className="large-text italic fw-700 mt-16">
        Make someone smile today!
      </p>
    )}
    {/* note: social share removed per client request, leaving this code because there is a possibility that it will be reincorporated in the future */}
    {/* {!!programURL && (
      <div>
        <p
          className="fw-700 medium-text mb-32 text-center"
          style={{ marginTop: "56px" }}
        >
          Get your friends to join you!
        </p>
        <div className="flex social-media-btns">
          <FacebookShareButton
            hashtag={tripEventHashtag}
            quote={`I am going to ${
              tripEventHashtag || programScheduleName
            }. You can join me by signing up here ${programURL}`}
            url={programURL}
          >
            <div className="flex flex-justify-center flex-align-center">
              <FacebookIcon />
              Share on Facebook
            </div>
          </FacebookShareButton>
          <TwitterShareButton
            hashtags={
              tripEventHashtag ? [tripEventHashtag.replace("#", "")] : []
            }
            title={`I am going to ${tripEventHashtag}. You can join me by signing up here`}
            url={programURL}
          >
            <div className="flex flex flex-justify-center flex-align-center">
              <TwitterIcon />
              Share on Twitter
            </div>
          </TwitterShareButton>
          <WhatsappShareButton
            title={`I am going to ${
              tripEventHashtag || programScheduleName
            }. You can join me by signing up here`}
            url={programURL}
          >
            <div className="flex flex flex-justify-center flex-align-center">
              <WhatsappIcon />
              Share on Whatsapp
            </div>
          </WhatsappShareButton>
        </div>
      </div>
    )} */}
  </div>
);
