import { AuthRequest } from "../../lib";
import { ApiCallErrorMessageHandler } from "../../ApiClientErrorHandler";
import querySerializer from "query-string";
import axios from "axios";
const { REACT_APP_API_URL } = process.env;

export const RsvpActions = {
  SET_GET_RSVP_LOCATION_LOADING: "SET_GET_RSVP_LOCATION_LOADING",
  GET_RSVP_LOCATION_COMPLETE: "GET_RSVP_LOCATION_COMPLETE",
  GET_RSVP_LOCATION_ERROR: "GET_RSVP_LOCATION_ERROR",

  SET_GET_RSVP_EVENT_SCHEDULES_LOADING: "SET_GET_RSVP_EVENT_SCHEDULES_LOADING",
  GET_RSVP_EVENT_SCHEDULES_COMPLETE: "GET_RSVP_EVENT_SCHEDULES_COMPLETE",
  GET_RSVP_EVENT_SCHEDULES_ERROR: "GET_RSVP_EVENT_SCHEDULES_ERROR",
  RESET_RSVP_EVENT_SCHEDULES: "RESET_RSVP_EVENT_SCHEDULES",

  SET_GET_RSVP_EVENT_SCHEDULE_LOADING: "SET_GET_RSVP_EVENT_SCHEDULE_LOADING",
  GET_RSVP_EVENT_SCHEDULE_COMPLETE: "GET_RSVP_EVENT_SCHEDULE_COMPLETE",
  GET_RSVP_EVENT_SCHEDULE_ERROR: "GET_RSVP_EVENT_SCHEDULE_ERROR",
  RESET_RSVP_EVENT_SCHEDULE: "RESET_RSVP_EVENT_SCHEDULE",

  SET_RSVP_EVENT_REGISTRATION_LOADING: "SET_RSVP_EVENT_REGISTRATION_LOADING",
  SET_RSVP_EVENT_REGISTRATION: "SET_RSVP_EVENT_REGISTRATION",
  RSVP_EVENT_REGISTRATION_ERROR: "RSVP_EVENT_REGISTRATION_ERROR",
  RESET_RSVP_EVENT_REGISTRATION: "RESET_RSVP_EVENT_REGISTRATION",

  SET_GET_RSVP_EVENT_REGISTRATION_PAYMENT_DETAILS_LOADING:
    "SET_GET_RSVP_EVENT_REGISTRATION_PAYMENT_DETAILS_LOADING",
  SET_GET_RSVP_EVENT_REGISTRATION_PAYMENT_DETAILS:
    "SET_GET_RSVP_EVENT_REGISTRATION_PAYMENT_DETAILS",

  SET_RSVP_EVENT_REGISTRATION_PAYMENT_LOADING:
    "SET_RSVP_EVENT_REGISTRATION_PAYMENT_LOADING",
  SET_RSVP_EVENT_REGISTRATION_PAYMENT: "SET_RSVP_EVENT_REGISTRATION_PAYMENT",

  SET_GET_RSVP_RESOURCES_LOADING: "SET_GET_RSVP_RESOURCES_LOADING",
  SET_RSVP_RESOURCES: "SET_RSVP_RESOURCES",

  SET_GET_RSVP_RESOURCE_LOADING: "SET_GET_RSVP_RESOURCE_LOADING",
  SET_RSVP_RESOURCE: "SET_RSVP_RESOURCE",

  SET_RSVP_RESOURCE_REGISTRATION_LOADING:
    "SET_RSVP_RESOURCE_REGISTRATION_LOADING",
  SET_RSVP_RESOURCE_REGISTRATION: "SET_RSVP_RESOURCE_REGISTRATION",

  SET_GET_RSVP_RESOURCE_REGISTRATION_PAYMENT_DETAILS_LOADING:
    "SET_GET_RSVP_RESOURCE_REGISTRATION_PAYMENT_DETAILS_LOADING",
  SET_GET_RSVP_RESOURCE_REGISTRATION_PAYMENT_DETAILS:
    "SET_GET_RSVP_RESOURCE_REGISTRATION_PAYMENT_DETAILS",

  SET_RSVP_RESOURCE_REGISTRATION_PAYMENT_LOADING:
    "SET_RSVP_RESOURCE_REGISTRATION_PAYMENT_LOADING",
  SET_RSVP_RESOURCE_REGISTRATION_PAYMENT:
    "SET_RSVP_RESOURCE_REGISTRATION_PAYMENT",

  getRsvpLocation(chabadHouseSlug) {
    return async (dispatch) => {
      dispatch(RsvpActions.setGetRsvpLocationLoading(true));

      let error, response, success;
      try {
        response = await AuthRequest.get(`RsvpEnrollments/${chabadHouseSlug}`);
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(RsvpActions.getRsvpLocationError(error));
        return undefined;
      }

      dispatch(RsvpActions.getRsvpLocationComplete(response.data.payload));

      return response.data.payload;
    };
  },
  setGetRsvpLocationLoading(payload) {
    return {
      type: RsvpActions.SET_GET_RSVP_LOCATION_LOADING,
      payload,
    };
  },
  getRsvpLocationComplete(payload) {
    return {
      type: RsvpActions.GET_RSVP_LOCATION_COMPLETE,
      payload,
    };
  },
  getRsvpLocationError(payload) {
    return {
      type: RsvpActions.GET_RSVP_LOCATION_ERROR,
      payload,
    };
  },

  getRsvpEventSchedules(
    chabadHouseSlug,
    page,
    results,
    availableForRegistrationOnly,
  ) {
    return async (dispatch, getState) => {
      dispatch(RsvpActions.setGetRsvpEventSchedulesLoading(true));

      let error, response, success;
      try {
        const params = {
          availableForRegistrationOnly,
          occurrenceStatus: "Upcoming",
          settingStates: ["Active"],
          page,
          results,
          sortByOption: "Date",
        };
        response = await AuthRequest.get(
          `RsvpEnrollments/eventSchedules/${chabadHouseSlug}?${querySerializer.stringify(
            params,
          )}`,
        );

        //if page is greater than 1 (loading more), include previous results in response data
        if (page > 1 && response.data.payload.results) {
          const previousEventScheduleResults =
            getState().rsvp.eventSchedules.data.results;
          if (previousEventScheduleResults) {
            response.data.payload.results = [
              ...previousEventScheduleResults,
              ...response.data.payload.results,
            ];
          }
        }

        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(RsvpActions.getRsvpEventSchedulesError(error));
        return undefined;
      }

      dispatch(
        RsvpActions.getRsvpEventSchedulesComplete(response.data.payload),
      );

      return response.data.payload;
    };
  },
  setGetRsvpEventSchedulesLoading(payload) {
    return {
      type: RsvpActions.SET_GET_RSVP_EVENT_SCHEDULES_LOADING,
      payload,
    };
  },
  getRsvpEventSchedulesComplete(payload) {
    return {
      type: RsvpActions.GET_RSVP_EVENT_SCHEDULES_COMPLETE,
      payload,
    };
  },
  getRsvpEventSchedulesError(payload) {
    return {
      type: RsvpActions.GET_RSVP_EVENT_SCHEDULES_ERROR,
      payload,
    };
  },
  resetRsvpEventSchedules() {
    return {
      type: RsvpActions.RESET_RSVP_EVENT_SCHEDULES,
    };
  },

  getRsvpEventSchedule(eventScheduleId, isPublic = false) {
    return async (dispatch) => {
      dispatch(RsvpActions.setGetRsvpEventScheduleLoading(true));

      let error, response, success;
      try {
        response = await AuthRequest.get(
          `RsvpRegistrations/byScheduleId${
            isPublic ? "/public" : ""
          }?scheduleId=${eventScheduleId}`,
        );
        success = true;
      } catch (err) {
        error = err;
      }

      if (!response || !success) {
        dispatch(RsvpActions.getRsvpEventScheduleError(error));
        return undefined;
      }

      dispatch(RsvpActions.getRsvpEventScheduleComplete(response.data.payload));

      return response.data.payload;
    };
  },
  setGetRsvpEventScheduleLoading(payload) {
    return {
      type: RsvpActions.SET_GET_RSVP_EVENT_SCHEDULE_LOADING,
      payload,
    };
  },
  getRsvpEventScheduleComplete(payload) {
    return {
      type: RsvpActions.GET_RSVP_EVENT_SCHEDULE_COMPLETE,
      payload,
    };
  },
  getRsvpEventScheduleError(payload) {
    return {
      type: RsvpActions.GET_RSVP_EVENT_SCHEDULE_ERROR,
      payload,
    };
  },
  resetRsvpEventSchedule() {
    return {
      type: RsvpActions.RESET_RSVP_EVENT_SCHEDULE,
    };
  },

  doRsvpEventRegistration(eventRegistration) {
    return async (dispatch, getState) => {
      dispatch(RsvpActions.setRsvpEventRegistrationLoading(true));

      let success = true;
      let newState = {};
      const response = await AuthRequest.post(
        "RsvpRegistrations",
        eventRegistration,
      ).catch((err) => {
        success = false;
        newState.error = err;
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;
      dispatch(RsvpActions.setRsvpEventRegistration(newState));

      return newState;
    };
  },
  deleteEventRegistration(eventScheduleId) {
    return async () => {
      const response = await AuthRequest.delete(
        `RsvpRegistrations/${eventScheduleId}`,
      ).catch((err) => {
        return { error: true };
      });
      return response;
    };
  },
  setRsvpEventRegistrationLoading(payload) {
    return {
      type: RsvpActions.SET_RSVP_EVENT_REGISTRATION_LOADING,
      payload,
    };
  },
  setRsvpEventRegistration(payload) {
    return {
      type: RsvpActions.SET_RSVP_EVENT_REGISTRATION,
      payload,
    };
  },

  getRsvpEventRegistrationPaymentDetails(data) {
    return async (dispatch) => {
      dispatch(
        RsvpActions.setGetRsvpEventRegistrationPaymentDetailsLoading(true),
      );

      let success = true;
      let newState = {};
      const response = await axios
        .get(
          `${REACT_APP_API_URL}RsvpRegistrations/${
            data.registrationId
          }/forPayment?${querySerializer.stringify(data)}`,
        )
        .catch((err) => {
          success = false;
          newState.error = err;
        });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;
      dispatch(RsvpActions.setGetRsvpEventRegistrationPaymentDetails(newState));
    };
  },
  setGetRsvpEventRegistrationPaymentDetailsLoading(payload) {
    return {
      type: RsvpActions.SET_GET_RSVP_EVENT_REGISTRATION_PAYMENT_DETAILS_LOADING,
      payload,
    };
  },
  setGetRsvpEventRegistrationPaymentDetails(payload) {
    return {
      type: RsvpActions.SET_GET_RSVP_EVENT_REGISTRATION_PAYMENT_DETAILS,
      payload,
    };
  },

  doRsvpEventRegistrationPayment(data) {
    return async (dispatch) => {
      dispatch(RsvpActions.setRsvpEventRegistrationPaymentLoading(true));

      let success = true;
      let newState = {};
      const response = await axios
        .post(`${REACT_APP_API_URL}RsvpRegistrations/processPayment`, data)
        .catch((err) => {
          success = false;
          newState = {
            error: err,
            errorMessage: ApiCallErrorMessageHandler(err),
          };
        });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;
      dispatch(RsvpActions.rsvpEventRegistrationPayment(newState));

      return newState;
    };
  },
  setRsvpEventRegistrationPaymentLoading(payload) {
    return {
      type: RsvpActions.SET_RSVP_EVENT_REGISTRATION_PAYMENT_LOADING,
      payload,
    };
  },
  rsvpEventRegistrationPayment(payload) {
    return {
      type: RsvpActions.SET_RSVP_EVENT_REGISTRATION_PAYMENT,
      payload,
    };
  },

  getRsvpResources(chabadHouseSlug, ignorePagination) {
    return async (dispatch, getState) => {
      dispatch(RsvpActions.setGetRsvpResourcesLoading(true));

      let success = true;
      let newState = {};

      const params = {
        sortByOption: "CreatedOn",
        ignorePagination,
      };
      const response = await AuthRequest.get(
        `RsvpEnrollments/resources/${chabadHouseSlug}?${querySerializer.stringify(
          params,
        )}`,
      ).catch((err) => {
        success = false;
        newState.error = err;
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;
      dispatch(RsvpActions.setRsvpResources(newState));

      return newState;
    };
  },
  setGetRsvpResourcesLoading(payload) {
    return {
      type: RsvpActions.SET_GET_RSVP_RESOURCES_LOADING,
      payload,
    };
  },
  setRsvpResources(payload) {
    return {
      type: RsvpActions.SET_RSVP_RESOURCES,
      payload,
    };
  },

  getRsvpResource(resourceId) {
    return async (dispatch, getState) => {
      dispatch(RsvpActions.setGetRsvpResourceLoading(true));

      let success = true;
      let newState = {};

      const response = await AuthRequest.get(
        `RsvpRegistrations/resources/forRegistration?resourceId=${resourceId}`,
      ).catch((err) => {
        success = false;
        newState.error = err;
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;
      dispatch(RsvpActions.setRsvpResource(newState));

      return newState;
    };
  },
  setGetRsvpResourceLoading(payload) {
    return {
      type: RsvpActions.SET_GET_RSVP_RESOURCE_LOADING,
      payload,
    };
  },
  setRsvpResource(payload) {
    return {
      type: RsvpActions.SET_RSVP_RESOURCE,
      payload,
    };
  },

  doRsvpResourceRegistration(resource) {
    return async (dispatch, getState) => {
      dispatch(RsvpActions.setRsvpResourceRegistrationLoading(true));

      let success = true;
      let newState = {};
      const response = await AuthRequest.post(
        "RsvpRegistrations/resources",
        resource,
      ).catch((err) => {
        success = false;
        newState.error = err;
      });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;
      dispatch(RsvpActions.setRsvpResourceRegistration(newState));

      return newState;
    };
  },
  setRsvpResourceRegistrationLoading(payload) {
    return {
      type: RsvpActions.SET_RSVP_RESOURCE_REGISTRATION_LOADING,
      payload,
    };
  },
  setRsvpResourceRegistration(payload) {
    return {
      type: RsvpActions.SET_RSVP_RESOURCE_REGISTRATION,
      payload,
    };
  },

  getRsvpResourceRegistrationPaymentDetails(data) {
    return async (dispatch) => {
      dispatch(
        RsvpActions.setGetRsvpResourceRegistrationPaymentDetailsLoading(true),
      );

      let success = true;
      let newState = {};
      const response = await axios
        .get(
          `${REACT_APP_API_URL}RsvpRegistrations/resources/${
            data.registrationId
          }/forPayment?${querySerializer.stringify(data)}`,
        )
        .catch((err) => {
          success = false;
          newState.error = err;
        });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;
      dispatch(
        RsvpActions.setGetRsvpResourceRegistrationPaymentDetails(newState),
      );
    };
  },
  setGetRsvpResourceRegistrationPaymentDetailsLoading(payload) {
    return {
      type: RsvpActions.SET_GET_RSVP_RESOURCE_REGISTRATION_PAYMENT_DETAILS_LOADING,
      payload,
    };
  },
  setGetRsvpResourceRegistrationPaymentDetails(payload) {
    return {
      type: RsvpActions.SET_GET_RSVP_RESOURCE_REGISTRATION_PAYMENT_DETAILS,
      payload,
    };
  },

  doRsvpResourceRegistrationPayment(data) {
    return async (dispatch) => {
      dispatch(RsvpActions.setRsvpResourceRegistrationPaymentLoading(true));

      let success = true;
      let newState = {};
      const response = await axios
        .post(
          `${REACT_APP_API_URL}RsvpRegistrations/resources/processPayment`,
          data,
        )
        .catch((err) => {
          success = false;
          newState = {
            error: err,
            errorMessage: ApiCallErrorMessageHandler(err),
          };
        });

      if (success && response.data) {
        newState.data = response.data.payload;
      }

      newState.loading = false;
      newState.success = success;
      dispatch(RsvpActions.rsvpResourceRegistrationPayment(newState));

      return newState;
    };
  },
  setRsvpResourceRegistrationPaymentLoading(payload) {
    return {
      type: RsvpActions.SET_RSVP_RESOURCE_REGISTRATION_PAYMENT_LOADING,
      payload,
    };
  },
  rsvpResourceRegistrationPayment(payload) {
    return {
      type: RsvpActions.SET_RSVP_RESOURCE_REGISTRATION_PAYMENT,
      payload,
    };
  },
};
