import MyTrip from "./MyTrip";
import TripRegistration from "./TripRegistration";

export const TripPages = {
  myTrip: {
    anon: false,
    path: "/trips/:eventType?",
    title: "My Trip",
    type: "PAGE_MY_TRIP",
    view: MyTrip,
  },
  register: {
    anon: false,
    path: "/trips/:eventType/:scheduleId/register",
    title: "Register for a Trip",
    type: "PAGE_TRIP_REGISTRATION",
    view: TripRegistration,
  },
};
export default TripPages;

export const TripArea = {
  pages: TripPages,
};
